import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSchemeContext } from '../provider/SchemeDataProvider'

export const SchemeDetails: React.FC = () => {
  const { t } = useTranslation()

  const { schemeData } = useSchemeContext()

  return (
    <Box>
      <Typography fontWeight="bold">
        {t('scheme.details')}
      </Typography>
      <Typography>
        {t('scheme.ratingScale', { from: 1, to: schemeData?.ratingScale })}
      </Typography>
    </Box>
  )
}
