import {
  TextField,
  Typography
} from '@mui/material'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type Props = React.PropsWithChildren & {
  open?: boolean,
  currentTeam?:string
  onClose?: () => void
  onConfirm?: () => void | Promise<void>
}

export const ConfirmSubmissionDialog: React.FC<Props> = (props) => {
  const {
    onConfirm,
    open,
    currentTeam,
    onClose
  } = props

  const { t } = useTranslation()

  const [value, setValue] = React.useState('')

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm()
    }
    if (onClose) {
      onClose()
    }
  }

  const handleCancel = async () => {
    setValue('')
    if (onClose) {
      onClose()
    }
  }

  const disabledButton = useMemo(
    () => { return value !== currentTeam },
    [value, currentTeam]
  )

  const handleChange = (v: string) => {
    setValue(v)
  }

  return (
    <ConfirmDialog
      open={!!open}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: 500
        }
      }}
      title={t('dialogs.submission.title')}
      confirmProps={{ disabled: disabledButton }}
    >
      <Trans
        i18nKey={'dialogs.submission.message'}
        values={{
          name: currentTeam
        }}
        components={{ b: <b /> }}
      />
      <Typography
        sx={{ mt: 2 }}
        display="block"
      >
        {t('submission.verifyTeamName')}
      </Typography>
      <TextField
        autoFocus
        margin="dense"
        id="verify"
        label={currentTeam}
        onChange={(e) => { handleChange(e.target.value) }}
        type="text"
        fullWidth
        variant="outlined"
      />
    </ConfirmDialog>
  )
}
