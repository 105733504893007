import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  SxProps,
  useTheme,
  Typography
} from '@mui/material'
import { fontSizes } from '@theme/core/typography/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type ConfirmDialogProps = React.PropsWithChildren & {
  title?: string
  open?: boolean,
  loading?: boolean
  hideCancel?: boolean
  confirmLabel?: string
  stretchButtons?: boolean
  confirmProps?: LoadingButtonProps
  sx?: SxProps
  onClose?: () => void
  onConfirm?: () => void | Promise<void>
  onCancel?: () => void | Promise<void>
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    title,
    onConfirm,
    onCancel,
    open,
    loading,
    hideCancel,
    confirmLabel,
    confirmProps,
    sx,
    onClose,
    children,
    stretchButtons
  } = props

  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm()
    }
    if (onClose) {
      onClose()
    }
  }

  const handleCancel = async () => {
    if (onCancel) {
      await onCancel()
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      open={!!open}
      onClose={() => false}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: 500,
          [breakpoints.down('md')]: {
            maxWidth: '95%'
          }
        },
        ...sx
      }}
    >
      <DialogTitle>
        <Typography
          fontWeight="bold"
          sx={{
            [breakpoints.down('md')]: {
              fontSize: fontSizes.sm,
              fontWeight: 'bold'
            }
          }}
        >{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {typeof children === 'string'
          ? (
            <Typography sx={{
              [breakpoints.down('md')]: {
                fontSize: fontSizes.sm
              }
            }}
            >{children}</Typography>
            )
          : children}
      </DialogContent>
      <DialogActions sx={{
        px: 0,
        flexDirection: 'row',
        justifyContent: stretchButtons ? 'space-evenly' : 'flex-start'
      }}
      >
        {!hideCancel && handleCancel &&
          <Button
            sx={{
              width: stretchButtons ? '50%' : 'auto'
            }}
            variant="outlined"
            onClick={handleCancel}
          >
            {t('common.cancel')}
          </Button>
        }
        <LoadingButton
          loading={loading}
          sx={{
            width: stretchButtons ? (!hideCancel ? '50%' : '100%') : 'auto'
          }}
          variant='contained'
          onClick={handleConfirm}
          {...confirmProps}
        >
          {confirmLabel || t('common.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
