import React from 'react'
import { TeamColumnType, TeamsRowModel } from '@features/teams/typings/types'
import { Typography } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'
import { FilterSelectInput, FilterSelectItem } from '@shared/dataGrid/filter/FilterSelectInput'
import { RatingState } from '@typings/graphql'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { t } = useTranslation()

  const states = React.useMemo<FilterSelectItem[]>(() => {
    return Object.keys(RatingState)?.map((state) => {
      return {
        id: state,
        name: t(`ratingState.${state.toLowerCase()}`),
        key: state
      }
    }) ?? []
  }, [])

  return (
    <FilterSelectInput
      {...props}
      items={states ?? []}
    />
  )
}

const RenderCell: React.FC<TeamsRowModel> = ({ ratingState }) => {
  const { t } = useTranslation()

  const stateColor = React.useMemo(() => {
    switch (ratingState) {
      case RatingState.Full:
        return 'success.main'
      case RatingState.Partial:
        return 'warning.main'
      case RatingState.None:
        return 'error.main'
      default:
        return 'text.primary'
    }
  }, [ratingState])

  return (
    <Typography
      variant='subtitle1'
      sx={{
        color: stateColor
      }}
    >
      {t(`ratingState.${ratingState.toLowerCase()}`)}
    </Typography>
  )
}

export const ratingStateColumnModel: TeamColumnType = {
  field: 'ratingState',
  filterable: false,
  width: 175,
  minWidth: 125,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  valueGetter: (params) => params.row.ratingState,
  renderCell: (params) => (
    <RenderCell {...params.row} />
  )
}
