import { Box } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import React from 'react'

export const BaseDataGridDetailPanel: React.FC<React.PropsWithChildren> = ({ children }) => {
  const apiRef = useGridApiContext()

  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions()
    return dimensions!.viewportInnerSize.width
  })

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions()
    setWidth(dimensions!.viewportInnerSize.width)
  }, [apiRef])

  React.useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange
    )
  }, [apiRef, handleViewportInnerSizeChange])

  return (
    <Box sx={{
      p: 0,
      boxSizing: 'border-box',
      position: 'sticky',
      left: 0,
      width
    }}
    >
      <Box
        sx={{
          p: 2,
          // mr: '4px',
          backgroundColor: 'secondary.300',
          display: 'flex',
          flexDirection: 'column',
          // boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
          borderBottom: 'solid 1px',
          borderColor: 'grey.200'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
