import React from 'react'
import { useTranslation } from 'react-i18next'
import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import { DefaultIconButton } from '@shared/components/ui/DefaultIconButton'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { can } from '@utils/permissions'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

import { FeedbackEvaluationDataProvider, useFeedbackEvaluationContext } from '../provider/FeedbackEvaluationDataProvider'
import { FeedbackEvaluationQuestionList } from '../components/FeedbackEvaluationQuestionList'

const FeedbackEvaluationViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { dialogManagerRef } = useDialogContext()
  const { loading, handleDeleteFeedbacks } = useFeedbackEvaluationContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const handleDeleteFeedbacksConfirm = async () => {
    try {
      await handleDeleteFeedbacks()

      handleSuccess(t('feedbackEvaluation.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handelDeleteFeedback = () => {
    dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
      confirmCallback: async () => {
        return await handleDeleteFeedbacksConfirm()
      },
      dialogProps: {
        title: t('dialogs.deleteFeedback.title'),
        children: t('dialogs.deleteFeedback.message')
      }
    })
  }

  return (
    <AdminContentLayout
      title={t('routes.feedbackEvaluation')}
      loading={loading}
      headerContent={can('feedback:delete') && (<DefaultIconButton onClick={handelDeleteFeedback} icon="Delete" />)}
    >
      <FeedbackEvaluationQuestionList />

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}

export const FeedbackEvaluationView: React.FC = () => (
  <FeedbackEvaluationDataProvider>
    <FeedbackEvaluationViewContent />
  </FeedbackEvaluationDataProvider>
)
