import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = React.PropsWithChildren & {
  title: string,
  spaceBetween?: boolean
}

export const SimpleDataRow: React.FC<Props> = ({ title, spaceBetween = true, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: spaceBetween ? 'space-between' : 'flex-start'
      }}
    >
      <Typography>{`${title}:`}</Typography>
      <Box>
        {typeof children === 'string' ? <Typography>{children}</Typography> : children}
      </Box>
    </Box>
  )
}
