import React from 'react'
import { Box } from '@mui/material'

type Props = React.PropsWithChildren & {
  actions?: React.ReactNode
}

export const ActionRowCard: React.FC<Props> = ({ actions, children }) => {
  return (
    <Box
      sx={{
        p: 2,
        mr: '4px',
        backgroundColor: 'secondary.100',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        border: 'solid 1px rgba(0,0,0)',
        minHeight: '75px'
      }}
    >
      <Box
        sx={{
          flex: 1
        }}
      >
        {children}
      </Box>
      {actions && (
        <Box sx={{
          flex: '0 0 auto'
        }}
        >
          {actions}
        </Box>
      )}
    </Box>
  )
}
