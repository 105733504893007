import { DashboardCard } from '@shared/components/ui/DashboardCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { timestampToDate } from '@utils/date'
import { Box, Typography } from '@mui/material'
import { SimpleDataRow } from '@shared/components/ui/SimpleDataRow'

import { DateCountdown } from '../../../shared/components/ui/DateCountdown'
import { useHomeContext } from '../provider/HomeDataProvider'

export const ActiveEventCounterCard: React.FC = () => {
  const { t } = useTranslation()

  const { eventData } = useHomeContext()

  const startDeadline = React.useMemo<{date: string, time: string} | null>(() => {
    if (!eventData) {
      return null
    }

    return {
      date: timestampToDate(new Date(eventData.startDate), ['day', 'month', 'year']),
      time: timestampToDate(new Date(eventData.startDate), ['hour', 'minute'])
    }
  }, [eventData])

  return (
    <DashboardCard
      header={t('common.countdown')}
    >
      {
        eventData
          ? (
            <Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 2
              }}
              >
                {eventData?.startDate && (
                <>
                  <Typography sx={{
                    mb: 1
                  }}
                  >
                    {t('home.timeTillNextEvent')}
                  </Typography>
                  <DateCountdown date={new Date(eventData.startDate)} />
                </>
                )}
              </Box>

              <SimpleDataRow title={t('common.name')}>
                {eventData.name}
              </SimpleDataRow>
              <SimpleDataRow title={t('common.subject')}>
                {eventData.texts.subject}
              </SimpleDataRow>
              {startDeadline && (
                <>
                  <SimpleDataRow title={t('common.startDate')}>
                    {startDeadline.date}
                  </SimpleDataRow>
                  <SimpleDataRow title={t('common.startTime')}>
                    {startDeadline.time}
                  </SimpleDataRow>
                </>
              )}
            </Box>
            )
          : (
            <Typography>
              {t('home.noActiveEvent')}
            </Typography>
            )
        }
    </DashboardCard>
  )
}
