import { SchemeByEventForCrudQuery } from '@typings/graphql'

export enum SchemeButtonActions {
  'CREATE' = 'CREATE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
  'CREATE_CRITERIA' = 'CREATE_CRITERIA',
  'EDIT_CRITERIA' = 'EDIT_CRITERIA',
  'DELETE_CRITERIA' = 'DELETE_CRITERIA',
}

export type SchemeDataModel = SchemeByEventForCrudQuery['schemeByEvent']
export type SchemeCriteriaModel = Exclude<SchemeDataModel, null | undefined>['schemeCriteria'][0] & {
  children?: SchemeCriteriaModel[]
}

export type SchemeHandleActionProps = {
  handleAction: (action: SchemeButtonActions, model?: SchemeCriteriaModel) => void
}
