import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { can } from '@utils/permissions'

import { SchemeButtonActions, SchemeHandleActionProps } from '../typings/types'

export const MissingSchemeFallback: React.FC<SchemeHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        pb: 10
      }}
    >
      <Typography variant="h4" component="h2">
        {t('scheme.noScheme')}
      </Typography>

      {can(['schemes:create', 'scheme:create.own']) && (
        <Button
          sx={{
            mt: 4
          }}
          onClick={() => handleAction(SchemeButtonActions.CREATE)}
        >
          {t('action.createScheme')}
        </Button>
      )}
    </Box>
  )
}
