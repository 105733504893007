import * as Sentry from '@sentry/react'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { getAuthUser, resetAuthData } from '@services/store/slices/auth'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { themeIcons } from '@theme/icons'
import { useLogoutMutation } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const NavSidebarFooter: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const authUser = useAppSelector(getAuthUser)
  const dispatch = useAppDispatch()

  const [logout] = useLogoutMutation()

  const handleLogout = async () => {
    try {
      await logout()

      dispatch(resetAuthData())

      navigate('/login')
    } catch (error: any) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  return (
    <Box>
      <Divider />

      <Box sx={{
        mt: 3,
        px: 3,
        display: 'flex',
        flexDirection: 'row'
      }}
      >
        <Box sx={{
          flex: 1,
          width: '100%',
          overflow: 'hidden'
        }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}
          >
            {t('common.fullName', { firstName: authUser?.firstname, lastName: authUser?.lastname })}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}
          >
            {authUser?.email}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 0,
            pl: 1
          }}
        >
          <IconButton onClick={handleLogout}>
            <themeIcons.Logout />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
