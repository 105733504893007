import { Box, IconButton, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'

import { TextInput } from './TextInput'

type Props = {
  firstNameKey: string
  lastNameKey: string
  onDelete: () => void
}

export const TeamMemberInput: React.FC<Props> = ({ firstNameKey, lastNameKey, onDelete }) => {
  const { t } = useTranslation()

  const { breakpoints } = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.200',
        border: '1px solid #000',
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          [breakpoints.down('md')]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            flex: 1,
            mr: 2,
            backgroundColor: '#fff'
          }}
        >
          <TextInput
            formKey={firstNameKey}
            label={t('common.firstName')}
            hideValidationText
            hideMaxLength
            options={{
              required: true
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            mr: 2,
            backgroundColor: '#fff',
            [breakpoints.down('md')]: {
              mt: 1
            }
          }}
        >
          <TextInput
            formKey={lastNameKey}
            label={t('common.lastName')}
            hideValidationText
            hideMaxLength
            options={{
              required: true
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 0
        }}
      >
        <IconButton
          onClick={onDelete}
          sx={{
            p: 1
          }}
        >
          <themeIcons.Delete />
        </IconButton>
      </Box>
    </Box>
  )
}
