import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_SYMBOLS
} from '@shared/constants/password'

/* eslint-disable max-len */
export const urlPattern =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

export const mailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const postalPattern = /^[0-9]{5}$/

export const passwordPattern = (value: string) => {
  return new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${PASSWORD_SYMBOLS}])(?=.{${PASSWORD_MIN_LENGTH},})`
  ).test(value)
}
