import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { ActionRowCard } from '@shared/components/ui/ActionRowCard'
import { themeIcons } from '@theme/icons'
import { useTranslation } from 'react-i18next'
import { timestampToDate } from '@utils/date'
import { can } from '@utils/permissions'

import { EventButtonActions, EventHandleActionProps, EventsRowModel } from '../typings/types'

type Props = EventHandleActionProps & {
  event: EventsRowModel
}

export const EventListItem: React.FC<Props> = ({ event, handleAction }) => {
  const { t } = useTranslation()

  return (
    <ActionRowCard actions={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5
        }}
      >
        {can('events:create') && (
          <Tooltip title={t(`events.${event.active ? 'deactivateEvent' : 'activateEvent'}`)}>
            <IconButton onClick={() => handleAction(EventButtonActions.ACTIVATE, event)}>
              {event.active ? <themeIcons.CodeOff /> : <themeIcons.Code />}
            </IconButton>
          </Tooltip>
        )}

        {can(['events:read', 'events:read.own']) && (
          <Tooltip title={t('events.showEvent')}>
            <IconButton onClick={() => handleAction(EventButtonActions.SHOW, event)}>
              <themeIcons.Visibility />
            </IconButton>
          </Tooltip>
        )}

        {can(['events:update', 'events:update.own']) && (
          <Tooltip title={t('events.editEvent')}>
            <IconButton onClick={() => handleAction(EventButtonActions.EDIT, event)}>
              <themeIcons.Edit />
            </IconButton>
          </Tooltip>
        )}
        {can(['events:delete', 'events:delete.own']) && (
          <Tooltip title={t('events.deleteEvent')}>
            <IconButton onClick={() => handleAction(EventButtonActions.DELETE, event)}>
              <themeIcons.Delete />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    }
    >
      <Typography variant="h4">{event.name}</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 1
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mr: 2
          }}
          >
            <themeIcons.Subject />
            <Typography
              variant="subtitle2"
              sx={{
                ml: 0.5
              }}
            >{t('common.subjectWithName', { name: event.texts.subject })}</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
          >
            <themeIcons.DateRange />
            <Typography
              variant="subtitle2"
              sx={{
                ml: 0.5
              }}
            >{t('common.dateRange', {
              from: timestampToDate(new Date(event.startDate), ['month', 'year', 'day']),
              to: timestampToDate(new Date(event.endDate), ['month', 'year', 'day'])
            })}</Typography>
          </Box>
        </Box>
      </Box>
    </ActionRowCard>
  )
}
