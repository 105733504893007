import { FeedbackQuestionProps } from '@features/feedbackSubmission/typings/types'
import { Box, TextField, Typography, useTheme } from '@mui/material'
import { MAX_TEXT_LENGTH } from '@shared/constants/input'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'
import { themeIcons } from '@theme/icons'
import { t } from 'i18next'
import React from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'

const MAX_LENGTH = 50

type ChoiceAnswerProps = React.PropsWithChildren & {
  onSelectAnswer?: () => void
  selected: boolean
}

const ChoiceAnswer: React.FC<ChoiceAnswerProps> = ({ onSelectAnswer, selected, children }) => {
  return (
    <Box
      sx={{
        mb: 1,
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid black',
        cursor: 'pointer',
        backgroundColor: selected ? 'primary.main' : 'white',
        '&:hover': {
          backgroundColor: selected ? 'primary.main' : 'grey.100'
        }
      }}
      onClick={onSelectAnswer}
    >
      {children}
      <themeIcons.Check sx={{
        color: selected ? '#fff' : 'transparent'
      }}
      />
    </Box>
  )
}

export const FeedbackQuestionChoice: React.FC<FeedbackQuestionProps> = ({ question }) => {
  const { breakpoints } = useTheme()

  const { control, setValue, getValues } = useFormContext()

  const [otherSelected, setOtherSelected] = React.useState(false)
  const [otherValue, setOtherValue] = React.useState('')

  useController({
    name: `feedback.${question.id}`,
    control,
    defaultValue: undefined,
    rules: {
      required: question.config.required || false,
      validate: (value) => !!value?.answers?.length || !!value?.other || !question.config.required
    }
  })

  const answers = React.useMemo(() => {
    return question.feedbackQuestionAnswers.map((answer) => {
      return {
        id: answer.id,
        text: answer.texts.answer
      }
    })
  }, [question])

  const handleSelectAnswer = (answerId: string) => {
    const currentValue = getValues(`feedback.${question.id}.answers`)

    if (question.config.multiple) {
      setValue(`feedback.${question.id}.answers`, currentValue?.includes(answerId)
        ? currentValue.filter((id: string) => id !== answerId)
        : [...(currentValue || []), answerId], {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      setValue(`feedback.${question.id}.answers`, currentValue?.includes(answerId) ? [] : [answerId], {
        shouldValidate: true,
        shouldDirty: true
      })

      setValue(`feedback.${question.id}.other`, '', {
        shouldValidate: true,
        shouldDirty: true
      })

      setOtherSelected(false)
    }
  }

  const handleOtherFocus = () => {
    setOtherSelected(true)

    if (!question.config.multiple) {
      setValue(`feedback.${question.id}.answers`, [], {
        shouldValidate: true,
        shouldDirty: true
      })

      setValue(`feedback.${question.id}.other`, otherValue, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }

  const onOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value.length > MAX_LENGTH) {
      return
    }

    setOtherValue(value)

    setValue(`feedback.${question.id}.other`, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  return (
    <Box>
      <Box sx={{
        mb: 2
      }}
      >
        <Typography variant='subtitle1'
          sx={{
            [breakpoints.down('md')]: {
              fontSize: fontSizes.xs,
              lineHeight: lineHeights.xs
            }
          }}
        >
          {t(`feedbackSubmission.${question.config.multiple ? 'multipleChoice' : 'singleChoice'}`)}
        </Typography>
      </Box>

      <Box>
        <Controller
          control={control}
          name={`feedback.${question.id}.answers`}
          render={({ field: { value } }) => (
            <>
              {
              answers.map((answer) => (
                <ChoiceAnswer
                  key={answer.id}
                  selected={value?.includes(answer.id)}
                  onSelectAnswer={() => handleSelectAnswer(answer.id)}
                >

                  <Typography>
                    {answer.text}
                  </Typography>
                </ChoiceAnswer>
              ))}
            </>
          )}
        />
        {
          question.config.other && (
            <Controller
              control={control}
              name={`feedback.${question.id}.other`}
              render={({ field: { value } }) => (
                <>
                  <ChoiceAnswer
                    selected={question.config.multiple ? value?.length > 0 : otherSelected}
                  >
                    <TextField
                      onChange={onOtherChange}
                      onClick={handleOtherFocus}
                      placeholder={t('feedbackSubmission.other')}
                      fullWidth
                      inputProps={{
                        maxLength: MAX_TEXT_LENGTH
                      }}
                      variant='standard'
                      spellCheck={false}
                      sx={{
                        '.MuiInputBase-root': {
                          '&:before': {
                            display: 'none'
                          },
                          '&:after': {
                            display: 'none'
                          },
                          input: {
                            padding: 0
                          }
                        }
                      }}
                    />
                  </ChoiceAnswer>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Typography variant="subtitle2">
                      {t('inputs.charactersRemaining', { count: MAX_TEXT_LENGTH - (value?.length || 0) })}
                    </Typography>
                  </Box>
                </>
              )}
            />
          )
        }

      </Box>
    </Box>
  )
}
