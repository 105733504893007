import React from 'react'
import { BaseDrawer, BaseDrawerProps } from '@shared/components/ui/BaseDrawer'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { FetchResult } from '@apollo/client'

type Props = BaseDrawerProps & {
  title: string
  handleCreate: (data: any) => Promise<FetchResult<any>>
  defaultValues?: Record<string, any>
}

export const BaseCreateDrawer: React.FC<Props> = ({ title, handleCreate, onSuccess, onError, onClose, defaultValues, children, ...props }) => {
  const { t } = useTranslation()

  const formData = useForm({
    defaultValues,
    mode: 'onChange'
  })

  const { handleSubmit, formState: { isDirty, isValid }, reset } = formData

  const { trigger } = useUnsavedChanges({
    when: isDirty,
    proceed: onClose
  })

  const [loading, setLoading] = React.useState(false)

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await handleCreate(data)
      onSuccess?.()
    } catch (error: any) {
      onError?.(error)
    } finally {
      setLoading(false)
    }
  })

  return (
    <BaseDrawer
      title={title}
      onClose={trigger}
      contentProps={{
        sx: {
          p: 0
        }
      }}
      onTransitionEnd={() => reset()}
      footer={
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={!isDirty || !isValid}
          onClick={onSubmit}
        >
          {t('action.save')}
        </LoadingButton>
      }
      {...props}
    >
      <FormProvider {...formData}>
        <form style={{ height: '100%', overflow: 'auto' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
          >
            <Box sx={{
              flex: 1,
              p: 3
            }}
            >
              {children}
            </Box>
          </Box>
        </form>

      </FormProvider>
    </BaseDrawer>
  )
}
