import { Box, Button } from '@mui/material'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FeedbackQuestionsButtonActions, FeedbackQuestionsHandleActionProps } from '../typings/types'

export const FeedbackQuestionsHeaderExtension: React.FC<FeedbackQuestionsHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  const onCreateFeedbackClicked = () => {
    handleAction(FeedbackQuestionsButtonActions.CREATE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {can('feedback:create') && (
        <Button
          sx={{ width: 'auto' }}
          onClick={onCreateFeedbackClicked}
        >
          {t('feedbackQuestions.createQuestion')}
        </Button>
      )}
    </Box>
  )
}
