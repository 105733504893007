import React from 'react'
import MDEditor, { commands } from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'
import { Box, BoxProps, Typography } from '@mui/material'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'

import { HeadlineExtension } from './mdExtensions/HeadlineExtension'

export type MarkdownEditorProps = {
  name: string,
  width?: string | number,
  height?: number,
  maxLength?: number,
  options?: RegisterOptions
  style?: BoxProps['style']
  previewLabel?: boolean,
  hideExtraCommands?: boolean
}

const extraCommands = [
  commands.codeEdit,
  commands.codeLive,
  commands.codePreview
]

export const MarkdownEditor: React.FC<MarkdownEditorProps> =
({ name, options, width, height = 300, style, hideExtraCommands, maxLength, previewLabel }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const editorCommands = React.useMemo(() => [
    {
      name: 'bold',
      keyCommand: 'bold',
      buttonProps: { 'aria-label': t('mdEditor.bold') },
      icon: <themeIcons.FormatBold />,
      execute: (state: commands.TextState, api: commands.TextAreaTextApi) => {
        let modifyText = `**${state.selectedText}**`
        const { selection } = state

        if (!state.selectedText) {
          modifyText = '****'
        }
        api.replaceSelection(modifyText)

        if (!state.selectedText) {
          selection.start += 2
          selection.end += 2
          api.setSelectionRange(selection)
        }
      }
    },
    {
      name: 'italic',
      keyCommand: 'italic',
      buttonProps: { 'aria-label': t('mdEditor.italic') },
      icon: <themeIcons.FormatItalic />,
      execute: (state: commands.TextState, api: commands.TextAreaTextApi) => {
        let modifyText = `*${state.selectedText}*`
        const { selection } = state

        if (!state.selectedText) {
          modifyText = '**'
        }
        api.replaceSelection(modifyText)

        if (!state.selectedText) {
          selection.start += 1
          selection.end += 1
          api.setSelectionRange(selection)
        }
      }
    },
    commands.group([commands.title1, commands.title2, commands.title3], {
      name: 'title',
      groupName: 'title',
      buttonProps: { 'aria-label': t('mdEditor.insertTitle') },
      icon: <themeIcons.Title />,
      children: (handle) => (<HeadlineExtension {...handle} />)
    }),
    commands.quote,
    commands.divider,
    commands.unorderedListCommand,
    commands.orderedListCommand,
    commands.divider,
    {
      name: 'help',
      keyCommand: 'help',
      buttonProps: { 'aria-label': t('mdEditor.help') },
      icon: <themeIcons.Help />,
      execute: () => {
        window.open('https://www.markdownguide.org/basic-syntax/', '_blank')
      }
    }
  ], [])

  return (
    <Box width={width}
      style={style}
      sx={{
        '.w-md-editor': {
          borderRadius: 0,
          border: 'solid 1px',
          borderColor: '#000',
          boxShadow: 'none',
          '.w-md-editor-toolbar': {
            borderRadius: 0,
            borderColor: '#000',
            alignItems: 'center',
            ul: {
              display: 'flex',
              alignItems: 'center'
            },
            '.w-md-editor-toolbar-divider': {
              height: '18px',
              marginTop: '2px!important'
            },
            button: {
              margin: 0,
              height: 'auto',
              width: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pb: '3px',
              svg: {
                height: '20px',
                width: '20px',
                p: '2px',
                '&.MuiSvgIcon-root': {
                  p: 0
                }
              }
            }
          },
          '.w-md-editor-preview': {
            borderRadius: 0,
            borderColor: '#000',
            boxShadow: 'none',
            borderLeft: 'solid 1px'
          }
        },
        ...(previewLabel && {
          '.w-md-editor-preview': {
            paddingTop: '60px!important',
            borderRadius: 0,
            borderColor: '#000',
            boxShadow: 'none',
            borderLeft: 'solid 1px',
            '&::before': {
              content: `"${t('mdEditor.preview')}"`,
              position: 'absolute',
              top: '14px',
              fontSize: '18px',
              fontWeight: 'bold'
            }
          }
        })
      }}
    >
      <Controller
        name={name}
        control={control}
        rules={options}
        render={({ field: { value, onChange } }) => (
          <>
            <MDEditor
              value={value}
              onChange={onChange}
              height={height}
              commands={editorCommands}
              extraCommands={hideExtraCommands ? [] : extraCommands}
              textareaProps={{
                maxLength
              }}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]]
              }}
            />

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
            >
              {maxLength && <Typography variant="subtitle2"
                sx={{
                  mt: 0.5
                }}
              >
                {t('inputs.charactersRemaining', { count: maxLength - (value ? value.length : 0) })}
              </Typography>}
            </Box>
          </>)}
      ></Controller>
    </Box>
  )
}
