import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid } from '@mui/material'
import { useDialogContext } from '@providers/DialogProvider'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { GlobalDialogType } from '@typings/dialogs'
import { DefaultIconButton } from '@shared/components/ui/DefaultIconButton'
import { can } from '@utils/permissions'

import { EvaluationDataProvider, useEvaluationContext } from '../provider/EvaluationDataProvider'
import { EvaluationTeamRanking } from '../components/EvaluationTeamRanking'
import { EvaluationChallengeFilter } from '../components/EvaluationChallengeFilter'

const EvaluationViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { dialogManagerRef } = useDialogContext()
  const { schemeData, challengesData, loading, handleDeleteRatings } = useEvaluationContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const handleDeleteRatingsConfirm = async () => {
    try {
      await handleDeleteRatings()

      handleSuccess(t('evaluation.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handelDeleteRatings = () => {
    dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
      confirmCallback: async () => {
        return await handleDeleteRatingsConfirm()
      },
      dialogProps: {
        title: t('dialogs.deleteRatings.title'),
        children: t('dialogs.deleteRatings.message')
      }
    })
  }

  return (
    <AdminContentLayout
      title={t('routes.evaluation')}
      loading={loading}
      headerContent={can('ratings:delete') && (<DefaultIconButton onClick={handelDeleteRatings} icon="Delete" />)}
    >

      { challengesData?.length >= 0 &&
      (
        <Box sx={{
          mb: 3
        }}
        >
          <EvaluationChallengeFilter />
        </Box>
      )}

      {schemeData &&
      <EvaluationTeamRanking
        title={t('evaluation.ranking')}
        criteria={schemeData?.schemeCriteria}
      />}

      <Divider
        sx={{
          my: 4
        }}
      />

      <Grid
        container
        spacing={2}
      >
        {schemeData?.schemeCriteria?.map((item) => (
          <Grid
            key={item.id}
            item
            xs={12}
          >
            <EvaluationTeamRanking
              criteria={item.children || []}
              title={item.texts.title}
              criteriaId={item.id}
            />
          </Grid>
        ))}
      </Grid>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}

export const EvaluationView: React.FC = () => (
  <EvaluationDataProvider>
    <EvaluationViewContent />
  </EvaluationDataProvider>
)
