import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { Box } from '@mui/material'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { DefaultIconButton } from '@shared/components/ui/DefaultIconButton'
import { can } from '@utils/permissions'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'
import { UpdateSchemeInput } from '@typings/graphql'

import { SchemeDataProvider, useSchemeContext } from '../provider/SchemeDataProvider'
import { SchemeButtonActions } from '../typings/types'
import { MissingSchemeFallback } from '../components/MissingSchemeFallback'
import { SchemeDetails } from '../components/SchemeDetails'
import { SchemeCreateEditInputs } from '../components/SchemeCreateEditInputs'

import { SchemeCriteriaView } from './SchemeCriteriaView'

const SchemeViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { schemeData, loading, handleDeleteScheme, handleUpdateScheme, handleCreateScheme } = useSchemeContext()

  const { dialogManagerRef } = useDialogContext()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const resetModel = React.useMemo<UpdateSchemeInput>(() => {
    return {
      ratingScale: schemeData?.ratingScale
    }
  }, [schemeData])

  const handleDeleteSchemeConfirm = async () => {
    try {
      await handleDeleteScheme()

      handleSuccess(t('scheme.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleSchemeCreated = () => {
    handleSuccess(t('scheme.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleSchemeUpdated = () => {
    handleSuccess(t('scheme.editSuccess'))
    setShowEditDrawer(false)
  }

  const onHandleAction = (action: SchemeButtonActions) => {
    switch (action) {
      case SchemeButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case SchemeButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            return await handleDeleteSchemeConfirm()
          },
          dialogProps: {
            title: t('dialogs.deleteScheme.title'),
            children: t('dialogs.deleteScheme.message')
          }
        })
        break
      case SchemeButtonActions.EDIT:
        setShowEditDrawer(true)
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.scheme')}
      loading={loading}
      headerContent={ schemeData && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1
        }}
        >
          {can(['schemes:update', 'schemes:update.own']) && (
            <DefaultIconButton onClick={() => onHandleAction(SchemeButtonActions.EDIT)} icon="Edit" />
          )}
          {can('schemes:delete') && (<DefaultIconButton onClick={() => onHandleAction(SchemeButtonActions.DELETE)} icon="Delete" />)}
        </Box>
      )}
    >
      {
        !schemeData
          ? (
            <MissingSchemeFallback handleAction={onHandleAction} />
            )
          : (
            <>
              <SchemeDetails />
              <Box sx={{
                mt: 2
              }}
              >
                <SchemeCriteriaView
                  onError={handleError}
                  onSuccess={handleSuccess}
                />
              </Box>
            </>
            )
      }

      <BaseCreateDrawer
        title={t('scheme.createScheme')}
        handleCreate={handleCreateScheme}
        open={showCreateDrawer}
        onError={handleError}
        onSuccess={handleSchemeCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <SchemeCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={handleUpdateScheme}
        title={t('scheme.editScheme')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleSchemeUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <SchemeCreateEditInputs />
      </BaseEditDrawer>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}
export const SchemeView: React.FC = () => {
  return (
    <SchemeDataProvider>
      <SchemeViewContent />
    </SchemeDataProvider>
  )
}
