import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

import { RegisterTeamInput } from '../typings/types'
import { useRegisterTeamContext } from '../provider/RegisterTeamDataProvider'

import { TeamRegisterInputs } from './TeamRegisterInputs'

type Props = React.PropsWithChildren & {
  open?: boolean,
  onError?: (error: any) => void
  onClose: () => void
  onSuccess: () => void
}

export const RegisterTeamDialog: React.FC<Props> = (props) => {
  const { breakpoints } = useTheme()

  const {
    onError,
    onClose,
    open,
    onSuccess
  } = props

  const defaultTeamMembers = React.useMemo(() => {
    return Array.from(Array(4)).map(() => ({
      id: Math.random().toString(36).substr(2, 9),
      firstName: '',
      lastName: ''
    }))
  }, [])

  const formData = useForm<RegisterTeamInput>({
    defaultValues: { teamMembers: defaultTeamMembers },
    mode: 'onChange'
  })

  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const { handleRegisterTeam } = useRegisterTeamContext()

  const { handleSubmit, reset, formState: { isDirty, isValid } } = formData

  const handleCancel = () => {
    reset()
    onClose()
  }

  const submit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await handleRegisterTeam(data)
      onSuccess()
    } catch (error: any) {
      onError?.(error)
    } finally {
      setLoading(false)
    }
  })

  return (
    <ConfirmDialog
      open={!!open}
      onCancel={handleCancel}
      onConfirm={submit}
      loading={loading}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '50%',
          [breakpoints.down('md')]: {
            width: '95%',
            maxWidth: '95%'
          }
        }
      }}
      title={t('action.register')}
      confirmProps={{
        disabled: !isDirty || !isValid
      }}
    >
      <FormProvider {...formData}>
        <form>
          <TeamRegisterInputs />
        </form>
      </FormProvider>
    </ConfirmDialog>
  )
}
