import { Link, Typography } from '@mui/material'
import { TeamAttachmentModal } from '@shared/components/ui/TeamAttachmentModal'
import { palette } from '@theme/core/palette/palette'
import { fontSizes } from '@theme/core/typography/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { TeamAttachment } from '../typings/types'

type Props = {
  files: TeamAttachment[];
};

export const JuryTeamAttachments: React.FC<Props> = ({ files }) => {
  const { t } = useTranslation()

  const [showFilePreview, setShowFilePreview] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState<TeamAttachment>()

  const handleFileOpen = (file: TeamAttachment) => {
    if (isMobile && file.mimeType.includes('pdf')) {
      window.open(file.url, '_blank')
      return
    }

    setSelectedFile(file)
    setShowFilePreview(true)
  }

  return (
    <>
      <Typography
        variant='subtitle1'
        fontWeight="bold"
      >
        {t('common.attachments')}
      </Typography>
      {files.map((file) => (
        <Link
          key={file.id}
          sx={{
            mt: 1,
            display: 'block',
            fontSize: fontSizes.sm,
            cursor: 'pointer',
            color: palette?.grey?.['700'],
            textDecorationColor: palette?.grey?.['700'],
            '&:hover': {
              color: palette?.grey?.['700'],
              textDecoration: 'underline',
              textDecorationColor: palette?.grey?.['700']
            }
          }}
          variant='body1'
          onClick={() => handleFileOpen(file)}
        >
          {file.fileName}
        </Link>
      ))}

      <TeamAttachmentModal file={selectedFile} open={showFilePreview} onClose={() => setShowFilePreview(false)} />
    </>
  )
}
