import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Divider } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = React.PropsWithChildren & {
  headerExtension?: React.ReactNode,
  title?: string,
  defaultExpanded?: boolean
}

export const DefaultAccordion: React.FC<Props> = ({ headerExtension, title, defaultExpanded, children }) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'secondary.100'
      }}
    >
      <AccordionSummary
        expandIcon={<themeIcons.KeyboardArrowDown />}
      >

        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
          <Box sx={{
            mr: 5
          }}
          >
            {headerExtension}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{
          my: 2
        }}
        />
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
