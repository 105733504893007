import React from 'react'
import {
  CreateChallengeInput,
  CreateChallengeMutation,
  DeleteChallengeMutation,
  ReorderChallengesMutation,
  UpdateChallengeMutation,
  UpdateChallengeInput,
  useCreateChallengeMutation,
  useDeleteChallengeMutation,
  useChallengesQuery,
  useReorderChallengesMutation,
  useUpdateChallengeMutation,
  ChallengesDocument
} from '@typings/graphql'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from 'apollo-link'
import { mapTextsForMutation } from '@utils/translations'

import { ChallengesModel } from '../typings/types'

export type ChallengesDataProviderContextType = {
  challengesData: ChallengesModel[]
  loading: boolean,
  handleDeleteChallenge: (id: string) => Promise<FetchResult<DeleteChallengeMutation>>
  handleUpdateChallenge: (id: string, input: UpdateChallengeInput) => Promise<FetchResult<UpdateChallengeMutation>>
  handleCreateChallenge: (input: CreateChallengeInput) => Promise<FetchResult<CreateChallengeMutation>>
  handleReorderChallenge: (order: ChallengesModel[]) => Promise<FetchResult<ReorderChallengesMutation>>
}

const ChallengesDataProviderContext = React.createContext<ChallengesDataProviderContextType>(
  {} as any
)

export const ChallengesDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const { data, loading: challengesDataLoading } = useChallengesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string
    },
    skip: !id
  })

  const [createChallenge] = useCreateChallengeMutation({
    update (cache, { data: updatedData }) {
      const created = updatedData?.createChallenge

      cache.updateQuery({
        query: ChallengesDocument,
        variables: {
          eventId: id as string
        }
      }, (challenges) => {
        const challengesData = challenges?.challenges || []

        return {
          challenges: [...challengesData, created].sort((a, b) => a.order - b.order)
        }
      })
    }
  })

  const [updateChallenge] = useUpdateChallengeMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.updateChallenge

      if (updated) {
        cache.modify({
          id: cache.identify({
            __typename: 'Challenge',
            id: updated.id
          }),
          fields: {
            texts () {
              return updated.texts
            }
          }
        })
      }
    }
  })
  const [reorderChallenge] = useReorderChallengesMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.reorderChallenges

      if (updated) {
        for (const item of updated) {
          cache.modify({
            id: cache.identify({
              __typename: 'Challenge',
              id: item.id
            }),
            fields: {
              order () {
                return item.order
              }
            }
          })
        }
      }
    }
  })
  const [deleteChallenge] = useDeleteChallengeMutation({
    update (cache, { data: updatedData }) {
      const deleted = updatedData?.deleteChallenge

      if (deleted) {
        cache.evict({
          id: cache.identify({
            __typename: 'Challenge',
            id: deleted.id
          })
        })

        cache.updateQuery({
          query: ChallengesDocument,
          variables: {
            eventId: id as string
          }
        }, (challenges) => {
          const challengesData = challenges?.challenges || []

          return {
            challenges: [...challengesData].sort((a, b) => a.order - b.order).map((sorted, index) => {
              return {
                ...sorted,
                order: index
              }
            })
          }
        })

        cache.gc()
      }
    }
  })

  const challengesData = React.useMemo<ChallengesModel[]>(() => {
    return data?.challenges || []
  }, [data])

  const loading = useArtificialLoading(500, [challengesDataLoading])

  const handleUpdateChallenge = React.useCallback(async (challengeId: string, input: UpdateChallengeInput) => {
    const { texts, ...rest } = input

    return await updateChallenge({
      variables: {
        id: challengeId,
        data: {
          texts: mapTextsForMutation(texts as Record<string, any>),
          ...rest
        }
      }
    })
  }, [])

  const handleCreateChallenge = React.useCallback(async (input: CreateChallengeInput) => {
    const { texts, ...rest } = input

    return await createChallenge({
      variables: {
        data: {
          ...rest,
          eventId: id as string,
          texts: mapTextsForMutation(texts as Record<string, any>)
        }
      }
    })
  }, [])

  const handleDeleteChallenge = React.useCallback(async (challengeId: string) => {
    return await deleteChallenge({
      variables: {
        id: challengeId
      }
    })
  }, [])

  const handleReorderChallenge = React.useCallback(async (order: ChallengesModel[]) => {
    return await reorderChallenge({
      variables: {
        eventId: id as string,
        order: order.map((item) => item.id)
      }
    })
  }, [])

  const value = React.useMemo<ChallengesDataProviderContextType>(() => {
    return {
      challengesData,
      loading,
      handleCreateChallenge,
      handleUpdateChallenge,
      handleDeleteChallenge,
      handleReorderChallenge
    }
  }, [challengesData, loading])

  return (
    <>
      <ChallengesDataProviderContext.Provider value={value}>
        {children}
      </ChallengesDataProviderContext.Provider>
    </>
  )
}

export const useChallengesContext = () => React.useContext(ChallengesDataProviderContext)
