import React from 'react'
import { Box, Typography } from '@mui/material'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { palette } from '@theme/core/palette/palette'

import { useEvaluationContext } from '../provider/EvaluationDataProvider'
import { SchemeCriteriaData, TeamCriteriaEvaluation } from '../typings/types'

import { EvaluationCriteriaFilter } from './EvaluationCriteriaFilter'

const MAX_CHAR_LENGTH = 12

type Props = {
  criteriaId?: string
  title: string
  criteria: SchemeCriteriaData[],
  vertical?: boolean
}

const CustomNameTick: React.FC<any> = ({ x, y, payload }) => {
  const trimmed = React.useMemo(() => {
    return payload.value.length > MAX_CHAR_LENGTH ? `${payload.value.substring(0, MAX_CHAR_LENGTH)}...` : payload.value
  }, [payload])

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} textAnchor="middle" fontSize={12} transform="rotate(-15)">
        {trimmed}
      </text>
    </g>
  )
}

const CustomTooltip: React.FC<any> = ({ payload, label }) => {
  return (
    <Box sx={{
      backgroundColor: '#fff',
      border: '1px solid #000',
      padding: '16px'
    }}
    >
      <Typography>{label}</Typography>
      <Typography>{(payload?.[0]?.payload?.value || 0).toFixed(2)}</Typography>
    </Box>
  )
}

export const EvaluationTeamRanking: React.FC<Props> = ({ title, criteriaId, vertical, criteria }) => {
  const { rankingData, schemeData } = useEvaluationContext()

  const [criteriaFilter, setCriteriaFilter] = React.useState<string>('')

  const getNestedEvaluationById = React.useCallback((evaluation?: TeamCriteriaEvaluation[]): TeamCriteriaEvaluation[] | null => {
    if (!criteria || !evaluation || !criteriaId) {
      return null
    }

    const found = evaluation.find((item) => item.id === (criteriaId))

    if (found) {
      return found.children || null
    } else {
      for (const item of evaluation) {
        if (item.children) {
          const nestedFound = getNestedEvaluationById(item.children)

          if (nestedFound) {
            return nestedFound
          }
        }
      }

      return null
    }
  }, [criteria, criteriaId])

  const diagramData = React.useMemo(() => {
    if (!rankingData) {
      return []
    }

    return rankingData.map((item) => {
      let value = 0

      const evaluation = criteriaId ? getNestedEvaluationById(item.evaluation) || item.evaluation : item.evaluation

      if (!criteriaFilter) {
        value = evaluation.reduce((acc, entry) => {
          return acc + (entry.value * (entry.weighting ? entry.weighting / 100 : evaluation.length / 100))
        }, 0)
      } else {
        value = evaluation.find((entry) => entry.id === criteriaFilter)?.value || 0
      }

      return {
        id: item.id,
        name: item.name,
        value
      }
    }).sort((a, b) => b.value - a.value)
  }, [rankingData, criteriaFilter])

  return (
    <DashboardCard header={(
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 56
      }}
      >
        <Box
          flex={1}
        >
          <Typography variant="h4" >
            {title}
          </Typography>
        </Box>
        <Box flex={0}>
          {criteria && criteria.length > 1 && (
          <EvaluationCriteriaFilter
            selected={criteriaFilter}
            criteria={criteria}
            onChange={setCriteriaFilter}
          />
          )}
        </Box>
      </Box>
    )}
    >
      <Box sx={{
        width: '100%',
        height: 300,
        overflow: 'hidden',
        mt: 2
      }}
      >
        <ResponsiveContainer>
          <BarChart
            data={diagramData}
            margin={{ right: 40, left: vertical ? 40 : 0 }}
            layout={vertical ? 'vertical' : 'horizontal'}
          >
            {vertical
              ? (
                <>
                  <YAxis
                    dataKey="name"
                    type="category"
                    stroke={palette.text?.primary || '#000000'}
                    tick={{
                      fontSize: 14
                    }}
                  />
                  <XAxis
                    type="number"
                    domain={[0, (schemeData?.ratingScale || 10)]}
                    ticks={Array.from(Array((schemeData?.ratingScale || 10) + 1).keys())}
                    stroke={palette.text?.primary || '#000000'}
                    tick={{
                      fontSize: 14
                    }}
                  />
                </>
                )
              : (
                <>
                  <XAxis
                    dataKey="name"
                    type="category"
                    stroke={palette.text?.primary || '#000000'}
                    tickMargin={10}
                    height={70}
                    minTickGap={-100}
                    tick={<CustomNameTick />}
                  />
                  <YAxis
                    domain={[0, (schemeData?.ratingScale || 10) + 1]}
                    ticks={Array.from(Array((schemeData?.ratingScale || 10) + 1).keys())}
                    stroke={palette.text?.primary || '#000000'}
                    tick={{
                      fontSize: 14
                    }}
                  />
                </>
                )}
            <Tooltip
              cursor={{
                fill: palette?.secondary[400]
              }}
              position={{ y: 100 }}
              animationDuration={100}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey="value"
              fill={palette.primary.main}
              stroke='#000000'
              label={{
                position: vertical ? 'right' : 'top',
                formatter: (value: number) => value.toFixed(2),
                fill: palette.text?.primary || '#000000',
                fontFamily: 'Sora'
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </DashboardCard>
  )
}
