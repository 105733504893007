import { GridColDef } from '@mui/x-data-grid-pro'
import { UsersQuery } from '@typings/graphql'

export enum UserButtonActions {
  'CREATE' = 'CREATE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
  'INVITE' = 'INVITE',
}

export type UserNode = Exclude<UsersQuery['users'], null | undefined>[0]
export type UsersRowModel = Omit<UserNode, '__typename'>

export type UserColumnType = Omit<GridColDef<UsersRowModel>, 'field'> & {
  field: keyof UsersRowModel | 'action',
  hideHeader?: boolean,
}
