import React from 'react'
import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

import { themeIcons } from '../icons'

export type MuiCheckboxType = {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
}

export const MuiCheckbox:MuiCheckboxType = {
  defaultProps: {
    disableRipple: true,

    checkedIcon: <themeIcons.Check />
  },
  styleOverrides: {
    root: {
      padding: '0',
      width: '20px',
      height: '20px',
      border: '1px solid #000000',
      borderRadius: '0',
      '&.Mui-checked': {
        backgroundColor: palette.primary.main,
        '.MuiSvgIcon-root': {
          width: '14px',
          height: '14px',
          color: '#000000'
        }
      }
    }
  }
}
