import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { SortableList } from '@shared/components/sortable/SortableList'
import { ActionRowCard } from '@shared/components/ui/ActionRowCard'
import { useTranslation } from 'react-i18next'
import { FeedbackQuestionType } from '@typings/graphql'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'

import { useFeedbackQuestionsContext } from '../provider/FeedbackQuestionsDataProvider'
import { FeedbackQuestionsButtonActions, FeedbackQuestionsHandleActionProps, FeedbackQuestionsModel } from '../typings/types'

export const FeedbackQuestionsList: React.FC<FeedbackQuestionsHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()
  const { feedbackData, handleReorderQuestion } = useFeedbackQuestionsContext()

  const [internalItems, setInternalItems] = React.useState(feedbackData)

  React.useMemo(() => {
    return setInternalItems([...feedbackData]?.sort((a, b) => a.order - b.order) || [feedbackData])
  }, [feedbackData])

  const handleReorder = (items: FeedbackQuestionsModel[]) => {
    setInternalItems(items)

    handleReorderQuestion(items)
  }

  return (
    <SortableList
      items={internalItems}
      onDragEnd={handleReorder}
      id="sortable_answers"
      grabber
      renderItem={(item, activeId, index, level, clone) => (
        <Box
          key={`sort_feedback_${item.id}`}
          sx={{
            mb: 2,
            opacity: (activeId === item.id && !clone) ? 0 : 1
          }}
        >
          <ActionRowCard
            actions={(
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 0.5
                }}
              >
                {can(['feedback:update', 'feedback:update.own']) && (
                  <Tooltip title={t('feedbackQuestions.editQuestion')}>
                    <IconButton onClick={() => handleAction(FeedbackQuestionsButtonActions.EDIT, item)}>
                      <themeIcons.Edit />
                    </IconButton>
                  </Tooltip>
                )}
                {can(['feedback:delete', 'feedback:delete.own']) && (
                  <Tooltip title={t('feedbackQuestions.deleteQuestion')}>
                    <IconButton onClick={() => handleAction(FeedbackQuestionsButtonActions.DELETE, item)}>
                      <themeIcons.Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            >
              <Box sx={{ flex: 0, mr: 2 }}>
                <SortableGrabber
                  id={item.id}
                >
                  <themeIcons.DragHandle />
                </SortableGrabber>
              </Box>
              <Box sx={{
                flex: 1
              }}
              >
                <Typography variant="h4">{item.texts.title}</Typography>
                <Typography sx={{
                  mt: 1
                }}
                >
                  {t(`feedbackQuestions.types.${item.type === FeedbackQuestionType.Choice
              ? (item.config.multiple ? 'multipleChoice' : 'singleChoice')
              : item.type.toLocaleLowerCase()}`)}
                </Typography>
              </Box>
            </Box>
          </ActionRowCard>
        </Box>
      )}
    />
  )
}
