import React from 'react'
import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import { Trans, useTranslation } from 'react-i18next'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { UpdateFeedbackQuestionInput } from '@typings/graphql'

import { FeedbackQuestionsDataProvider, useFeedbackQuestionsContext } from '../provider/FeedbackQuestionsDataProvider'
import { FeedbackQuestionsHeaderExtension } from '../components/FeedbackQuestionsHeaderExtension'
import { FeedbackQuestionsButtonActions, FeedbackQuestionsModel } from '../typings/types'
import { FeedbackQuestionsList } from '../components/FeedbackQuestionsList'
import { FeedbackQuestionCreateEditInputs } from '../components/FeedbackQuestionCreateEditInputs'
import { FeedbackQuestionCreateDrawer } from '../components/FeedbackQuestionCreateDrawer'

const FeedbackQuestionsViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { loading, handleDeleteQuestion, handleUpdateQuestion } = useFeedbackQuestionsContext()
  const { dialogManagerRef } = useDialogContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<FeedbackQuestionsModel | null>(null)

  const resetModel = React.useMemo<UpdateFeedbackQuestionInput>(() => {
    return {
      texts: currentRow?.texts,
      config: currentRow?.config,
      type: currentRow?.type,
      answers: (currentRow?.feedbackQuestionAnswers.map((item) => ({
        id: item.id,
        order: item.order,
        texts: item.texts
      })) || []).sort((a, b) => a.order - b.order) || []
    }
  }, [currentRow])

  const handleQuestionUpdated = () => {
    handleSuccess(t('feedbackQuestions.editSuccess'))
    setShowEditDrawer(false)
  }

  const handleQuestionCreated = () => {
    handleSuccess(t('feedbackQuestions.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleDeleteQuestionConfirm = async (id: string) => {
    try {
      await handleDeleteQuestion(id)

      handleSuccess(t('feedbackQuestions.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const onHandleAction = (action: FeedbackQuestionsButtonActions, model?: FeedbackQuestionsModel) => {
    switch (action) {
      case FeedbackQuestionsButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case FeedbackQuestionsButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            await handleDeleteQuestionConfirm(model?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteFeedbackQuestion.title'),
            children: <Trans
              i18nKey={'dialogs.deleteFeedbackQuestion.message'}
              values={{
                name: model?.texts.title
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case FeedbackQuestionsButtonActions.EDIT:
        setCurrentRow(model || null)
        setShowEditDrawer(true)
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.feedbackQuestions')}
      headerContent={<FeedbackQuestionsHeaderExtension handleAction={onHandleAction} />}
      loading={loading}
    >
      <FeedbackQuestionsList handleAction={onHandleAction} />

      <FeedbackQuestionCreateDrawer
        open={showCreateDrawer}
        onError={handleError}
        onSuccess={handleQuestionCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <FeedbackQuestionCreateEditInputs />
      </FeedbackQuestionCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateQuestion(currentRow?.id || '', data)}
        title={t('feedbackQuestions.editQuestion')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleQuestionUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <FeedbackQuestionCreateEditInputs disableType />
      </BaseEditDrawer>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}

export const FeedbackQuestionsView: React.FC = () => (
  <FeedbackQuestionsDataProvider>
    <FeedbackQuestionsViewContent />
  </FeedbackQuestionsDataProvider>
)
