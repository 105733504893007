import React from 'react'
import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import { Trans, useTranslation } from 'react-i18next'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { UpdateChallengeInput } from '@typings/graphql'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'

import { ChallengesDataProvider, useChallengesContext } from '../provider/ChallengesDataProvider'
import { ChallengesCreateEditInputs } from '../components/ChallengesCreateEditInputs'
import { ChallengesButtonActions, ChallengesModel } from '../typings/types'
import { ChallengesHeaderExtension } from '../components/ChallengesHeaderExtension'
import { ChallengesList } from '../components/ChallengesList'

const ChallengesViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { loading, handleDeleteChallenge, handleUpdateChallenge, handleCreateChallenge } = useChallengesContext()
  const { dialogManagerRef } = useDialogContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<ChallengesModel | null>(null)

  const resetModel = React.useMemo<UpdateChallengeInput>(() => {
    return {
      texts: currentRow?.texts
    }
  }, [currentRow])

  const handleChallengeUpdated = () => {
    handleSuccess(t('challenges.editSuccess'))
    setShowEditDrawer(false)
  }

  const handleChallengeCreated = () => {
    handleSuccess(t('challenges.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleDeleteChallengeConfirm = async (id: string) => {
    try {
      await handleDeleteChallenge(id)

      handleSuccess(t('challenges.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const onHandleAction = (action: ChallengesButtonActions, model?: ChallengesModel) => {
    switch (action) {
      case ChallengesButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case ChallengesButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            await handleDeleteChallengeConfirm(model?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteChallenge.title'),
            children: <Trans
              i18nKey={'dialogs.deleteChallenge.message'}
              values={{
                name: model?.texts.title
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case ChallengesButtonActions.EDIT:
        setCurrentRow(model || null)
        setShowEditDrawer(true)
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.challenges')}
      headerContent={<ChallengesHeaderExtension handleAction={onHandleAction} />}
      loading={loading}
    >
      <ChallengesList handleAction={onHandleAction} />

      <BaseCreateDrawer
        title={t('challenges.createChallenge')}
        handleCreate={handleCreateChallenge}
        open={showCreateDrawer}
        onError={handleError}
        onSuccess={handleChallengeCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <ChallengesCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateChallenge(currentRow?.id || '', data)}
        title={t('challenges.editChallenge')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleChallengeUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <ChallengesCreateEditInputs />
      </BaseEditDrawer>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}

export const ChallengesView: React.FC = () => (
  <ChallengesDataProvider>
    <ChallengesViewContent />
  </ChallengesDataProvider>
)
