import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiDividerType = {
  defaultProps?: ComponentsProps['MuiDivider'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDivider'];
  variants?: ComponentsVariants['MuiDivider'];
}

export const MuiDivider:MuiDividerType = {
  styleOverrides: {
    root: {
      borderWidth: '1px',
      borderTop: 'none',
      borderColor: palette.grey?.['600']
    },
    light: {
      borderColor: palette.grey?.['200']
    },
    vertical: {
      height: '100%'
    }
  }
}
