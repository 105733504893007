import { Box, Typography, useTheme } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

export type RatingNumberBoxProps = {
  backgroundColor?: string
  color?: string
  size?: string | number
  value: number
  stars?: boolean
  onChange: (value: number) => void
}

export const RatingNumberBox: React.FC<RatingNumberBoxProps> = ({ backgroundColor, stars, color, size, value, onChange }) => {
  const { breakpoints } = useTheme()

  return (
    <Box
      sx={{
        width: size || 34,
        minHeight: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderColor: 'grey.200',
        cursor: 'pointer',
        backgroundColor,
        position: 'relative',
        ...(stars && {
          color
        }),
        [breakpoints.up('md')]: {
          '&:hover': {
            backgroundColor: 'primary.main',
            ...(stars && {
              color: '#fff'
            })
          }
        }
      }}
      onClick={() => onChange(value)}
    >
      {stars
        ? (
          <themeIcons.Star />
          )
        : (
          <Typography
            variant="subtitle1"
            fontWeight={600}
          >
            {value}
          </Typography>
          )}
    </Box>
  )
}
