import React from 'react'
import { SubmissionView } from '@features/submission/views/SubmissionView'
import { PublicLayout } from '@shared/layouts/PublicLayout'
import { FeedbackSubmissionView } from '@features/feedbackSubmission/views/FeedbackSubmissionView'
import { TeamRegistrationView } from '@features/registerTeam/views/TeamRegistrationView'

import { AppRouteObject } from '../router'

export const publicRoutes: AppRouteObject[] = [
  {
    path: '/submission/:id',
    element: <PublicLayout />,
    children: [{
      index: true,
      element: <SubmissionView />
    }]
  },
  {
    path: '/feedback',
    element: <PublicLayout large />,
    children: [{
      index: true,
      element: <FeedbackSubmissionView />
    }]
  },
  {
    path: '/register-team',
    element: <PublicLayout large />,
    children: [{
      index: true,
      element: <TeamRegistrationView />
    }]
  }
]
