import { DashboardCard } from '@shared/components/ui/DashboardCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { timestampToDate } from '@utils/date'
import { Box, Typography } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { SimpleDataRow } from '@shared/components/ui/SimpleDataRow'

import { useEventDetailsContext } from '../provider/EventDetailsDataProvider'
import { DateCountdown } from '../../../shared/components/ui/DateCountdown'

export const SubmissionsCounterCard: React.FC = () => {
  const { t } = useTranslation()

  const { eventData } = useEventDetailsContext()

  const submissionDeadline = React.useMemo<{date: string, time: string} | null>(() => {
    if (!eventData) {
      return null
    }

    return {
      date: timestampToDate(new Date(eventData.endDate), ['day', 'month', 'year']),
      time: timestampToDate(new Date(eventData.endDate), ['hour', 'minute'])
    }
  }, [eventData])

  const startDeadline = React.useMemo<{date: string, time: string} | null>(() => {
    if (!eventData) {
      return null
    }

    return {
      date: timestampToDate(new Date(eventData.startDate), ['day', 'month', 'year']),
      time: timestampToDate(new Date(eventData.startDate), ['hour', 'minute'])
    }
  }, [eventData])

  const isStarted = React.useMemo(() => {
    if (!eventData) {
      return false
    }

    return new Date(eventData.startDate).getTime() < new Date().getTime()
  }, [eventData])

  const submissionOpen = React.useMemo(() => {
    if (!eventData) {
      return false
    }

    return isStarted && eventData.submission && eventData.active
  }, [eventData, isStarted])

  return (
    <DashboardCard
      header={t('common.countdown')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2
        }}
      >
        {eventData?.endDate && (
          <>
            <Typography sx={{
              mb: 1
            }}
            >
              {isStarted ? t('eventDetails.timeTillSubmission') : t('eventDetails.timeTillStart')}
            </Typography>
            <DateCountdown date={isStarted ? new Date(eventData.endDate) : new Date(eventData.startDate)} />
          </>
        )}
      </Box>
      {startDeadline && (
        <>
          <SimpleDataRow title={t('common.startDate')}>
            {startDeadline.date}
          </SimpleDataRow>
          <SimpleDataRow title={t('common.startTime')}>
            {startDeadline.time}
          </SimpleDataRow>
        </>
      )}
      <SimpleDataRow title={t('common.submissionStatus')}>
        <Typography
          sx={{
            color: submissionOpen ? palette.success?.[500] : palette.error?.[500]
          }}
        >
          {submissionOpen ? t('common.open') : t('common.closed')}
        </Typography>
      </SimpleDataRow>
      <SimpleDataRow title={t('common.registration')}>
        <Typography
          sx={{
            color: eventData?.registration ? palette.success?.[500] : palette.error?.[500]
          }}
        >
          {eventData?.registration ? t('common.open') : t('common.closed')}
        </Typography>
      </SimpleDataRow>
      <SimpleDataRow title={t('common.rating')}>
        <Typography
          sx={{
            color: eventData?.rateable ? palette.success?.[500] : palette.error?.[500]
          }}
        >
          {eventData?.rateable ? t('common.open') : t('common.closed')}
        </Typography>
      </SimpleDataRow>
      {submissionDeadline && (
        <>
          <SimpleDataRow title={t('common.submissionDate')}>
            {submissionDeadline.date}
          </SimpleDataRow>
          <SimpleDataRow title={t('common.submissionTime')}>
            {submissionDeadline.time}
          </SimpleDataRow>
        </>
      )}
    </DashboardCard>
  )
}
