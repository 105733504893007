import { TeamColumnType } from '@features/teams/typings/types'
import { getGridNumericOperators } from '@mui/x-data-grid-pro'

export const membersCountColumn: TeamColumnType = {
  field: 'teamMembersCount',
  width: 150,
  filterable: false,
  filterOperators: getGridNumericOperators().filter((operator) => operator.value === 'equals' ||
  operator.value === 'greaterThan' || operator.value === 'lessThan'),
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: (params) => params.row?.teamMembersCount ?? 0
}
