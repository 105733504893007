import {
  CreateSchemeCriteriaInput,
  CreateSchemeCriteriaMutation,
  CreateSchemeInput,
  CreateSchemeMutation,
  DeleteSchemeCriteriaMutation,
  DeleteSchemeMutation,
  RolesQuery,
  SchemeCriteriaDataFragmentDoc,
  UpdateSchemeCriteriaInput,
  UpdateSchemeCriteriaMutation,
  UpdateSchemeInput,
  UpdateSchemeMutation,
  useCreateSchemeCriteriaMutation,
  useCreateSchemeMutation,
  useDeleteSchemeCriteriaMutation,
  useDeleteSchemeMutation,
  useRolesQuery,
  useSchemeByEventForCrudQuery,
  useUpdateSchemeCriteriaMutation,
  useUpdateSchemeMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from '@apollo/client'
import { mapTextsForMutation } from '@utils/translations'

import { SchemeDataModel } from '../typings/types'

export type SchemeDataProviderContextType = {
  roleData: RolesQuery['roles']
  schemeData: SchemeDataModel
  loading: boolean
  handleCreateScheme: (input: CreateSchemeInput) => Promise<FetchResult<CreateSchemeMutation>>
  handleUpdateScheme: (input: UpdateSchemeInput) => Promise<FetchResult<UpdateSchemeMutation>>
  handleDeleteScheme: () => Promise<FetchResult<DeleteSchemeMutation>>
  handleCreateCriteria: (input: CreateSchemeCriteriaInput) => Promise<FetchResult<CreateSchemeCriteriaMutation>>
  handleUpdateCriteria: (id: string, input: UpdateSchemeCriteriaInput) => Promise<FetchResult<UpdateSchemeCriteriaMutation>>
  handleDeleteCriteria: (id: string) => Promise<FetchResult<DeleteSchemeCriteriaMutation>>
}

const SchemeDataProviderContext = React.createContext<SchemeDataProviderContextType>(
  {} as any
)

export const SchemeDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id: eventId } = useParams<{id: string}>()

  const { data, loading: dataLoading } = useSchemeByEventForCrudQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: eventId as string,
      all: true
    },
    skip: !eventId
  })

  const { data: roleData, loading: rolesLoading } = useRolesQuery({
    fetchPolicy: 'cache-and-network'
  })

  const schemeData = React.useMemo<SchemeDataModel>(() => {
    return data?.schemeByEvent
  }, [data])

  const loading = useArtificialLoading(500, [dataLoading, rolesLoading])

  const [createScheme] = useCreateSchemeMutation({
    update (cache, { data: createdData }) {
      const created = createdData?.createScheme

      if (created) {
        cache.modify({
          fields: {
            schemeByEvent () {
              return created
            }
          }
        })
      }
    }
  })

  const [updateScheme] = useUpdateSchemeMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.updateScheme

      if (updated) {
        cache.modify({
          id: cache.identify({
            __typename: 'Scheme',
            id: updated.id
          }),
          fields: {
            ratingScale () {
              return updated.ratingScale
            }
          }
        })
      }
    }
  })

  const [deleteFeedbacks] = useDeleteSchemeMutation({
    update (cache) {
      cache.evict({
        id: cache.identify({
          __typename: 'Scheme',
          id: schemeData?.id
        })
      })

      cache.gc()
    }
  })

  const handleCreateScheme = React.useCallback(async (input: CreateSchemeInput) => {
    return await createScheme({
      variables: {
        data: {
          ...input,
          eventId: eventId as string
        }
      }
    })
  }, [schemeData])

  const [createCriteria] = useCreateSchemeCriteriaMutation({
    update (cache, { data: createdData }) {
      const created = createdData?.createSchemeCriteria

      if (!created) {
        return
      }

      const newCriteriaRef = cache.writeFragment({
        data: created,
        fragment: SchemeCriteriaDataFragmentDoc
      })

      if (created.parentId) {
        cache.modify({
          id: cache.identify({
            __typename: 'SchemeCriteria',
            id: created.parentId
          }),
          fields:
            {
              children (existingChildren = []) {
                return [...existingChildren, newCriteriaRef]
              }
            }
        })
      } else {
        cache.modify({
          id: cache.identify({
            __typename: 'Scheme',
            id: schemeData?.id
          }),
          fields: {
            schemeCriteria (existingCriteria = []) {
              return [...existingCriteria, newCriteriaRef]
            }
          }
        })
      }
    }
  })

  const [updateCriteria] = useUpdateSchemeCriteriaMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.updateSchemeCriteria

      if (updated) {
        cache.modify({
          id: cache.identify({
            __typename: 'SchemeCriteria',
            id: updated.id
          }),
          fields: {
            texts () {
              return updated.texts
            },
            weighting () {
              return updated.weighting || null
            },
            roles () {
              return updated.roles
            }
          }
        })
      }
    }
  })

  const [deleteCriteria] = useDeleteSchemeCriteriaMutation({
    update (cache, { data: deletedData }) {
      const deleted = deletedData?.deleteSchemeCriteria

      if (deleted) {
        cache.evict({
          id: cache.identify({
            __typename: 'SchemeCriteria',
            id: deleted.id
          })
        })

        cache.gc()
      }
    }
  })

  const handleUpdateScheme = React.useCallback(async (input: UpdateSchemeInput) => {
    return await updateScheme({
      variables: {
        id: schemeData?.id as string,
        data: input
      }
    })
  }, [schemeData])

  const handleDeleteScheme = React.useCallback(async () => {
    return await deleteFeedbacks({
      variables: {
        id: schemeData?.id as string
      }
    })
  }, [schemeData])

  const handleCreateCriteria = React.useCallback(async (input: CreateSchemeCriteriaInput) => {
    const { texts, weighting, parentId, roleIds } = input

    return await createCriteria({
      variables: {
        data: {
          weighting: weighting || null,
          parentId: parentId || null,
          schemeId: schemeData?.id as string,
          texts: mapTextsForMutation(texts as Record<string, any>),
          roleIds
        }
      }
    })
  }, [schemeData])

  const handleUpdateCriteria = React.useCallback(async (id: string, input: UpdateSchemeCriteriaInput) => {
    const { texts, weighting, roleIds } = input

    return await updateCriteria({
      variables: {
        id,
        data: {
          weighting: weighting || null,
          texts: mapTextsForMutation(texts as Record<string, any>),
          roleIds
        }
      }
    })
  }, [])

  const handleDeleteCriteria = React.useCallback(async (id: string) => {
    return await deleteCriteria({
      variables: {
        id
      }
    })
  }, [])

  const value = React.useMemo<SchemeDataProviderContextType>(() => {
    return {
      roleData: roleData?.roles || [],
      schemeData,
      loading,
      handleCreateScheme,
      handleDeleteScheme,
      handleUpdateScheme,
      handleCreateCriteria,
      handleUpdateCriteria,
      handleDeleteCriteria
    }
  }, [roleData, schemeData, loading])

  return (
    <>
      <SchemeDataProviderContext.Provider value={value}>
        {children}
      </SchemeDataProviderContext.Provider>
    </>
  )
}

export const useSchemeContext = () => React.useContext(SchemeDataProviderContext)
