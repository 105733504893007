import { TeamColumnType } from '@features/teams/typings/types'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { t } from 'i18next'

export const challengeColumnModel: TeamColumnType = {
  field: 'challenge',
  width: 175,
  minWidth: 175,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  valueGetter: (params) => {
    const { challenge } = params.row

    return challenge
      ? t('challenges.titleWithOrder', { order: challenge.order + 1, title: challenge.texts.title })
      : '-'
  }
}
