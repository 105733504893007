import { ChallengesQuery } from '@typings/graphql'

export enum ChallengesButtonActions {
  'CREATE' = 'CREATE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
}

export type ChallengesModel = ChallengesQuery['challenges'][0]

export type ChallengesHandleActionProps = {
  handleAction: (action: ChallengesButtonActions, model?: ChallengesModel) => void
}
