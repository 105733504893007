import { Box, Button } from '@mui/material'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ChallengesHandleActionProps, ChallengesButtonActions } from '../typings/types'

export const ChallengesHeaderExtension: React.FC<ChallengesHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  const onCreateFeedbackClicked = () => {
    handleAction(ChallengesButtonActions.CREATE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {can('challenges:create') && (
        <Button
          sx={{ width: 'auto' }}
          onClick={onCreateFeedbackClicked}
        >
          {t('challenges.createChallenge')}
        </Button>
      )}
    </Box>
  )
}
