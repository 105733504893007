import { Button, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  label: string
  icon?: keyof typeof themeIcons
  selected?: boolean
  href: string
}
export const NavSidebarLinkListItem: React.FC<Props> = ({ label, icon, selected = false, href }) => {
  const Icon = React.useMemo(() => icon ? themeIcons[icon] || null : null, [icon])

  return (
    <Button
      disableRipple
      variant="text"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        color: 'text.primary',
        width: '100%',
        alignItems: 'left',
        justifyContent: 'flex-start',
        px: 3,
        py: 1,
        backgroundColor: selected ? 'secondary.800' : 'transparent',
        '&:hover': {
          backgroundColor: 'secondary.600'
        }
      }}

      to={href}
      component={Link}
      startIcon={Icon ? <Icon /> : null}
    >
      <Typography>
        {label}
      </Typography>
    </Button>
  )
}
