import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'

import { UserColumnType } from '../typings/types'

import { activeColumnModel } from './columns/activeColumn'
import { emailColumnModel } from './columns/emailColumn'
import { firstnameColumnModel } from './columns/firstnameColumn'
import { lastnameColumnModel } from './columns/lastnameColumn'
import { roleColumnModel } from './columns/roleColumn'

export const userDataGridColumns: UserColumnType[] = [
  emailColumnModel,
  firstnameColumnModel,
  lastnameColumnModel,
  roleColumnModel,
  activeColumnModel,
  createdColumnModel as UserColumnType
]
