import React from 'react'

type UseTimeBlocker = {
  blocked: boolean;
  remainingTime: number;
  startBlocker: () => void;
  stopBlocker: () => void;
};

export const useTimeBlocker = (seconds: number): UseTimeBlocker => {
  const [remainingTime, setRemainingTime] = React.useState(0)
  const [blocked, setBlocked] = React.useState(false)

  const timerIntervalRef = React.useRef<ReturnType<typeof setInterval> | null>(
    null
  )

  const startBlocker = React.useCallback(() => {
    setBlocked(true)
    setRemainingTime(seconds)

    timerIntervalRef.current = setInterval(() => {
      setRemainingTime(prev => {
        const newTime = prev - 1

        if (newTime <= 0) {
          clearInterval(
            timerIntervalRef.current as ReturnType<typeof setInterval>
          )
          timerIntervalRef.current = null

          setBlocked(false)

          return 0
        }

        return newTime
      })
    }, 1000)
  }, [seconds])

  const stopBlocker = React.useCallback(() => {
    clearInterval(timerIntervalRef.current as ReturnType<typeof setInterval>)
    timerIntervalRef.current = null

    setBlocked(false)
    setRemainingTime(0)
  }, [])

  return {
    blocked,
    remainingTime,
    startBlocker,
    stopBlocker
  }
}
