import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { deDE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parseISO } from 'date-fns'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'

type Props = {
  formKey: string
  options?: RegisterOptions
  label?: string,
  description?: string,
  formatter?: (value: Date) => string
  dateTimePickerProps?: DateTimePickerProps<Date>
}

export const DateTimeInput: React.FC<Props> = ({ formKey, label, options, formatter, dateTimePickerProps }) => {
  const { t, i18n } = useTranslation()
  const { control } = useFormContext()

  const localizeWeekday = React.useCallback((day: string) => {
    return i18n.exists(`days.${day.toLowerCase()}`) ? t(`days.${day.toLowerCase()}`) : day
  }, [])

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { onChange, value } }) =>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <DateTimePicker
            sx={{
              width: '100%'
            }}
            onChange={(v) => {
              onChange(formatter && v ? formatter(v) : v)
            }}
            dayOfWeekFormatter={localizeWeekday}
            label={label}
            value={typeof value === 'string' ? parseISO(value) : value || null}
            format="dd.MM.yyyy hh:mm"
            ampm={false}
            closeOnSelect={false}
            timezone='Europe/Berlin'
            timeSteps={{
              minutes: 15
            }}
            slotProps={{
              actionBar: {
                actions: ['clear', 'cancel', 'accept']
              }
            }}
            {...dateTimePickerProps}
          />
        </LocalizationProvider>
          }
    />
  )
}
