import { Box, Button } from '@mui/material'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { UserButtonActions } from '../typings/types'

type Props = {
  handleAction: (action: UserButtonActions) => void
}

export const UserHeaderExtension: React.FC<Props> = ({ handleAction }) => {
  const { t } = useTranslation()

  const onCreateUserClicked = () => {
    handleAction(UserButtonActions.CREATE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {can('users:create') && (
        <Button
          sx={{ width: 'auto' }}
          onClick={onCreateUserClicked}
        >
          {t('users.createUser')}
        </Button>
      )}
    </Box>
  )
}
