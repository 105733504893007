import { FeedbackEvaluationTypeProps } from '@features/feedbackEvaluation/typings/types'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FeedbackEvaluationBar } from '../FeedbackEvaluationBar'

export const FeedbackEvaluationChoice: React.FC<FeedbackEvaluationTypeProps> = ({ data }) => {
  const { t } = useTranslation()

  const mappedAnswers = React.useMemo(() => {
    return data.feedbackQuestionAnswers.map((answer) => {
      const answerCount = data.feedbackAnswers.filter((item) => item.value?.answers?.includes(answer.id)).length

      return {
        id: answer.id,
        answer: answer.texts.answer,
        percentage: answerCount / (data.feedbackAnswers.length || 1),
        count: answerCount
      }
    })
  }, [data])

  const otherAnswers = React.useMemo(() => {
    return data.feedbackAnswers.filter((answer) => answer.value?.other && answer.value?.other !== '').map((answer) => answer.value?.other)
  }, [data])

  return (
    <Box>
      {mappedAnswers.map((answer) => (
        <Box
          key={answer.id}
          sx={{
            mb: 2
          }}
        >
          <FeedbackEvaluationBar
            label={answer.answer}
            total={data.feedbackAnswers.length}
            value={answer.count}
          />
        </Box>
      ))}
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        >
          <Typography>
            {t('common.other')}
          </Typography>
          <Typography>
            {t('common.feedbacks', { count: otherAnswers.length })}
          </Typography>
        </Box>

        <Box sx={{
          mt: 1
        }}
        >
          {otherAnswers.map((item, index) => (
            <Box
              key={`question_${data.id}_other_${index}`}
              sx={{
                mb: 1,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Box sx={{
                flex: 0,
                minWidth: '25px',
                mr: 1
              }}
              >
                {`${index + 1}.`}
              </Box>

              <Typography>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

    </Box>
  )
}
