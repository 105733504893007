import { GridColDef } from '@mui/x-data-grid-pro'
import { EventsQuery } from '@typings/graphql'

export enum EventButtonActions {
  'CREATE' = 'CREATE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
  'ACTIVATE' = 'ACTIVATE',
  'SHOW' = 'SHOW',
}

export type EventNode = Exclude<EventsQuery['events'], null | undefined>[0]
export type EventsRowModel = Omit<EventNode, '__typename'>

export type EventColumnType = Omit<GridColDef<EventsRowModel>, 'field'> & {
  field: keyof EventsRowModel | 'action',
  hideHeader?: boolean,
}

export type EventHandleActionProps = {
  handleAction: (action: EventButtonActions, rowModel?: EventsRowModel) => void
}
