import { Box, IconButton, Typography, Link as MuiLink, useTheme } from '@mui/material'
import { Logo } from '@shared/components/ui/Logo'
import { themeIcons } from '@theme/icons'
import { useLogoutMutation } from '@typings/graphql'
import React from 'react'
import { Trans } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router'

export const JuryLayout: React.FC = () => {
  const { breakpoints } = useTheme()
  const navigate = useNavigate()

  const [logout] = useLogoutMutation()

  const handleLogoClicked = () => {
    navigate('/jury')
  }

  const handleLogout = async () => {
    await logout()
    navigate('/login')
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'secondary.200',
          alignItems: 'center',
          px: 2,
          py: 2
        }}
      >
        <Box
          sx={{
            flex: 0,
            height: 35
          }}
          onClick={handleLogoClicked}
        >
          <Logo sx={{
            width: 140,
            height: 35
          }}
          />
        </Box>
        <IconButton onClick={handleLogout}>
          <themeIcons.Logout />
        </IconButton>
      </Box>
      <Box sx={{
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
      >
        {
          /**
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              opacity: 0.1,
              transform: 'translate(25%, 25%)',
              zIndex: 1
            }}
            >
              <Logo colorVariant='black'
                minimal
                sx={{
                  width: 400,
                  height: 400
                }}
              />
            </Box>
          */
        }
        <Box sx={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          zIndex: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
        >
          <Box
            sx={{
              height: '100%',
              width: '50%',
              [breakpoints.down('md')]: {
                width: '100%',
                mt: 0
              }
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: '1px solid #000000',
          flex: 0,
          px: 2,
          py: 0.5
        }}
      >
        <Typography
          variant='subtitle2'
        >
          <Trans
            i18nKey="common.developedBy"
            components={{
              a: <MuiLink
                sx={{
                  display: 'inline'
                }}
                variant='subtitle2'
                target='_blank'
                href="https://kaleidoscode.de"
              />
            }}
          />
        </Typography>
      </Box>
    </Box>
  )
}
