import { FeedbackQuestionProps, FeedbackQuestions } from '@features/feedbackSubmission/typings/types'
import { FeedbackQuestionType } from '@typings/graphql'
import React from 'react'

import { FeedbackQuestionRating } from './FeedbackQuestionRating'
import { FeedbackQuestionOpen } from './FeedbackQuestionOpen'
import { FeedbackQuestionChoice } from './FeedbackQuestionChoice'

export const feedbackQuestionTypes: FeedbackQuestions = {
  [FeedbackQuestionType.Rating]: FeedbackQuestionRating,
  [FeedbackQuestionType.Open]: FeedbackQuestionOpen,
  [FeedbackQuestionType.Choice]: FeedbackQuestionChoice
}

export const FeedbackQuestion: React.FC<FeedbackQuestionProps> = (props) => {
  const { question } = props

  const Question = React.useMemo(() => feedbackQuestionTypes[question.type], [question])

  return (
    <Question {...props} />
  )
}
