import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { UpdateTeamInput } from '@typings/graphql'

import { TeamsDataProvider, useTeamsContext } from '../provider/TeamsDataProvider'
import { TeamsDataGrid } from '../dataGrid/TeamsDataGrid'
import { TeamButtonActions, TeamsRowModel } from '../typings/types'
import { TeamsHeaderExtension } from '../components/TeamsHeaderExtension'
import { TeamCreateEditInputs } from '../components/TeamCreateEditInputs'
import { TeamRatingDrawer } from '../components/TeamRatingDrawer'
import { DEFAULT_MEMBERS_COUNT } from '../constants/constants'

const TeamsViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { dialogManagerRef } = useDialogContext()
  const {
    handleDeleteTeam,
    handleCreateTeam,
    handleUpdateTeam,
    handleRateTeam,
    handleActivateTeam,
    handleResendCode
  } = useTeamsContext()

  const {
    showSnackbar,
    message,
    setShowSnackbar,
    severity,
    handleSuccess,
    handleError
  } = useSnackbar()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [showRatingDrawer, setShowRatingDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<TeamsRowModel | null>(null)

  const defaultTeamMembers = React.useMemo(() => {
    return Array.from(Array(DEFAULT_MEMBERS_COUNT)).map(() => ({
      id: Math.random().toString(36).substr(2, 9),
      firstName: '',
      lastName: ''
    }))
  }, [])

  const resetModel = React.useMemo<UpdateTeamInput>(() => {
    return {
      name: currentRow?.name || '',
      contact: currentRow?.contact || '',
      challengeId: currentRow?.challengeId || '',
      teamMembers: currentRow?.teamMembers || defaultTeamMembers
    }
  }, [currentRow])

  const handleDeleteTeamConfirm = async (id: string) => {
    try {
      await handleDeleteTeam(id)

      handleSuccess(t('teams.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleTeamCreated = () => {
    handleSuccess(t('teams.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleTeamUpdated = () => {
    handleSuccess(t('teams.editSuccess'))
    setShowEditDrawer(false)
  }

  const handleTeamRated = () => {
    handleSuccess(t('teams.rateSuccess'))
    setShowRatingDrawer(false)
  }

  const handleActivateTeamConfirm = async (id: string) => {
    try {
      await handleActivateTeam(id)

      handleSuccess(t('teams.activateSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleSendVerificationCode = async (id: string) => {
    try {
      await handleResendCode(id)

      handleSuccess(t('teams.sendVerificationCodeSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const onHandleAction = (action: TeamButtonActions, rowData?: TeamsRowModel) => {
    switch (action) {
      case TeamButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case TeamButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            await handleDeleteTeamConfirm(rowData?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteTeam.title'),
            children: <Trans
              i18nKey={'dialogs.deleteTeam.message'}
              values={{
                name: rowData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case TeamButtonActions.EDIT:
        setCurrentRow(rowData || null)
        setShowEditDrawer(true)
        break
      case TeamButtonActions.RATE:
        setCurrentRow(rowData || null)
        setShowRatingDrawer(true)
        break
      case TeamButtonActions.ACTIVATE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
          confirmCallback: async () => {
            await handleActivateTeamConfirm(rowData?.id || '')
          },
          dialogProps: {
            title: t('dialogs.activateTeam.title'),
            children: <Trans
              i18nKey={'dialogs.activateTeam.message'}
              values={{
                name: rowData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case TeamButtonActions.RESEND_CODE:
        handleSendVerificationCode(rowData?.id || '')

        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.teams')}
      headerContent={<TeamsHeaderExtension handleAction={onHandleAction} />}
    >
      <TeamsDataGrid handleAction={onHandleAction} />

      <BaseCreateDrawer
        handleCreate={handleCreateTeam}
        title={t('teams.createTeam')}
        open={showCreateDrawer}
        defaultValues={{
          teamMembers: defaultTeamMembers
        }}
        onError={handleError}
        onSuccess={handleTeamCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <TeamCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateTeam(currentRow?.id || '', data)}
        title={t('teams.editTeam')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleTeamUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <TeamCreateEditInputs />
      </BaseEditDrawer>

      <TeamRatingDrawer
        team={currentRow}
        handleUpdate={(data) => handleRateTeam(currentRow?.id || '', data)}
        open={showRatingDrawer}
        onSuccess={handleTeamRated}
        onError={handleError}
        onClose={() => setShowRatingDrawer(false)}
      />

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}
export const TeamsView: React.FC = () => {
  return (
    <TeamsDataProvider>
      <TeamsViewContent />
    </TeamsDataProvider>
  )
}
