import React from 'react'
import { Box } from '@mui/material'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { useTranslation } from 'react-i18next'

import { SchemeButtonActions, SchemeCriteriaModel, SchemeHandleActionProps } from '../typings/types'

import { SchemeCriteriaListItem } from './SchemeCriteriaListItem'

type Props = SchemeHandleActionProps & {
  criteria?: SchemeCriteriaModel[]
  level?: number,
}

export const SchemeCriteriaList: React.FC<Props> = ({ criteria, level = 0, handleAction }) => {
  const { t } = useTranslation()
  return (
    <>
      {criteria?.map((item) => (
        <Box
          key={item.id}
          sx={{
            mt: level === 0 ? 2 : 1,
            ml: level === 0 ? 0 : 2
          }}
        >
          <SchemeCriteriaListItem
            handleAction={handleAction}
            data={item}
            level={level}
            extension={ level < 1 &&
              <Box sx={{
                ml: 2,
                mt: -1
              }}
              >
                <AddDefaultRow
                  label={t('scheme.addCriteria')}
                  onAdd={() => handleAction(SchemeButtonActions.CREATE_CRITERIA, item)}
                  buttonProps={{
                    sx: {
                      mt: 2,
                      height: '75px'
                    }
                  }}
                />
              </Box>
            }
          >
            {item?.children?.length &&
            (
              <SchemeCriteriaList
                handleAction={handleAction}
                criteria={item.children}
                level={level + 1}
              />
            )}
          </SchemeCriteriaListItem>
        </Box>
      ))}
    </>
  )
}
