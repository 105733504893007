import { FeedbackQuestionProps } from '@features/feedbackSubmission/typings/types'
import { Box, Typography, useTheme } from '@mui/material'
import { RatingInput } from '@shared/components/inputs/RatingInput'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'
import { t } from 'i18next'
import React from 'react'

export const FeedbackQuestionRating: React.FC<FeedbackQuestionProps> = ({ question }) => {
  const { breakpoints } = useTheme()
  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        mb: 1
      }}
      >
        <Typography variant='subtitle1'
          sx={{
            [breakpoints.down('md')]: {
              fontSize: fontSizes.xs,
              lineHeight: lineHeights.xs
            }
          }}
        >
          {t('feedbackSubmission.ratingLow')}
        </Typography>

        <Typography variant='subtitle1'
          sx={{
            textAlign: 'right',
            [breakpoints.down('md')]: {
              fontSize: fontSizes.xs,
              lineHeight: lineHeights.xs
            }
          }}
        >
          {t('feedbackSubmission.ratingHigh')}
        </Typography>
      </Box>
      <RatingInput
        formKey={`feedback.${question.id}`}
        scale={5}
        stars
        options={{
          required: question.config?.required || false
        }}
        sameColor
      />
    </Box>
  )
}
