import { Alert, AlertProps, Snackbar, SnackbarProps, Typography, useTheme } from '@mui/material'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'
import React from 'react'

type Props = SnackbarProps & {
  severity: AlertProps['severity']
  message: string
  onClose: () => void
}

export const DefaultSnackbar: React.FC<Props> = ({ severity, message, sx, onClose, ...props }) => {
  const { breakpoints } = useTheme()

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        position: 'absolute',
        ...sx
      }}
      onClose={onClose}
      {...props}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        <Typography
          sx={{
            [breakpoints.down('md')]: {
              fontSize: fontSizes.sm,
              lineHeight: lineHeights.sm
            }
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  )
}
