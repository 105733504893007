import { ButtonProps, Button, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = {
  label: string
  onAdd: () => void
  buttonProps?: ButtonProps
}

export const AddDefaultRow: React.FC<Props> = ({ label, buttonProps, onAdd }) => {
  const { sx, ...rest } = buttonProps || {}

  return (
    <Button
      variant="text"
      disableRipple
      sx={{
        border: '1px dashed',
        borderColor: '#000',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        p: 1.5,
        '&.MuiButton-text': {
          color: '#000',
          '&:hover': {
            color: '#000',
            borderColor: 'grey.500'
          },
          '.MuiTypography-button': {
            textTransform: 'none'
          }
        },
        ...sx
      }}
      onClick={onAdd}
      {...rest}
    >
      <themeIcons.Add />
      <Typography sx={{
        ml: 1
      }}
      >
        {label}
      </Typography>
    </Button>
  )
}
