import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TeamMemberInput } from '@shared/components/inputs/TeamMemberInput'
import { Box } from '@mui/material'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { FormSelectInput } from '@shared/components/inputs/FormSelectInput'
import { mailPattern } from '@utils/pattern'

import { RegisterTeamInput } from '../typings/types'
import { useRegisterTeamContext } from '../provider/RegisterTeamDataProvider'

export const TeamRegisterInputs: React.FC = () => {
  const { t } = useTranslation()

  const { eventData } = useRegisterTeamContext()

  const { control, setValue } = useFormContext<RegisterTeamInput>()

  const mappedChallenges = React.useMemo(() => {
    return eventData?.challenges.map((challenge) => ({
      id: challenge.id,
      name: t('challenges.titleWithOrder', { order: challenge.order + 1, title: challenge.texts.title })
    })) ?? []
  }, [eventData])

  const { fields, append, remove } = useFieldArray({
    name: 'teamMembers',
    control
  })

  const handleAddMember = () => {
    append({
      id: Math.random().toString(36).substr(2, 9),
      firstName: '',
      lastName: ''
    })
  }

  React.useEffect(() => {
    if (mappedChallenges.length === 1) {
      setValue('challengeId', mappedChallenges[0].id)
    }
  }, [mappedChallenges])

  return (
    <>
      <TextInput
        formKey="name"
        label={t('common.teamName')}
        hideMaxLength
        inputProps={{
          maxLength: 50
        }}
        options={{
          required: true
        }}
        sx={{
          mt: 2
        }}
      />

      <TextInput
        formKey="contact"
        label={t('common.contactEmail')}
        hideMaxLength
        options={{
          required: true,
          pattern: mailPattern
        }}
      />

      {mappedChallenges.length > 1 && (
        <Box sx={{
          mb: 2
        }}
        >
          <FormSelectInput
            formKey='challengeId'
            items={mappedChallenges}
            label={t('common.challenge')}
            options={{
              required: true
            }}
          />
        </Box>
      )}

      {fields.map((item, index) => (
        <Box key={item.id}
          sx={{
            mb: 2
          }}
        >
          <TeamMemberInput
            firstNameKey={`teamMembers[${index}].firstName`}
            lastNameKey={`teamMembers[${index}].lastName`}
            onDelete={() => remove(index)}
          />
        </Box>
      ))}

      <AddDefaultRow
        label={t('action.addMember')}
        onAdd={handleAddMember}
      />

    </>
  )
}
