import { Box, Typography } from '@mui/material'
import { SubmitResultInput, useSubmitResultsMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LOGIN_BLOCKED } from '@shared/constants/auth'
import { useTimeBlocker } from '@hooks/useTimeBlocker'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'
import { EditableFile } from '@typings/files'
import { useFileUpload } from '@hooks/useFileUpload'

import { SubmissionInputs } from '../components/SubmissionInput'
import { VerifiedTeamType } from '../typings/types'
import { ConfirmSubmissionDialog } from '../components/ConfirmSubmissionDialog'

type Props = {
  currentTeam: VerifiedTeamType
  onSubmitted: () => void,
  onError: (error: any) => any;
}

type SubmitResultsInputType = Omit<SubmitResultInput, 'texts'> & {
  texts: Record<string, string>
  attachments: Record<string, EditableFile<any> | null>
  verifyTeamName: string
}

const SubmitViewContent: React.FC<Props> = ({ currentTeam, onSubmitted, onError }) => {
  const { t } = useTranslation()

  const [submitResults] = useSubmitResultsMutation()

  const { uploadAndDeleteFiles } = useFileUpload()

  const { handleSubmit, formState: { isDirty, isValid } } = useFormContext<SubmitResultsInputType>()

  const { blocked, remainingTime, startBlocker } = useTimeBlocker(LOGIN_BLOCKED)

  const [loading, setLoading] = React.useState(false)
  const [showDialog, setShowDialog] = React.useState(false)

  const submit = handleSubmit(async (input) => {
    setLoading(true)

    try {
      const res = await submitResults({
        variables: {
          data: {
            repository: input.repository,
            texts: Object.keys(input.texts).map((key) => ({
              key,
              value: input.texts[key]
            })),
            website: input.website
          }
        }
      })

      if (input.attachments) {
        await uploadAndDeleteFiles(
          input.attachments,
          'TeamResult',
          res?.data?.submitResults.id as string
        )
      }

      onSubmitted()
    } catch (error: any) {
      onError(error)
      startBlocker()
    } finally {
      setLoading(false)
    }
  })

  const handleOnClose = async () => {
    setShowDialog(false)
  }

  const handleOnConfirm = async () => {
    setShowDialog(false)
    submit()
  }

  const onHandleAction = () => {
    setShowDialog(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 2
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <Typography
          variant="h3"
          mb={2}
        >{currentTeam.name}</Typography>
        <Box
          sx={{
            width: '100%'
          }}
        >
          <form>
            <SubmissionInputs loading={loading} />

            <Box sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
            >
              <ElevatedButton
                onClick={onHandleAction}
                loading={loading}
                disabled={!isDirty || !isValid || blocked }
                sx={{
                  mt: 1
                }}
              >
                {t('action.send')}
              </ElevatedButton>

              {blocked &&
              <Typography
                variant='subtitle1'
                sx={{
                  ml: 2,
                  color: 'error.main'
                }}
              >
                {t('auth.submissionBlockedFor', { count: remainingTime })}
              </Typography>
        }
            </Box>

          </form>
        </Box>

      </Box>
      <ConfirmSubmissionDialog open={showDialog}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        currentTeam={currentTeam.name}
      >
      </ConfirmSubmissionDialog>
    </Box>
  )
}

export const SubmitView: React.FC<Props> = (props) => {
  const formData = useForm<SubmitResultsInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <SubmitViewContent {...props} />
    </FormProvider>
  )
}
