import { Box, Typography } from '@mui/material'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const EventNotActiveCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <DashboardCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: 'error.main'
        }}
      >
        <themeIcons.Info sx={{ mr: 2 }}/>
        <Typography>
          {t('eventDetails.eventNotActive')}
        </Typography>
      </Box>
    </DashboardCard>
  )
}
