import {
  UpdateEventMutation,
  useEventQuery, useTeamsQuery, useUpdateEventMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from '@apollo/client'

import { EventModel, TeamModel } from '../typings/types'

export type EventDetailsDataProviderContextType = {
  eventData: EventModel | null
  teamsData: TeamModel[]
  loading: boolean
  handleToggleSubmission: () => Promise<FetchResult<UpdateEventMutation>>
  handleToggleRegistration: () => Promise<FetchResult<UpdateEventMutation>>
  handleToggleRatings: () => Promise<FetchResult<UpdateEventMutation>>
}

const EventDetailsDataProviderContext = React.createContext<EventDetailsDataProviderContextType>(
  {} as any
)

export const EventDetailsDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const { data, loading: dataLoading } = useEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !id
  })

  const { data: eventTeamsData, loading: eventTeamsLoading } = useTeamsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string
    },
    skip: !id
  })

  const [updateEvent] = useUpdateEventMutation({
    update: (cache, { data: updateData }) => {
      if (updateData?.updateEvent) {
        cache.modify({
          id: cache.identify({
            __typename: 'Event',
            id: updateData.updateEvent.id as string
          }),
          fields: {
            submission: () => updateData.updateEvent.submission,
            registration: () => updateData.updateEvent.registration,
            rateable: () => updateData.updateEvent.rateable
          }
        })
      }
    }
  })

  const eventData = React.useMemo<EventModel | null>(() => {
    return data?.event || null
  }, [data])

  const teamsData = React.useMemo<TeamModel[]>(() => {
    return eventTeamsData?.teams || []
  }, [eventTeamsData])

  const loading = useArtificialLoading(0, [dataLoading, eventTeamsLoading])

  const handleToggleSubmission = React.useCallback(async () => {
    return await updateEvent({
      variables: {
        id: id as string,
        data: {
          submission: !eventData?.submission
        }
      }
    })
  }, [eventData])

  const handleToggleRegistration = React.useCallback(async () => {
    return await updateEvent({
      variables: {
        id: id as string,
        data: {
          registration: !eventData?.registration
        }
      }
    })
  }, [eventData])

  const handleToggleRatings = React.useCallback(async () => {
    return await updateEvent({
      variables: {
        id: id as string,
        data: {
          rateable: !eventData?.rateable
        }
      }
    })
  }, [eventData])

  const value = React.useMemo<EventDetailsDataProviderContextType>(() => {
    return {
      eventData,
      teamsData,
      loading,
      handleToggleSubmission,
      handleToggleRegistration,
      handleToggleRatings
    }
  }, [eventData, teamsData, loading])

  return (
    <>
      <EventDetailsDataProviderContext.Provider value={value}>
        {children}
      </EventDetailsDataProviderContext.Provider>
    </>
  )
}

export const useEventDetailsContext = () => React.useContext(EventDetailsDataProviderContext)
