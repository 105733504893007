import React from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorLayout } from '../layout/ErrorLayout'

export const Error403: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorLayout text={t('error.403')} />
  )
}
