import { Box, Typography } from '@mui/material'
import React from 'react'
import { CenterCircularProgress } from '@shared/components/ui/CenterCircularProgress'
import { useTranslation } from 'react-i18next'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useActiveEventForJuryQuery } from '@typings/graphql'
import { useNavigate } from 'react-router'

import { JuryTeamList } from '../components/JuryTeamList'
import { ActiveEventNode, TeamNode } from '../typings/types'

export const JuryView: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data, loading: dataLoading } = useActiveEventForJuryQuery({
    fetchPolicy: 'cache-and-network'
  })

  const eventsData = React.useMemo<ActiveEventNode | null>(() => {
    return data?.activeEvent || null
  }, [data])

  const loading = useArtificialLoading(500, [dataLoading])

  const handleTeamSelected = (team: TeamNode) => {
    navigate(`/jury/${team.id}`)
  }

  return (
    <Box
      sx={{
        height: '100%',
        px: 2,
        py: 3
      }}
    >
      {loading
        ? (
          <CenterCircularProgress />
          )
        : (
          <Box sx={{ height: '100%' }}>
            { !eventsData
              ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: 10
                }}
                >
                  <Typography variant='h4'
                    sx={{
                      fontWeight: 'bold'
                    }}
                  >
                    {t('jury.noActiveEvent')}
                  </Typography>
                </Box>
                )
              : (
                <Box>
                  <Typography sx={{
                    mb: 1.5
                  }}
                    variant='h3'
                  >
                    {t('jury.rating')}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {t('jury.ratingDescription')}
                  </Typography>
                  <Box sx={{
                    mt: 2
                  }}
                  >
                    <JuryTeamList
                      teams={eventsData.teams}
                      onSelected={handleTeamSelected}
                    />
                  </Box>
                </Box>
                )}
          </Box>
          )}
    </Box>
  )
}
