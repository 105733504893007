import React from 'react'
import { Box } from '@mui/material'

import { useFeedbackEvaluationContext } from '../provider/FeedbackEvaluationDataProvider'

import { FeedbackEvaluationQuestionListItem } from './FeedbackEvaluationQuestionListItem'

export const FeedbackEvaluationQuestionList: React.FC = () => {
  const { feedbackData } = useFeedbackEvaluationContext()

  return (
    <Box>
      {feedbackData?.map((feedback) => (
        <Box
          key={feedback.id}
          sx={{
            mb: 2
          }}
        >
          <FeedbackEvaluationQuestionListItem
            feedback={feedback}
          />
        </Box>
      ))}
    </Box>
  )
}
