import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { IconButton, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/icons'

import { UserButtonActions, UserColumnType, UsersRowModel } from '../typings/types'
import { useUsersContext } from '../provider/UsersDataProvider'

import { userDataGridColumns } from './userDataGridColumns'

type Props = {
  handleAction: (action: UserButtonActions, rowModel: UsersRowModel) => void
}

export const UsersDataGrid: React.FC<Props> = ({ handleAction }) => {
  const { t } = useTranslation()

  const { userData, loading } = useUsersContext()

  const columnData = React.useMemo<UserColumnType[]>(() => {
    return [...userDataGridColumns, {
      ...actionColumnModel,
      renderCell: (params: any) => (
        <ActionColumnRenderCell
          {...(can('users:create') && {
            additionalActions: (
              <Tooltip title={t(`tooltips.${params.row.active ? 'sendInvitationAgain' : 'sendInvitation'}`)}>
                <IconButton onClick={() => handleAction(UserButtonActions.INVITE, params.row)}>
                  {params.row.active ? <themeIcons.Drafts sx={{ width: 20, height: 20 }} /> : <themeIcons.Mail sx={{ width: 20, height: 20 }} />}
                </IconButton>
              </Tooltip>
            )
          })}
          {...(can('users:update') && { onEdit: () => handleAction(UserButtonActions.EDIT, params.row) })}
          {...(can('users:delete') && { onDelete: () => handleAction(UserButtonActions.DELETE, params.row) })}
        />
      )
    } as UserColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`users.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={userData}
      autoPageSize={false}
      loading={loading}
      rowSelection={false}
      checkboxSelection={false}
    />
  )
}
