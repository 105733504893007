import { Roles } from '@typings/roles'

import { TeamColumnType } from '../typings/types'

export const FORBIDDEN_COLUMNS: { [key in Roles]?: TeamColumnType['field'][] } = {
  [Roles.ADMIN]: [],
  [Roles.CODE_REVIEWER]: ['verificationCode'],
  [Roles.MANAGER]: ['verificationCode']
}

export const DEFAULT_MEMBERS_COUNT = 4
