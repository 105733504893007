import { Box, Link, SxProps, Typography } from '@mui/material'
import React from 'react'

type Props = {
  title: string
  link: string
  sx?: SxProps
}

export const TeamDetailPanelLink: React.FC<Props> = ({ title, link, sx }) => {
  const handleLinkClick = React.useCallback(() => {
    window.open(link, '_blank')
  }, [link])

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      ...sx
    }}
    >
      <Typography fontWeight={600}>
        {title}
      </Typography>
      <Link
        sx={{
          ml: 2,
          cursor: 'pointer',
          color: '#000000',
          textDecoration: 'none',
          '&:hover': {
            color: '#000000',
            textDecoration: 'underline'
          }
        }}
        variant='body1'
        onClick={handleLinkClick}
      >
        {link}
      </Link>
    </Box>
  )
}
