import React from 'react'
import { useTranslation } from 'react-i18next'
import { modalPreviewContent } from '@shared/components/files/preview/modalPreviewContent'
import { BaseModal } from '@shared/components/ui/BaseModal'

import { FileType } from '../../../features/teams/typings/types'

type Props = {
  open: boolean
  onClose: () => void
  file?: FileType
}

export const TeamAttachmentModal:React.FC<Props> = ({ file, onClose, open }) => {
  const { t } = useTranslation()

  const PreviewContent = React.useMemo(() => {
    if (!file) {
      return null
    }

    if (file.mimeType.startsWith('image')) {
      return modalPreviewContent.image
    } else if (file.mimeType.startsWith('video')) {
      return modalPreviewContent.video
    } else if (file.mimeType.startsWith('application/pdf')) {
      return modalPreviewContent.pdf
    }

    return null
  }, [file])

  return (
    <BaseModal
      title={t('files.previewFile', { fileName: file?.fileName })}
      onClose={onClose}
      open={open}
    >
      {PreviewContent && file && <PreviewContent src={file?.url} type={file?.mimeType} />}
    </BaseModal>
  )
}
