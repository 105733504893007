import {
  useActiveEventQuery
} from '@typings/graphql'
import React from 'react'

import { EventModel } from '../typings/types'

export type HomeDataProviderContextType = {
  eventData: EventModel | null
}

const HomeDataProviderContext = React.createContext<HomeDataProviderContextType>(
  {} as any
)

export const HomeDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data } = useActiveEventQuery({
    fetchPolicy: 'cache-and-network'
  })

  const eventData = React.useMemo<EventModel | null>(() => {
    return data?.activeEvent?.active ? data.activeEvent : null
  }, [data])

  const value = React.useMemo<HomeDataProviderContextType>(() => {
    return {
      eventData
    }
  }, [eventData])

  return (
    <>
      <HomeDataProviderContext.Provider value={value}>
        {children}
      </HomeDataProviderContext.Provider>
    </>
  )
}

export const useHomeContext = () => React.useContext(HomeDataProviderContext)
