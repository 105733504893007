import { useArtificialLoading } from '@hooks/useArtificialLoading'
import {
  DeleteFeedbackAnswersMutation,
  useDeleteFeedbackAnswersMutation,
  useFeedbackQuestionsForEvaluationQuery
} from '@typings/graphql'
import React from 'react'
import { useParams } from 'react-router'
import { FetchResult } from '@apollo/client'

import { FeedbackQuestionsModel } from '../typings/types'

export type FeedbackEvaluationDataProviderContextType = {
  feedbackData: FeedbackQuestionsModel[]
  loading: boolean,
  handleDeleteFeedbacks: () => Promise<FetchResult<DeleteFeedbackAnswersMutation>>
}

const FeedbackEvaluationDataProviderContext = React.createContext<FeedbackEvaluationDataProviderContextType>(
  {} as any
)

export const FeedbackEvaluationDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const { data, loading } = useFeedbackQuestionsForEvaluationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string
    }
  })

  const [deleteFeedbacks] = useDeleteFeedbackAnswersMutation({
    update (cache) {
      data?.feedbackQuestions.forEach((question) => {
        cache.modify({
          id: cache.identify({
            __typename: 'FeedbackQuestion',
            id: question.id
          }),
          fields: {
            feedbackAnswers () {
              return []
            }
          }
        })
      })
    }
  })

  const artificialLoading = useArtificialLoading(500, [loading])

  const feedbackData = React.useMemo<FeedbackQuestionsModel[]>(() => {
    return data?.feedbackQuestions || []
  }, [data])

  const handleDeleteFeedbacks = React.useCallback(async () => {
    return await deleteFeedbacks({
      variables: {
        eventId: id as string
      }
    })
  }, [id])

  const value = React.useMemo<FeedbackEvaluationDataProviderContextType>(() => {
    return {
      feedbackData,
      loading: artificialLoading,
      handleDeleteFeedbacks
    }
  }, [feedbackData, artificialLoading])

  return (
    <>
      <FeedbackEvaluationDataProviderContext.Provider value={value}>
        {children}
      </FeedbackEvaluationDataProviderContext.Provider>
    </>
  )
}

export const useFeedbackEvaluationContext = () => React.useContext(FeedbackEvaluationDataProviderContext)
