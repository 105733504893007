import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { FeedbackQuestionType } from '@typings/graphql'
import { FormSelectInput } from '@shared/components/inputs/FormSelectInput'
import { Box, Typography } from '@mui/material'

import { STATIC_QUESTION_TEXTS } from '../constants/feedbackQuestions'

import { feedbackQuestionTypeExtensions } from './types/feedbackQuestionTypeExtensions'

type Props = {
  disableType?: boolean
}

export const FeedbackQuestionCreateEditInputs: React.FC<Props> = ({ disableType }) => {
  const { t } = useTranslation()

  const { watch } = useFormContext()

  const TypeExtension = React.useMemo(() => {
    const watchedType = watch('type') as FeedbackQuestionType

    return watchedType ? feedbackQuestionTypeExtensions[watchedType] : null
  }, [watch('type')])

  const mappedTypes = React.useMemo(() => {
    return Object.entries(FeedbackQuestionType).map(([key, value]) => ({
      id: value,
      name: t(`feedbackQuestions.types.${key.toLowerCase()}`)
    }))
  }, [])

  return (
    <>
      <Typography sx={{
        fontWeight: 'bold',
        mb: 2
      }}
      >
        {t('common.general')}
      </Typography>
      {STATIC_QUESTION_TEXTS.map((item) => (
        <TextInput
          key={item}
          formKey={`texts.${item}`}
          label={t(`common.${item}`)}
          options={{
            required: true
          }}
        />
      ))}

      <FormSelectInput
        formKey='type'
        disabled={disableType}
        items={mappedTypes}
        label={t('feedbackQuestions.type')}
        options={{
          required: true
        }}
      />

      {TypeExtension && (
        <Box sx={{ mt: 2 }}>
          <TypeExtension />
        </Box>
      )}
    </>
  )
}
