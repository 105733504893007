import React from 'react'
import { Error404 } from '@features/error/views/Error404'
import { Error403 } from '@features/error/views/Error403'

import { AppRouteObject } from '../router'

export const errorRoutes: AppRouteObject[] = [
  // 404
  {
    path: '*',
    element: <Error404 />,
    children: [{
      index: true
    }]
  },

  // 403
  {
    path: '/403',
    element: <Error403 />,
    children: [{
      index: true
    }]
  }
]
