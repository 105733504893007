import { SvgIcon, SvgIconProps } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import React from 'react'

type ColorVariant = 'white' | 'black' | 'blue'

type Props = SvgIconProps & {
  colorVariant?: ColorVariant
  minimal?: boolean
}

export const Logo:React.FC<Props> = ({ colorVariant = 'blue', minimal = false, sx, ...props }) => {
  const colors = React.useMemo(() => {
    switch (colorVariant) {
      case 'black':
        return {
          fill: '#000000',
          color: '#000000',
          text: '#000000'
        }
      case 'white':
        return {
          fill: '#FFFFFF',
          color: '#FFFFFF',
          text: '#FFFFFF'
        }
      default:
        return {
          fill: palette.primary.main,
          color: '#000000',
          text: '#000000'
        }
    }
  }, [colorVariant])

  return (
    <SvgIcon
      viewBox={`0 0 ${minimal ? '52' : '209'} 52`}
      width={minimal ? '52px' : '209'}
      height="52"
      fill="none"
      sx={{
        width: minimal ? '52px' : '209px',
        height: '52px',
        ...sx
      }}
      {...props}
    >
      {!minimal && (
        <g>
          <path d="M83.6305 22.1909V3.35522H87.8621V22.1909H83.6305ZM71.4518 22.1909V3.35522H75.6833V22.1909H71.4518ZM74.935 14.5792V10.8637H84.3788V14.5792H74.935Z" fill={colors.text}/>
          <path d="M99.8814 22.1909V18.0109H99.1841V13.4697C99.1841 12.7473 99.0119 12.2054 98.6686 11.8442C98.3241 11.483 97.7738 11.3023 97.0168 11.3023C96.6384 11.3023 96.1396 11.311 95.5203 11.3282C94.901 11.3454 94.2646 11.3712 93.611 11.4056C92.9573 11.4399 92.3639 11.4744 91.8306 11.5088V8.02545C92.2263 7.99105 92.6907 7.95665 93.2239 7.92224C93.7572 7.88784 94.3076 7.86205 94.8753 7.84485C95.443 7.82765 95.9762 7.81905 96.475 7.81905C97.9199 7.81905 99.1325 8.02545 100.113 8.43829C101.111 8.85114 101.867 9.47899 102.384 10.3219C102.9 11.1476 103.158 12.2141 103.158 13.5214V22.191L99.8814 22.1909ZM95.3655 22.5522C94.3506 22.5522 93.4561 22.3715 92.6821 22.0103C91.9252 21.6491 91.3317 21.133 90.9017 20.4621C90.4717 19.7742 90.2567 18.957 90.2567 18.0109C90.2567 16.9789 90.5232 16.136 91.0565 15.4823C91.5897 14.8114 92.338 14.3212 93.3013 14.0116C94.2646 13.6847 95.3741 13.5214 96.6299 13.5214H99.6489V15.8177H96.604C95.8816 15.8177 95.3224 15.9983 94.9268 16.3596C94.5484 16.7036 94.3593 17.1767 94.3593 17.7788C94.3593 18.3464 94.5484 18.8108 94.9268 19.172C95.3224 19.5161 95.8816 19.6881 96.604 19.6881C97.0685 19.6881 97.4813 19.6107 97.8425 19.4558C98.2209 19.2839 98.5305 19 98.7717 18.6044C99.0119 18.2087 99.15 17.6583 99.1841 16.9531L100.164 17.9852C100.079 18.9657 99.8376 19.7913 99.4427 20.4621C99.0634 21.133 98.5305 21.6491 97.8425 22.0103C97.1717 22.3715 96.346 22.5522 95.3655 22.5522Z" fill={colors.text}/>
          <path d="M112.491 22.6812C111.271 22.6812 110.204 22.4834 109.293 22.0878C108.398 21.6749 107.649 21.1245 107.047 20.4364C106.445 19.7483 105.999 18.9742 105.706 18.1142C105.413 17.2369 105.267 16.3338 105.267 15.4049V14.9146C105.267 13.9514 105.422 13.0311 105.731 12.1539C106.041 11.2593 106.497 10.4681 107.099 9.77998C107.702 9.09195 108.449 8.5501 109.344 8.15446C110.256 7.75882 111.296 7.561 112.466 7.561C113.739 7.561 114.865 7.81043 115.846 8.30928C116.827 8.79092 117.609 9.47038 118.194 10.3476C118.779 11.225 119.106 12.2313 119.175 13.3665H115.124C115.055 12.7301 114.796 12.1968 114.35 11.7668C113.919 11.3195 113.292 11.0959 112.466 11.0959C111.778 11.0959 111.202 11.268 110.737 11.612C110.29 11.956 109.955 12.4291 109.731 13.0311C109.508 13.6332 109.396 14.347 109.396 15.1727C109.396 15.9296 109.499 16.6177 109.705 17.2369C109.911 17.839 110.238 18.312 110.685 18.656C111.15 18.9829 111.752 19.1463 112.491 19.1463C113.042 19.1463 113.507 19.0517 113.885 18.8624C114.281 18.656 114.59 18.3808 114.814 18.0368C115.055 17.6755 115.201 17.2627 115.252 16.7983H119.278C119.226 17.9679 118.9 19 118.298 19.8946C117.696 20.7718 116.895 21.4599 115.897 21.9587C114.9 22.4404 113.764 22.6812 112.491 22.6812Z" fill={colors.text}/>
          <path d="M131.362 22.1909L126.898 15.5855H124.911L130.665 8.05125H135L129.581 15.0695L129.633 13.0569L136.007 22.1909H131.362ZM121.402 22.1909V3.35522H125.53V22.1909H121.402Z" fill={colors.text}/>
          <path d="M145.654 22.1909V18.0109H144.957V13.4697C144.957 12.7473 144.785 12.2054 144.442 11.8442C144.097 11.483 143.547 11.3023 142.79 11.3023C142.412 11.3023 141.913 11.311 141.293 11.3282C140.674 11.3454 140.038 11.3712 139.384 11.4056C138.73 11.4399 138.137 11.4744 137.604 11.5088V8.02545C137.999 7.99105 138.463 7.95665 138.997 7.92224C139.53 7.88784 140.081 7.86205 140.649 7.84485C141.216 7.82765 141.75 7.81905 142.247 7.81905C143.693 7.81905 144.905 8.02545 145.886 8.43829C146.884 8.85114 147.64 9.47899 148.157 10.3219C148.673 11.1476 148.931 12.2141 148.931 13.5214V22.191L145.654 22.1909ZM141.139 22.5522C140.123 22.5522 139.229 22.3715 138.455 22.0103C137.698 21.6491 137.105 21.133 136.675 20.4621C136.245 19.7742 136.03 18.957 136.03 18.0109C136.03 16.9789 136.296 16.136 136.829 15.4823C137.362 14.8114 138.111 14.3212 139.074 14.0116C140.038 13.6847 141.148 13.5214 142.403 13.5214H145.422V15.8177H142.377C141.654 15.8177 141.095 15.9983 140.7 16.3596C140.322 16.7036 140.132 17.1767 140.132 17.7788C140.132 18.3464 140.322 18.8108 140.7 19.172C141.095 19.5161 141.654 19.6881 142.377 19.6881C142.842 19.6881 143.254 19.6107 143.616 19.4558C143.994 19.2839 144.303 19 144.545 18.6044C144.785 18.2087 144.923 17.6583 144.957 16.9531L145.937 17.9852C145.852 18.9657 145.611 19.7913 145.216 20.4621C144.836 21.133 144.303 21.6491 143.616 22.0103C142.945 22.3715 142.119 22.5522 141.139 22.5522Z" fill={colors.text}/>
          <path d="M158.342 22.3716C156.914 22.3716 155.762 22.1995 154.885 21.8555C154.008 21.4943 153.363 20.9009 152.949 20.0752C152.554 19.2323 152.356 18.097 152.356 16.6692V4.2325H156.201V16.824C156.201 17.4949 156.373 18.0109 156.717 18.3722C157.078 18.7162 157.586 18.8883 158.239 18.8883H160.329V22.3716H158.342ZM150.215 11.0702V8.05125H160.329V11.0702H150.215Z" fill={colors.text}/>
          <path d="M162.589 22.1909V3.35522H166.718V14.218H166.098C166.098 12.7903 166.279 11.5862 166.64 10.6057C167.002 9.62521 167.535 8.88553 168.24 8.38669C168.945 7.87065 169.831 7.61261 170.898 7.61261H171.078C172.643 7.61261 173.839 8.17167 174.664 9.28978C175.49 10.3906 175.903 12.0076 175.903 14.1406V22.1909H171.775V13.8826C171.775 13.1085 171.543 12.4893 171.078 12.0248C170.631 11.5432 170.037 11.3023 169.298 11.3023C168.524 11.3023 167.896 11.5518 167.414 12.0506C166.949 12.5495 166.718 13.2117 166.718 14.0374V22.1909H162.589Z" fill={colors.text}/>
          <path d="M185.794 22.6812C184.556 22.6812 183.455 22.4919 182.492 22.1135C181.529 21.7179 180.711 21.1847 180.041 20.5138C179.387 19.8429 178.888 19.0774 178.544 18.2174C178.2 17.3401 178.028 16.4199 178.028 15.4565V14.8631C178.028 13.8826 178.2 12.9537 178.544 12.0764C178.905 11.1819 179.421 10.3993 180.092 9.72842C180.78 9.05754 181.606 8.53289 182.569 8.15446C183.532 7.75882 184.608 7.561 185.794 7.561C187.016 7.561 188.099 7.75882 189.046 8.15446C190.008 8.53289 190.825 9.05754 191.496 9.72842C192.167 10.3993 192.684 11.1819 193.045 12.0764C193.406 12.9537 193.586 13.8826 193.586 14.8631V15.4565C193.586 16.4199 193.414 17.3401 193.071 18.2174C192.727 19.0774 192.219 19.8429 191.548 20.5138C190.894 21.1847 190.086 21.7179 189.122 22.1135C188.16 22.4919 187.05 22.6812 185.794 22.6812ZM185.794 19.1978C186.602 19.1978 187.274 19.0259 187.806 18.6818C188.34 18.3378 188.744 17.8561 189.019 17.2369C189.312 16.6177 189.458 15.9296 189.458 15.1727C189.458 14.3643 189.312 13.659 189.019 13.0569C188.727 12.4376 188.305 11.9474 187.755 11.5862C187.222 11.225 186.568 11.0443 185.794 11.0443C185.038 11.0443 184.384 11.225 183.834 11.5862C183.283 11.9474 182.861 12.4376 182.569 13.0569C182.294 13.659 182.156 14.3643 182.156 15.1727C182.156 15.9296 182.294 16.6177 182.569 17.2369C182.845 17.8561 183.257 18.3378 183.807 18.6818C184.358 19.0259 185.02 19.1978 185.794 19.1978Z" fill={colors.text}/>
          <path d="M195.952 22.1909V8.05125H199.229V14.1148H198.997C198.997 12.6699 199.177 11.4658 199.538 10.5025C199.917 9.5392 200.485 8.81672 201.241 8.33508C201.998 7.85344 202.918 7.61261 204.002 7.61261H204.182C205.834 7.61261 207.09 8.15447 207.95 9.23817C208.827 10.3047 209.266 11.9302 209.266 14.1148V22.1909H205.137V13.8826C205.137 13.1257 204.914 12.5064 204.466 12.0248C204.019 11.5432 203.417 11.3023 202.66 11.3023C201.886 11.3023 201.259 11.5518 200.776 12.0506C200.312 12.5323 200.08 13.1687 200.08 13.96V22.1909L195.952 22.1909Z" fill={colors.text}/>
          <path d="M71.4518 48.6285V29.7928H73.9804V48.6285H71.4518ZM73.4644 40.2427V37.9721L81.5405 37.9464V40.217L73.4644 40.2427ZM73.4644 32.0634V29.7928H81.9792V32.0634H73.4644Z" fill={colors.text}/>
          <path d="M89.5846 49.0413C87.9505 49.0413 86.6947 48.508 85.8174 47.4415C84.9402 46.375 84.5015 44.8355 84.5015 42.823V34.8242H86.9786V43.3132C86.9786 44.3969 87.271 45.2483 87.8558 45.8677C88.4407 46.4697 89.232 46.7708 90.2296 46.7708C91.2618 46.7708 92.1046 46.4439 92.7583 45.7903C93.412 45.1194 93.7388 44.2077 93.7388 43.0552V34.8242H96.2159V48.6285H94.2548V42.7197H94.5645C94.5645 44.0787 94.3839 45.2311 94.0227 46.1772C93.6614 47.1061 93.1196 47.8201 92.3971 48.3189C91.6919 48.8005 90.7888 49.0413 89.6878 49.0413L89.5846 49.0413Z" fill={colors.text}/>
          <path d="M100.652 48.6285V29.7928H103.129V48.6285H100.652ZM98.7428 31.7538V29.7928H103.129V31.7538H98.7428Z" fill={colors.text}/>
          <path d="M113.118 49.0929C112.138 49.0929 111.234 48.9123 110.409 48.5511C109.583 48.1898 108.86 47.6824 108.242 47.0288C107.64 46.375 107.166 45.6182 106.822 44.7582C106.495 43.8981 106.332 42.9692 106.332 41.9714V41.507C106.332 40.5265 106.495 39.6063 106.822 38.7462C107.149 37.8862 107.605 37.1292 108.19 36.4756C108.792 35.8219 109.497 35.3144 110.305 34.9532C111.131 34.5748 112.042 34.3856 113.04 34.3856C114.09 34.3856 115.045 34.6092 115.904 35.0564C116.765 35.4865 117.47 36.1659 118.021 37.0948C118.571 38.0237 118.88 39.2278 118.949 40.7071L117.918 39.0558V29.7928H120.395V48.6285H118.433V42.5133H119.156C119.087 44.0787 118.76 45.3516 118.175 46.332C117.591 47.2954 116.851 48.0007 115.956 48.4478C115.079 48.8779 114.132 49.0929 113.118 49.0929ZM113.454 46.9255C114.296 46.9255 115.062 46.7363 115.75 46.3579C116.438 45.9794 116.989 45.4376 117.401 44.7323C117.814 44.0099 118.021 43.167 118.021 42.2037V41.0168C118.021 40.0707 117.806 39.2709 117.375 38.6172C116.963 37.9463 116.403 37.4389 115.698 37.0948C115.01 36.7336 114.253 36.553 113.427 36.553C112.516 36.553 111.708 36.768 111.002 37.1981C110.314 37.628 109.772 38.2301 109.377 39.0042C108.998 39.7782 108.809 40.6899 108.809 41.7393C108.809 42.7886 109.007 43.7088 109.402 44.5C109.798 45.2742 110.348 45.8762 111.053 46.3063C111.759 46.7191 112.559 46.9255 113.454 46.9255Z" fill={colors.text}/>
          <path d="M132.972 48.6285V44.5001H132.559V39.7267C132.559 38.7806 132.309 38.0668 131.81 37.5851C131.329 37.1034 130.615 36.8626 129.669 36.8626C129.136 36.8626 128.585 36.8712 128.017 36.8884C127.449 36.9056 126.908 36.9229 126.392 36.9401C125.876 36.9572 125.429 36.983 125.05 37.0174V34.8501C125.429 34.8156 125.824 34.7899 126.238 34.7726C126.668 34.7382 127.106 34.721 127.554 34.721C128 34.7038 128.431 34.6952 128.843 34.6952C130.253 34.6952 131.407 34.8673 132.301 35.2113C133.195 35.5381 133.858 36.08 134.287 36.8368C134.717 37.5937 134.932 38.6171 134.932 39.9073V48.6285L132.972 48.6285ZM128.456 48.9897C127.424 48.9897 126.529 48.8177 125.773 48.4737C125.033 48.1124 124.457 47.5964 124.044 46.9255C123.649 46.2546 123.45 45.4462 123.45 44.5001C123.45 43.5196 123.665 42.7025 124.095 42.0488C124.543 41.3952 125.188 40.9049 126.031 40.5782C126.874 40.2341 127.888 40.0621 129.076 40.0621H132.817V41.9199H128.973C127.975 41.9199 127.209 42.1607 126.676 42.6423C126.142 43.124 125.876 43.7432 125.876 44.5C125.876 45.257 126.142 45.8676 126.676 46.332C127.209 46.7965 127.975 47.0288 128.973 47.0288C129.557 47.0288 130.116 46.9255 130.649 46.7191C131.183 46.4955 131.622 46.1342 131.965 45.6354C132.327 45.1194 132.524 44.4141 132.559 43.5196L133.229 44.5C133.144 45.4806 132.903 46.3063 132.507 46.9771C132.128 47.6308 131.595 48.1296 130.908 48.4737C130.237 48.8177 129.42 48.9897 128.456 48.9897Z" fill={colors.text}/>
        </g>
      )}
      {colorVariant === 'blue'
        ? <path d="M52.4864 0H0.486389V52H52.4864V0Z" fill={colors.fill} />
        : <path d="M49.7 2.59935V49.9993H2.3V2.59935H49.7ZM52 0.299347H0V52.2993H52V0.299347Z" fill={colors.fill} /> }

      <path fillRule="evenodd" clipRule="evenodd" d="M33.7523 29.794L24.3852 29.8299L42.7112 11.5037V32.1378L33.7523 41.0966V29.794Z" fill={colors.color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.2477 22.7728L27.6135 22.7704L9.28885 41.0966V20.4625L18.2477 11.5021V22.7728Z" fill={colors.color}/>

    </SvgIcon>
  )
}
