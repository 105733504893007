import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useEventsContext } from '../provider/EventsDataProvider'
import { EventHandleActionProps } from '../typings/types'

import { EventListItem } from './EventListItem'

export const EventsList: React.FC<EventHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  const { eventsData } = useEventsContext()

  const activeEvent = React.useMemo(() => {
    return eventsData.filter((event) => event.active)?.[0] || null
  }, [eventsData])

  const inactiveEvents = React.useMemo(() => {
    return eventsData.filter((event) => !event.active)
  }, [eventsData])

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      {!eventsData?.length && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
        >
          <Typography
            variant="h4"
          >
            {t('events.noEvents')}
          </Typography>
        </Box>
      )}
      {!!activeEvent && (
        <Box sx={{
          mb: 4
        }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
              mb: 2
            }}
          >{t('events.activeEvent')}</Typography>

          <EventListItem event={activeEvent} handleAction={handleAction} />
        </Box>
      )}

      {!!inactiveEvents.length && (
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
              mb: 2
            }}
          >
            {t('events.moreEvents')}
          </Typography>
          {inactiveEvents.map((event) => (
            <Box
              key={event.id}
              sx={{
                mb: 2
              }}
            >
              <EventListItem event={event} handleAction={handleAction}/>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
