import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultAccordion } from '@shared/components/ui/DefaultAccordion'
import { Box, Typography } from '@mui/material'

import { FeedbackEvaluationTypeProps, FeedbackQuestionsModel } from '../typings/types'

import { feedbackEvaluationQuestionTypes } from './types/feedbackEvaluationQuestionTypes'

type Props = {
  feedback: FeedbackQuestionsModel
}

export const FeedbackEvaluationQuestionListItem: React.FC<Props> = ({ feedback }) => {
  const { t } = useTranslation()

  const QuestionContent = React.useMemo<React.FC<FeedbackEvaluationTypeProps>>(() => {
    return feedbackEvaluationQuestionTypes[feedback.type]
  }, [feedback])

  return (
    <DefaultAccordion
      title={feedback.texts.title}
      headerExtension={(
        <Typography>{t('common.feedbacks', { count: feedback.feedbackAnswers?.length || 0 })}</Typography>
      )}
    >
      <Typography fontWeight="bold">{feedback.texts.question}</Typography>

      <Box sx={{
        mt: 2
      }}
      >
        <QuestionContent data={feedback} />
      </Box>
    </DefaultAccordion>
  )
}
