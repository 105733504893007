import {
  CreateEventInput,
  CreateEventMutation,
  EventDataFragmentDoc,
  SoftDeleteEventMutation,
  UpdateActiveEventMutation,
  UpdateEventInput,
  UpdateEventMutation,
  useCreateEventMutation,
  useEventsQuery,
  useSoftDeleteEventMutation,
  useUpdateActiveEventMutation,
  useUpdateEventMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { FetchResult } from 'apollo-link'
import { mapTextsForMutation } from '@utils/translations'

import { EventsRowModel } from '../typings/types'

export type EventsDataProviderContextType = {
  eventsData: EventsRowModel[]
  loading: boolean
  handleDeleteEvent: (id: string) => Promise<FetchResult<SoftDeleteEventMutation>>
  handleCreateEvent: (input: CreateEventInput) => Promise<FetchResult<CreateEventMutation>>
  handleUpdateEvent: (id: string, input: UpdateEventInput) => Promise<FetchResult<UpdateEventMutation>>
  handelUpdateActiveEvent: (id: string, active: boolean) => Promise<FetchResult<UpdateActiveEventMutation>>
}

const EventsDataProviderContext = React.createContext<EventsDataProviderContextType>(
  {} as any
)

export const EventsDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, loading: dataLoading } = useEventsQuery({
    fetchPolicy: 'cache-and-network'
  })

  const eventsData = React.useMemo<EventsRowModel[]>(() => {
    return data?.events || []
  }, [data])

  const loading = useArtificialLoading(500, [dataLoading])

  const [softDeleteMutation] = useSoftDeleteEventMutation({
    update (cache, { data: updatedData }) {
      const deletedEvent = updatedData?.softDeleteEvent

      if (deletedEvent) {
        cache.evict({
          id: cache.identify({
            __typename: 'Event',
            id: deletedEvent.id
          })
        })

        cache.gc()
      }
    }
  })
  const [createEvent] = useCreateEventMutation({
    update (cache, { data: updatedData }) {
      const createdEvent = updatedData?.createEvent

      if (createdEvent) {
        cache.modify({
          fields: {
            events (existingEvents = []) {
              const newEventRef = cache.writeFragment({
                data: createdEvent,
                fragment: EventDataFragmentDoc
              })

              return [...existingEvents, newEventRef]
            }
          }
        })
      }
    }
  })
  const [updateEvent] = useUpdateEventMutation({
    update (cache, { data: updatedData }) {
      const updatedEvent = updatedData?.updateEvent

      if (updatedEvent) {
        cache.modify({
          id: cache.identify({
            __typename: 'Event',
            id: updatedEvent.id
          }),
          fields: {
            name () {
              return updatedEvent.name
            },
            texts () {
              return updatedEvent.texts
            },
            startDate () {
              return updatedEvent.startDate
            },
            endDate () {
              return updatedEvent.endDate
            },
            active () {
              return updatedEvent.active
            },
            supportEmail () {
              return updatedEvent.supportEmail
            }
          }
        })
      }
    }
  })
  const [updateActiveEvent] = useUpdateActiveEventMutation({
    update (cache, { data: updatedData }) {
      const isActive = !!updatedData?.updateActiveEvent?.active

      cache.modify({
        id: cache.identify({
          __typename: 'Event',
          id: updatedData?.updateActiveEvent?.id
        }),
        fields: {
          active () {
            return isActive
          }
        }
      })

      if (isActive) {
        cache.modify({
          fields: {
            events (existingEvents = []) {
              return existingEvents.map((eventRef: any) => {
                if (eventRef.__ref !== `Event:${updatedData?.updateActiveEvent?.id}`) {
                  cache.modify({
                    id: cache.identify({
                      __typename: 'Event',
                      id: eventRef.__ref.split(':')[1]
                    }),
                    fields: {
                      active () {
                        return false
                      }
                    }
                  })

                  return {
                    ...eventRef
                  }
                }

                return eventRef
              })
            }
          }
        })
      }
    }
  })

  const handleDeleteEvent = React.useCallback(async (id: string) => {
    return await softDeleteMutation({
      variables: {
        id
      }
    })
  }, [])

  const handleCreateEvent = React.useCallback(async (input: CreateEventInput) => {
    const { texts, ...rest } = input

    return await createEvent({
      variables: {
        data: {
          ...rest,
          texts: mapTextsForMutation(texts as Record<string, any>)
        }
      }
    })
  }, [])

  const handelUpdateActiveEvent = React.useCallback(async (id: string, active: boolean) => {
    return await updateActiveEvent({
      variables: {
        id,
        active
      }
    })
  }, [])

  const handleUpdateEvent = React.useCallback(async (id: string, input: UpdateEventInput) => {
    const { texts, ...rest } = input

    return await updateEvent({
      variables: {
        id,
        data: {
          ...rest,
          texts: mapTextsForMutation(texts as Record<string, any>)
        }
      }
    })
  }, [])

  const value = React.useMemo<EventsDataProviderContextType>(() => {
    return {
      eventsData,
      loading,
      handleDeleteEvent,
      handleCreateEvent,
      handleUpdateEvent,
      handelUpdateActiveEvent
    }
  }, [eventsData, loading])

  return (
    <>
      <EventsDataProviderContext.Provider value={value}>
        {children}
      </EventsDataProviderContext.Provider>
    </>
  )
}

export const useEventsContext = () => React.useContext(EventsDataProviderContext)
