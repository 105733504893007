import { FeedbackEvaluationTypeProps } from '@features/feedbackEvaluation/typings/types'
import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FeedbackEvaluationBar } from '../FeedbackEvaluationBar'

export const FeedbackEvaluationRating: React.FC<FeedbackEvaluationTypeProps> = ({ data }) => {
  const { t } = useTranslation()

  const cumulatedValue = React.useMemo(() => {
    return parseFloat((data.feedbackAnswers.reduce((acc, curr) => {
      return acc + curr.value
    }, 0) / (data.feedbackAnswers.length || 1)).toFixed(2))
  }, [data])

  const mappedRatings = React.useMemo(() => {
    const ratings: Record<number, number> = {
      5: 0,
      4: 0,
      3: 0,
      2: 0,
      1: 0
    }

    return data.feedbackAnswers.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.value]: (acc[curr.value] || 0) + 1
      }
    }, ratings)
  }, [data])

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mb: 3
      }}
      >
        <Rating
          readOnly
          value={cumulatedValue}
          precision={0.1}
        />
        <Typography
          sx={{
            fontWeight: 'bold',
            ml: 1
          }}
        >{cumulatedValue}</Typography>
      </Box>

      {Object.entries(mappedRatings).sort(([a], [b]) => parseInt(b) - parseInt(a)).map(([key, value]) => (
        <Box
          key={`question_${data.id}_rating_${key}`}
          sx={{
            mb: 2
          }}
        >
          <FeedbackEvaluationBar
            label={t('common.starRating', { count: parseInt(key) }) as string}
            total={data.feedbackAnswers.length}
            value={value}
          />
        </Box>
      ))}
    </Box>
  )
}
