import React from 'react'
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useEvaluationContext } from '../provider/EvaluationDataProvider'

export const EvaluationChallengeFilter: React.FC = () => {
  const { t } = useTranslation()

  const { challengesData, updateChallengeFilter, challengeFilter } = useEvaluationContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography sx={{
        mr: 2,
        fontWeight: 'bold'
      }}
      >
        {t('evaluation.challengeFilter')}
      </Typography>
      <Box sx={{
        mt: 1,
        width: '150px'
      }}
      >
        <FormControl fullWidth>
          <Select
            fullWidth
            value={challengeFilter}
            onChange={(e) => updateChallengeFilter(e.target.value as string)}
            displayEmpty
            sx={{
              minWidth: 300
            }}
            renderValue={(value) => {
              const found = challengesData.find((item) => item.id === value)

              return found
                ? t('challenges.withOrder', { order: found.order + 1 })
                : t('evaluation.allChallenges')
            }}
          >
            <MenuItem value="">
              {t('evaluation.allChallenges')}
            </MenuItem>
            {challengesData.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {t('challenges.titleWithOrder', { order: item.order + 1, title: item.texts.title })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}
