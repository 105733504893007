import { useMemo } from 'react'
import { Location, matchRoutes, useLocation } from 'react-router'
import { useRoutesContext } from '@providers/RoutesProvider'
import { AgnosticRouteMatch } from '@sentry/react/types/types'
import { AppRouteObject } from '@services/router/router'

type MatchedRoutes = {
  matched: AgnosticRouteMatch<string, AppRouteObject>[] | null;
  location: Location;
  routes: AppRouteObject[];
};

export const useMatchedRoutes = (): MatchedRoutes => {
  const location = useLocation()
  const { routes } = useRoutesContext()

  const matched = useMemo(
    () => (routes ? matchRoutes(routes, location) : null),
    [location]
  )

  return {
    matched,
    location,
    routes
  }
}
