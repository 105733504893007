import { TypographyVariants } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import { defaultTheme } from '../core'
import './typography.css'

export type ThemeFontSizes =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl';
export type ThemeTypoVariantsType = Partial<keyof TypographyOptions>;

export const themeTypoVariants: ThemeTypoVariantsType[] = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body1',
  'body2',
  'subtitle1',
  'subtitle2',
  'caption',
  'button'
]

export const fontSizes: Record<ThemeFontSizes, string> = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.5rem',
  '2xl': '1.75rem',
  '3xl': '2rem',
  '4xl': '3.5rem'
}

export const lineHeights: Record<ThemeFontSizes, string> = {
  xs: '1.125rem',
  sm: '1.25rem',
  md: '1.5rem',
  lg: '1.75rem',
  xl: '1.75rem',
  '2xl': '2.375rem',
  '3xl': '3.25rem',
  '4xl': '3.75rem'
}

export const fontFamily = {
  body: 'Sora',
  heading: 'Sora'
}

export const typography: TypographyOptions & TypographyVariants = {
  ...defaultTheme.typography,
  fontSize: 16,
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes['4xl'],
    lineHeight: lineHeights['3xl'],
    fontWeight: 'bold'
  },
  h2: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes['3xl'],
    lineHeight: lineHeights['3xl'],
    fontWeight: 'bold'
  },
  h3: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes['2xl'],
    lineHeight: lineHeights['2xl'],
    fontWeight: 'bold'
  },
  h4: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes.xl,
    lineHeight: lineHeights.lg,
    fontWeight: 'normal'
  },
  h5: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: 'normal'
  },
  h6: {
    fontFamily: fontFamily.heading,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: 600
  },
  subtitle1: {
    fontFamily: fontFamily.body,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md
  },
  subtitle2: {
    fontFamily: fontFamily.body,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs
  },
  body1: {
    fontFamily: fontFamily.body,
    lineHeight: lineHeights.md,
    fontWeight: 500
  },
  body2: {
    fontFamily: fontFamily.body,
    lineHeight: lineHeights.md,
    fontSize: fontSizes.lg,
    fontWeight: 400
  },
  button: {
    fontFamily: fontFamily.body,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.lg,
    fontWeight: 600
  },
  caption: {
    fontFamily: fontFamily.body,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.md
  },
  overline: {
    fontFamily: fontFamily.body,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.md,
    textTransform: 'uppercase'
  }
}
