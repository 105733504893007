import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiChipType = {
  defaultProps?: ComponentsProps['MuiChip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip'];
  variants?: ComponentsVariants['MuiChip'];
}

export const MuiChip:MuiChipType = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      border: '1px solid #000000',
      backgroundColor: palette.secondary[100]
    }
  }
}
