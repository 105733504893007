import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiInputBaseType = {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
}

export const MuiInputBase:MuiInputBaseType = {
  styleOverrides: {
    root: {
      '&.MuiOutlinedInput-root': {
        borderRadius: 0
      },
      fieldset: {
        border: '1px solid black'
      },
      '&.Mui-focused': {
        fieldset: {
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: '#000000'
          }
        }
      },
      '&.Mui-error': {
        color: palette.error.main,
        fieldset: {
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: '#000000'
          }
        }
      },
      '&.MuiInputBase-adornedEnd': {
        paddingRight: '0px',
        input: {
          paddingRight: '24px'
        },
        '.MuiIconButton-edgeEnd': {
          marginRight: '8px'
        }
      }
    }
  }
}
