import React from 'react'
import { Box } from '@mui/material'

import { TeamNode } from '../typings/types'

import { JuryTeamListItem } from './JuryTeamListItem'

type Props = {
  teams: TeamNode[]
  onSelected: (team: TeamNode) => void
}

export const JuryTeamList: React.FC<Props> = ({ teams, onSelected }) => {
  return (
    <Box
      pb={2}
    >
      {teams.map((team) => (
        <Box key={team.id}
          mb={1}
          onClick={() => onSelected(team)}
        >
          <JuryTeamListItem team={team} />
        </Box>
      ))}
    </Box>
  )
}
