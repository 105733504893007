import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { convertBytesToHumanReadable } from '@utils/format'

import { locales } from './locales'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'filesize') {
          return convertBytesToHumanReadable(parseInt(value))
        }
        return value
      }
    },
    resources: locales
  })

export default i18n
