import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'

export const SchemeCreateEditInputs: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TextInput
        formKey="ratingScale"
        label={t('common.scale')}
        options={{
          required: true
        }}
        type="number"
      />
    </>
  )
}
