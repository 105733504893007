import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  value: number
  total: number
  label: string
}

export const FeedbackEvaluationBar: React.FC<Props> = ({ value, total, label }) => {
  const { t } = useTranslation()

  const cumulatedValue = React.useMemo(() => {
    return (value / (total || 1)) * 100
  }, [value, total])

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
      >
        <Typography>
          {label}
        </Typography>
        <Typography>
          {t('common.feedbacks', { count: value })}
        </Typography>
      </Box>
      <Box sx={{
        border: '1px solid #000',
        backgroundColor: 'secondary.200',
        height: '30px',
        width: '100%',
        overflow: 'hidden'
      }}
      >
        <Box sx={{
          backgroundColor: 'primary.main',
          height: '100%',
          width: `${cumulatedValue}%`,
          borderRight: value > 0 && value < 100 ? '1px solid #000' : 'none',
          display: 'flex',
          alignItems: 'center'
        }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 'bold',
              ml: 1,
              mt: '2px'
            }}
          >{`${cumulatedValue.toFixed(2)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
