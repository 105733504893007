import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiAccordionType = {
  defaultProps?: ComponentsProps['MuiAccordion'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordion'];
  variants?: ComponentsVariants['MuiAccordion'];
}

export const MuiAccordion:MuiAccordionType = {
  styleOverrides: {
    root: {
      '&.MuiPaper-root': {
        borderRadius: 0,
        padding: '16px',
        border: '1px solid #000',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)'
      },

      '&.Mui-expanded': {
        minHeight: 'auto'
      },

      '.MuiAccordionDetails-root': {
        padding: '0'
      },

      '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#000'
      },

      '.MuiAccordionSummary-root': {
        padding: '0',
        minHeight: 'auto',

        '&.Mui-expanded': {
          minHeight: 'auto'
        },

        '.MuiAccordionSummary-content': {
          margin: 0
        }
      }
    }
  }
}
