import { isLoggedIn, resetAuthData } from '@services/store/slices/auth'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import React from 'react'
import { useNavigate } from 'react-router'

import { useMatchedRoutes } from './useMatchedRoutes'

export const useAuthenticated = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(isLoggedIn)

  const { matched } = useMatchedRoutes()

  React.useEffect(() => {
    if (!loggedIn) {
      dispatch(resetAuthData())

      navigate('/login', {
        state: {
          from: matched?.[0].pathname || ''
        }
      })
    }
  }, [loggedIn, navigate])
}
