import { Box, Button } from '@mui/material'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TeamButtonActions } from '../typings/types'

type Props = {
  handleAction: (action: TeamButtonActions) => void
}

export const TeamsHeaderExtension: React.FC<Props> = ({ handleAction }) => {
  const { t } = useTranslation()

  const onCreateTeamClicked = () => {
    handleAction(TeamButtonActions.CREATE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {can('teams:create') && (
        <Button
          sx={{ width: 'auto' }}
          onClick={onCreateTeamClicked}
        >
          {t('teams.createTeam')}
        </Button>
      )}
    </Box>
  )
}
