import React from 'react'
import { Roles } from '@typings/roles'
import { JuryView } from '@features/jury/views/JuryView'
import { JuryLayout } from '@shared/layouts/JuryLayout'
import { JuryTeamView } from '@features/jury/views/JuryTeamView'
import { JuryTeamRatingView } from '@features/jury/views/JuryTeamRatingView'

import { AppRouteObject } from '../router'

export const juryRoutes: AppRouteObject[] = [
  {
    path: '/jury',
    element: <JuryLayout />,
    access: {
      roles: [Roles.JURY]
    },
    children: [{
      index: true,
      element: <JuryView />
    }, {
      path: ':id',
      element: <JuryTeamView />
    }, {
      path: ':id/:criteriaId',
      element: <JuryTeamRatingView />
    }]
  }
]
