import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Tooltip, Typography } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

export type FilterSelectItem = {
  id: string
  name: string
  key?: string
}

type Props = GridFilterInputValueProps & {
  items: FilterSelectItem[],
  selectProps?: SelectProps
}

const RenderedValue: React.FC<{ items: FilterSelectItem[], value: string | string[] }> = ({ items, value }) => {
  const { t } = useTranslation()

  const renderValue = React.useMemo(() => {
    if (!value || value?.length === 0) {
      return t('common.filterValue')
    }

    return items.filter((select) => typeof value === 'string'
      ? select.id === value
      : value.includes(select.id)).map((select) => select.name).join(', ')
  }, [value, items])

  return (
    <Tooltip title = {renderValue}>
      <Typography sx={{
        lineHeight: '23px',
        fontSize: '16px',
        color: (typeof value === 'string' ? value : value?.length > 0) ? 'text.primary' : 'grey.500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 400
      }}
      >
        {renderValue}
      </Typography>
    </Tooltip>
  )
}

export const FilterSelectInput:React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { item, applyValue, items, selectProps } = props

  const handleFilterChange = (value: any) => {
    applyValue({
      ...item,
      value: (!value || value.length === 0) ? null : value
    })
  }

  return (
    <FormControl variant='standard'>
      <InputLabel shrink>{t('common.value')}</InputLabel>
      <Select
        onChange={(e) => {
          handleFilterChange(e.target.value)
        }}
        value={item.value ?? []}
        sx={{
          '.MuiInputBase-input': {
            paddingTop: '4px!important',
            paddingBottom: '5px!important'
          }
        }}
        displayEmpty
        renderValue={(value) => (
          <RenderedValue items={items} value={value as string | string[]} />
        )}
        {...selectProps}
      >
        {!selectProps?.multiple &&
          <MenuItem value=''>
            {t('common.any')}
          </MenuItem>
        }
        {items.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
