import { Grid } from '@mui/material'
import { useAppSelector } from '@services/store/store'
import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { HomeDataProvider } from '../provider/HomeDataProvider'
import { ActiveEventCounterCard } from '../components/ActiveEventCounterCard'

const HomeViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { user } = useAppSelector(({ auth }) => auth)

  return (
    <AdminContentLayout
      title={t('home.userWelcome', {
        name: t('common.fullname', {
          firstname: user?.firstname,
          lastname: user?.lastname
        })
      }
      )}
      boxProps={{
        sx: {
          pt: 2
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ActiveEventCounterCard />
        </Grid>
      </Grid>
    </AdminContentLayout>
  )
}

export const HomeView: React.FC = () => (
  <HomeDataProvider>
    <HomeViewContent />
  </HomeDataProvider>
)
