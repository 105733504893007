import {
  CreateOrUpdateRatingsMutation,
  useActiveEventWithSchemeQuery, useCreateOrUpdateRatingsMutation, useTeamWithRatingQuery
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from 'apollo-link'

import { ActiveScheme, CreateRatingsInput, EventData, TeamWithRating } from '../typings/types'

export type JuryDataProviderContextType = {
  eventData: EventData | null
  teamData: TeamWithRating | null
  schemeData: ActiveScheme | null
  loading: boolean
  handleRateTeam: (input: CreateRatingsInput) => Promise<FetchResult<CreateOrUpdateRatingsMutation>>
}

const JuryDataProviderContext = React.createContext<JuryDataProviderContextType>(
  {} as any
)

export const JuryDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{id: string}>()

  const { data: eventData, loading: eventDataLoading } = useActiveEventWithSchemeQuery({
    fetchPolicy: 'cache-and-network'
  })

  const { data, loading: dataLoading } = useTeamWithRatingQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string
    },
    skip: !id
  })

  const [rateTeam] = useCreateOrUpdateRatingsMutation({
    update (cache, { data: updatedData }) {
      const updatedRatings = updatedData?.createOrUpdateRatings || []

      const mappedByTeams = updatedRatings.reduce((acc, rating) => {
        const { teamId } = rating

        if (!acc[teamId]) {
          acc[teamId] = []
        }

        acc[teamId].push(rating)

        return acc
      }, {} as Record<string, CreateOrUpdateRatingsMutation['createOrUpdateRatings'][0][]>)

      Object.entries(mappedByTeams).forEach(([teamId, ratings]) => {
        cache.modify({
          id: cache.identify({
            __typename: 'Team',
            id: teamId
          }),
          fields: {
            ratings () {
              return ratings
            }
          }
        })
      }, {})
    }
  })

  const teamData = React.useMemo<TeamWithRating | null>(() => {
    return data?.team || null
  }, [data])

  const schemeData = React.useMemo<ActiveScheme | null>(() => {
    return eventData?.activeEvent?.scheme || null
  }, [eventData])

  const loading = useArtificialLoading(0, [dataLoading, eventDataLoading])

  const handleRateTeam = React.useCallback(async (input: CreateRatingsInput) => {
    return await rateTeam({
      variables: {
        data: Object.entries(input.ratings).map(([criteriaId, value]) => ({
          criteriaId,
          teamId: id as string,
          value
        })).filter((rating) => rating.value !== null && rating.value !== undefined)
      }
    })
  }, [])

  const value = React.useMemo<JuryDataProviderContextType>(() => {
    return {
      eventData: eventData?.activeEvent || null,
      teamData,
      schemeData,
      loading,
      handleRateTeam
    }
  }, [eventData, teamData, schemeData, loading])

  return (
    <>
      <JuryDataProviderContext.Provider value={value}>
        {children}
      </JuryDataProviderContext.Provider>
    </>
  )
}

export const useJuryContext = () => React.useContext(JuryDataProviderContext)
