import { createTheme } from '@mui/material'
import { ThemeOptions, TypographyVariants } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import { components } from './components'
import { palette } from './core/palette/palette'
import { typography } from './core/typography/typography'

export type CustomThemeOptions = ThemeOptions & {
  typography: TypographyOptions & TypographyVariants;
};

export const themeProperties: CustomThemeOptions = {
  typography,
  palette,
  components
}

export const theme = createTheme({
  ...themeProperties,
  components
})
