import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JWT: any;
  Texts: any;
  Upload: any;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
  user: User;
};

export type Challenge = {
  __typename?: 'Challenge';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Int'];
  teams: Array<Team>;
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type CreateChallengeInput = {
  eventId: Scalars['String'];
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type CreateEventInput = {
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  supportEmail?: InputMaybe<Scalars['String']>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFeedbackAnswerInput = {
  feedbackQuestionId: Scalars['String'];
  value: Scalars['JSON'];
};

export type CreateFeedbackQuestionAnswerInput = {
  order: Scalars['Int'];
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type CreateFeedbackQuestionInput = {
  answers: Array<CreateFeedbackQuestionAnswerInput>;
  config?: InputMaybe<Scalars['JSON']>;
  eventId: Scalars['String'];
  order: Scalars['Int'];
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  type: FeedbackQuestionType;
};

export type CreateRatingInput = {
  criteriaId: Scalars['String'];
  teamId: Scalars['String'];
  value: Scalars['Int'];
};

export type CreateSchemeCriteriaInput = {
  parentId?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  schemeId: Scalars['String'];
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  weighting?: InputMaybe<Scalars['Int']>;
};

export type CreateSchemeInput = {
  eventId: Scalars['String'];
  ratingScale: Scalars['Int'];
};

export type CreateTeamInput = {
  challengeId?: InputMaybe<Scalars['String']>;
  contact: Scalars['String'];
  eventId: Scalars['String'];
  name: Scalars['String'];
  teamMembers: Array<CreateTeamMemberInput>;
};

export type CreateTeamMemberInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateTranslationInput = {
  key: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  firstname: Scalars['String'];
  inviteUser?: InputMaybe<Scalars['Boolean']>;
  lastname: Scalars['String'];
  roleId: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  active: Scalars['Boolean'];
  challenges: Array<Challenge>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  feedbackQuestions: Array<FeedbackQuestion>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rateable: Scalars['Boolean'];
  registration: Scalars['Boolean'];
  scheme: Scheme;
  startDate: Scalars['DateTime'];
  submission: Scalars['Boolean'];
  supportEmail?: Maybe<Scalars['String']>;
  teams: Array<Team>;
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};

export type EventUser = {
  __typename?: 'EventUser';
  event: Event;
  eventId: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type FeedbackAnswer = {
  __typename?: 'FeedbackAnswer';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  feedbackQuestionId: Scalars['String'];
  id: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['JSON']>;
};

export type FeedbackQuestion = {
  __typename?: 'FeedbackQuestion';
  config?: Maybe<Scalars['JSON']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  feedbackAnswers: Array<FeedbackAnswer>;
  feedbackQuestionAnswers: Array<FeedbackQuestionAnswer>;
  id: Scalars['String'];
  order: Scalars['Int'];
  texts: Scalars['Texts'];
  type: FeedbackQuestionType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type FeedbackQuestionAnswer = {
  __typename?: 'FeedbackQuestionAnswer';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** The type of feedback questions */
export enum FeedbackQuestionType {
  Choice = 'Choice',
  Open = 'Open',
  Rating = 'Rating'
}

export type File = {
  __typename?: 'File';
  config: Scalars['JSON'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  modelId: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateTeam: Team;
  confirmMail: Scalars['Boolean'];
  createChallenge: Challenge;
  createEvent: Event;
  createFeedbackAnswer: Array<FeedbackAnswer>;
  createFeedbackQuestion: FeedbackQuestion;
  createOrUpdateRating: Rating;
  createOrUpdateRatings: Array<Rating>;
  createScheme: Scheme;
  createSchemeCriteria: SchemeCriteria;
  createTeam: Team;
  createUser: User;
  deleteChallenge: Challenge;
  deleteEvent: Event;
  deleteFeedbackAnswers: Scalars['Boolean'];
  deleteFeedbackQuestion: FeedbackQuestion;
  deleteFile: File;
  deleteFiles: Scalars['Boolean'];
  deleteRatings: Scalars['Boolean'];
  deleteScheme: Scheme;
  deleteSchemeCriteria: SchemeCriteria;
  deleteTeam: Team;
  deleteUser: User;
  deleteUsers: Array<User>;
  forgotPassword: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  login: Auth;
  logout: Scalars['Boolean'];
  refreshToken: Token;
  registerTeam: Team;
  reorderChallenges: Array<Challenge>;
  reorderFeedbackQuestions: Array<FeedbackQuestion>;
  replaceFileInOrder: File;
  resendVerificationCode: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  softDeleteEvent: Event;
  submitResults: TeamResult;
  updateActiveEvent: Event;
  updateChallenge: Challenge;
  updateEvent: Event;
  updateFeedbackQuestion: FeedbackQuestion;
  updateFileInfo: File;
  updateScheme: Scheme;
  updateSchemeCriteria: SchemeCriteria;
  updateTeam: Team;
  updateUser: User;
  uploadFile: File;
  verifyTeam: TeamToken;
};


export type MutationActivateTeamArgs = {
  id: Scalars['String'];
};


export type MutationConfirmMailArgs = {
  token: Scalars['String'];
};


export type MutationCreateChallengeArgs = {
  data: CreateChallengeInput;
};


export type MutationCreateEventArgs = {
  data: CreateEventInput;
};


export type MutationCreateFeedbackAnswerArgs = {
  data: Array<CreateFeedbackAnswerInput>;
};


export type MutationCreateFeedbackQuestionArgs = {
  data: CreateFeedbackQuestionInput;
};


export type MutationCreateOrUpdateRatingArgs = {
  data: CreateRatingInput;
};


export type MutationCreateOrUpdateRatingsArgs = {
  data: Array<CreateRatingInput>;
};


export type MutationCreateSchemeArgs = {
  data: CreateSchemeInput;
};


export type MutationCreateSchemeCriteriaArgs = {
  data: CreateSchemeCriteriaInput;
};


export type MutationCreateTeamArgs = {
  data: CreateTeamInput;
};


export type MutationCreateUserArgs = {
  data: CreateUserInput;
};


export type MutationDeleteChallengeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFeedbackAnswersArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteFeedbackQuestionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFilesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteRatingsArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteSchemeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSchemeCriteriaArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationInviteUserArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['JWT'];
};


export type MutationRegisterTeamArgs = {
  data: RegisterTeamInput;
};


export type MutationReorderChallengesArgs = {
  eventId: Scalars['String'];
  order: Array<Scalars['String']>;
};


export type MutationReorderFeedbackQuestionsArgs = {
  eventId: Scalars['String'];
  order: Array<Scalars['String']>;
};


export type MutationReplaceFileInOrderArgs = {
  data: UploadFileInput;
  file: Scalars['Upload'];
  maxImageDimension?: InputMaybe<Scalars['Int']>;
};


export type MutationResendVerificationCodeArgs = {
  id: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSoftDeleteEventArgs = {
  id: Scalars['String'];
};


export type MutationSubmitResultsArgs = {
  data: SubmitResultInput;
};


export type MutationUpdateActiveEventArgs = {
  active: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationUpdateChallengeArgs = {
  data: UpdateChallengeInput;
  id: Scalars['String'];
};


export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
  id: Scalars['String'];
};


export type MutationUpdateFeedbackQuestionArgs = {
  data: UpdateFeedbackQuestionInput;
  id: Scalars['String'];
};


export type MutationUpdateFileInfoArgs = {
  data: UpdateFileInput;
  id: Scalars['String'];
};


export type MutationUpdateSchemeArgs = {
  data: UpdateSchemeInput;
  id: Scalars['String'];
};


export type MutationUpdateSchemeCriteriaArgs = {
  data: UpdateSchemeCriteriaInput;
  id: Scalars['String'];
};


export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: Scalars['String'];
};


export type MutationUploadFileArgs = {
  data: UploadFileInput;
  file: Scalars['Upload'];
  maxImageDimension?: InputMaybe<Scalars['Int']>;
};


export type MutationVerifyTeamArgs = {
  data: VerifyTeamInput;
};

export type Permission = {
  __typename?: 'Permission';
  ability: Scalars['String'];
  id: Scalars['String'];
  resource: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeEvent?: Maybe<Event>;
  challenge: Challenge;
  challenges: Array<Challenge>;
  checkTeamValid: Scalars['Boolean'];
  checkValidToken: Scalars['Boolean'];
  event?: Maybe<Event>;
  events: Array<Event>;
  feedbackQuestion?: Maybe<FeedbackQuestion>;
  feedbackQuestionAnswer?: Maybe<FeedbackQuestionAnswer>;
  feedbackQuestions: Array<FeedbackQuestion>;
  file: File;
  me: User;
  roles: Array<Role>;
  scheme: Scheme;
  schemeByEvent?: Maybe<Scheme>;
  schemeCriteria: SchemeCriteria;
  schemeForEvaluation: Scheme;
  team: Team;
  teamActiveEvent: Team;
  teamResults: Array<TeamResult>;
  teams: Array<Team>;
  user: User;
  users: Array<User>;
};


export type QueryChallengeArgs = {
  id: Scalars['String'];
};


export type QueryChallengesArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};


export type QueryCheckTeamValidArgs = {
  id: Scalars['String'];
};


export type QueryCheckValidTokenArgs = {
  token: Scalars['String'];
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryFeedbackQuestionArgs = {
  id: Scalars['String'];
};


export type QueryFeedbackQuestionAnswerArgs = {
  id: Scalars['String'];
};


export type QueryFeedbackQuestionsArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};


export type QueryFileArgs = {
  id: Scalars['String'];
};


export type QuerySchemeArgs = {
  id: Scalars['String'];
};


export type QuerySchemeByEventArgs = {
  eventId: Scalars['String'];
};


export type QuerySchemeCriteriaArgs = {
  id: Scalars['String'];
};


export type QuerySchemeForEvaluationArgs = {
  id: Scalars['String'];
};


export type QueryTeamArgs = {
  id: Scalars['String'];
};


export type QueryTeamActiveEventArgs = {
  id: Scalars['String'];
};


export type QueryTeamResultsArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};


export type QueryTeamsArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
};

export type Rating = {
  __typename?: 'Rating';
  comment?: Maybe<Scalars['String']>;
  criteriaId: Scalars['String'];
  id: Scalars['String'];
  teamId: Scalars['String'];
  userId: Scalars['String'];
  value: Scalars['Int'];
};

/** RatingState */
export enum RatingState {
  Full = 'FULL',
  None = 'NONE',
  Partial = 'PARTIAL'
}

export type RegisterTeamInput = {
  challengeId: Scalars['String'];
  contact: Scalars['String'];
  name: Scalars['String'];
  teamMembers: Array<CreateTeamMemberInput>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  key: Scalars['String'];
  permissions: Array<Permission>;
};

export type Scheme = {
  __typename?: 'Scheme';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  id: Scalars['String'];
  ratingCriteriaCount: Scalars['Int'];
  ratingScale: Scalars['Int'];
  schemeCriteria: Array<SchemeCriteria>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type SchemeCriteria = {
  __typename?: 'SchemeCriteria';
  canRate: Scalars['Boolean'];
  children: Array<SchemeCriteria>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  weighting?: Maybe<Scalars['Int']>;
};

export type SubmitResultInput = {
  repository: Scalars['String'];
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  website?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  ratingsUpdated: Array<Rating>;
};

export type Team = {
  __typename?: 'Team';
  active: Scalars['Boolean'];
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['String']>;
  contact: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  evaluation: Scalars['JSON'];
  eventId: Scalars['String'];
  hasResult?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ratingState: RatingState;
  ratings: Array<Rating>;
  teamMembers: Array<TeamMember>;
  teamMembersCount: Scalars['Int'];
  teamResult?: Maybe<TeamResult>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  verificationCode: Scalars['String'];
};


export type TeamRatingsArgs = {
  own?: InputMaybe<Scalars['Boolean']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type TeamResult = {
  __typename?: 'TeamResult';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  files: Array<File>;
  id: Scalars['ID'];
  repository: Scalars['String'];
  team: Team;
  teamId: Scalars['String'];
  texts: Scalars['Texts'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

export type TeamToken = {
  __typename?: 'TeamToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  team: Team;
};

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
};

export type UpdateChallengeInput = {
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateEventInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  rateable?: InputMaybe<Scalars['Boolean']>;
  registration?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  submission?: InputMaybe<Scalars['Boolean']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateFeedbackQuestionInput = {
  answers: Array<CreateFeedbackQuestionAnswerInput>;
  config?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<Scalars['Int']>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
};

export type UpdateFileInput = {
  config?: InputMaybe<Scalars['JSON']>;
  key?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
};

export type UpdateSchemeCriteriaInput = {
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  texts?: InputMaybe<Array<CreateTranslationInput>>;
  weighting?: InputMaybe<Scalars['Int']>;
};

export type UpdateSchemeInput = {
  ratingScale?: InputMaybe<Scalars['Int']>;
};

export type UpdateTeamInput = {
  challengeId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  teamMembers: Array<CreateTeamMemberInput>;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  config?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type UploadFileInput = {
  config?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
  meta?: InputMaybe<Scalars['JSON']>;
  model: Scalars['String'];
  modelId: Scalars['String'];
  replace?: InputMaybe<Scalars['Boolean']>;
  replaceId?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  eventUsers?: Maybe<Array<EventUser>>;
  events: Array<Event>;
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type VerifyTeamInput = {
  id: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type AuthUserFragment = { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, role: { __typename?: 'Role', key: string, permissions: Array<{ __typename?: 'Permission', ability: string, resource: string }> } };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, role: { __typename?: 'Role', key: string, permissions: Array<{ __typename?: 'Permission', ability: string, resource: string }> } } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['JWT'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'Token', accessToken: any, refreshToken: any } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ConfirmMailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmMailMutation = { __typename?: 'Mutation', confirmMail: boolean };

export type CheckValidTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckValidTokenQuery = { __typename?: 'Query', checkValidToken: boolean };

export type ChallengeDataFragment = { __typename?: 'Challenge', id: string, order: number, texts: any };

export type ChallengeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ChallengeQuery = { __typename?: 'Query', challenge: { __typename?: 'Challenge', id: string, order: number, texts: any } };

export type ChallengesQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type ChallengesQuery = { __typename?: 'Query', challenges: Array<{ __typename?: 'Challenge', id: string, order: number, texts: any }> };

export type CreateChallengeMutationVariables = Exact<{
  data: CreateChallengeInput;
}>;


export type CreateChallengeMutation = { __typename?: 'Mutation', createChallenge: { __typename?: 'Challenge', id: string, order: number, texts: any } };

export type UpdateChallengeMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateChallengeInput;
}>;


export type UpdateChallengeMutation = { __typename?: 'Mutation', updateChallenge: { __typename?: 'Challenge', id: string, order: number, texts: any } };

export type ReorderChallengesMutationVariables = Exact<{
  eventId: Scalars['String'];
  order: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReorderChallengesMutation = { __typename?: 'Mutation', reorderChallenges: Array<{ __typename?: 'Challenge', id: string, order: number, texts: any }> };

export type DeleteChallengeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteChallengeMutation = { __typename?: 'Mutation', deleteChallenge: { __typename?: 'Challenge', id: string, order: number, texts: any } };

export type EventDataFragment = { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> };

export type EventMinimalDataFragment = { __typename?: 'Event', id: string, name: string };

export type ActiveEventQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveEventQuery = { __typename?: 'Query', activeEvent?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, teams: Array<{ __typename?: 'Team', id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null }>, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } | null };

export type ActiveEventWithSchemeQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveEventWithSchemeQuery = { __typename?: 'Query', activeEvent?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, scheme: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number, schemeCriteria: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean }> }> }, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } | null };

export type ActiveEventForJuryQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveEventForJuryQuery = { __typename?: 'Query', activeEvent?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, teams: Array<{ __typename?: 'Team', id: string, name: string, ratingState: RatingState }>, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } | null };

export type ActiveEventWithFeedbackQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveEventWithFeedbackQuery = { __typename?: 'Query', activeEvent?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, feedbackQuestions: Array<{ __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> }>, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } | null };

export type EventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> }> };

export type EventMinimalQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EventMinimalQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, name: string } | null };

export type EventsMinimalQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsMinimalQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, name: string }> };

export type CreateEventMutationVariables = Exact<{
  data: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } };

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } };

export type UpdateActiveEventMutationVariables = Exact<{
  id: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type UpdateActiveEventMutation = { __typename?: 'Mutation', updateActiveEvent: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } };

export type SoftDeleteEventMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SoftDeleteEventMutation = { __typename?: 'Mutation', softDeleteEvent: { __typename?: 'Event', id: string, name: string, startDate: any, endDate: any, active: boolean, registration: boolean, createdAt: any, updatedAt: any, texts: any, submission: boolean, supportEmail?: string | null, rateable: boolean, challenges: Array<{ __typename?: 'Challenge', id: string, texts: any, order: number }> } };

export type FeedbackAnswerFragment = { __typename?: 'FeedbackAnswer', id: string, feedbackQuestionId: string, value?: any | null };

export type CreateFeedbackAnswerMutationVariables = Exact<{
  data: Array<CreateFeedbackAnswerInput> | CreateFeedbackAnswerInput;
}>;


export type CreateFeedbackAnswerMutation = { __typename?: 'Mutation', createFeedbackAnswer: Array<{ __typename?: 'FeedbackAnswer', id: string, feedbackQuestionId: string, value?: any | null }> };

export type DeleteFeedbackAnswersMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type DeleteFeedbackAnswersMutation = { __typename?: 'Mutation', deleteFeedbackAnswers: boolean };

export type FeedbackQuestionAnswerDataFragment = { __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number };

export type FeedbackDataFragment = { __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> };

export type FeedbackQuestionsQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type FeedbackQuestionsQuery = { __typename?: 'Query', feedbackQuestions: Array<{ __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> }> };

export type FeedbackQuestionsForEvaluationQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type FeedbackQuestionsForEvaluationQuery = { __typename?: 'Query', feedbackQuestions: Array<{ __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackAnswers: Array<{ __typename?: 'FeedbackAnswer', id: string, feedbackQuestionId: string, value?: any | null }>, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> }> };

export type CreateFeedbackQuestionMutationVariables = Exact<{
  data: CreateFeedbackQuestionInput;
}>;


export type CreateFeedbackQuestionMutation = { __typename?: 'Mutation', createFeedbackQuestion: { __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> } };

export type UpdateFeedbackQuestionMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateFeedbackQuestionInput;
}>;


export type UpdateFeedbackQuestionMutation = { __typename?: 'Mutation', updateFeedbackQuestion: { __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> } };

export type ReorderFeedbackQuestionsMutationVariables = Exact<{
  eventId: Scalars['String'];
  order: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReorderFeedbackQuestionsMutation = { __typename?: 'Mutation', reorderFeedbackQuestions: Array<{ __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> }> };

export type DeleteFeedbackQuestionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFeedbackQuestionMutation = { __typename?: 'Mutation', deleteFeedbackQuestion: { __typename?: 'FeedbackQuestion', id: string, texts: any, type: FeedbackQuestionType, config?: any | null, order: number, feedbackQuestionAnswers: Array<{ __typename?: 'FeedbackQuestionAnswer', id: string, texts: any, order: number }> } };

export type FileDataFragment = { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any };

export type UploadFileMutationVariables = Exact<{
  data: UploadFileInput;
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any } };

export type UpdateFileMutationVariables = Exact<{
  data: UpdateFileInput;
  id: Scalars['String'];
}>;


export type UpdateFileMutation = { __typename?: 'Mutation', updateFileInfo: { __typename?: 'File', id: string, key: string, fileName: string, fileSize: number, createdAt: any, mimeType: string, url: string, config: any } };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'File', id: string } };

export type DeleteFilesMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteFilesMutation = { __typename?: 'Mutation', deleteFiles: boolean };

export type RatingDataFragment = { __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number };

export type CreateOrUpdateRatingsMutationVariables = Exact<{
  data: Array<CreateRatingInput> | CreateRatingInput;
}>;


export type CreateOrUpdateRatingsMutation = { __typename?: 'Mutation', createOrUpdateRatings: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }> };

export type DeleteRatingsMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type DeleteRatingsMutation = { __typename?: 'Mutation', deleteRatings: boolean };

export type RatingsUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RatingsUpdatedSubscription = { __typename?: 'Subscription', ratingsUpdated: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }> };

export type RolesMinimalFragment = { __typename?: 'Role', id: string, key: string };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, key: string }> };

export type SchemeDataMinimalFragment = { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number };

export type SchemeDataFragment = { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number, schemeCriteria: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean }> }> };

export type SchemeByEventQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type SchemeByEventQuery = { __typename?: 'Query', schemeByEvent?: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number, schemeCriteria: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean }> }> } | null };

export type SchemeByEventForCrudQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type SchemeByEventForCrudQuery = { __typename?: 'Query', schemeByEvent?: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number, schemeCriteria: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, roles: Array<{ __typename?: 'Role', id: string, key: string }> }>, roles: Array<{ __typename?: 'Role', id: string, key: string }> }> } | null };

export type SchemeByEventForEvaluationQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type SchemeByEventForEvaluationQuery = { __typename?: 'Query', schemeByEvent?: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number, schemeCriteria: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean }> }> } | null };

export type CreateSchemeMutationVariables = Exact<{
  data: CreateSchemeInput;
}>;


export type CreateSchemeMutation = { __typename?: 'Mutation', createScheme: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number } };

export type UpdateSchemeMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateSchemeInput;
}>;


export type UpdateSchemeMutation = { __typename?: 'Mutation', updateScheme: { __typename?: 'Scheme', id: string, ratingScale: number, ratingCriteriaCount: number } };

export type DeleteSchemeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSchemeMutation = { __typename?: 'Mutation', deleteScheme: { __typename?: 'Scheme', id: string } };

export type SchemeCriteriaDataFragment = { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean };

export type SchemeCriteriaWithRoleDataFragment = { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, roles: Array<{ __typename?: 'Role', id: string, key: string }> };

export type SchemeCriteriaNestedDataFragment = { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, children: Array<{ __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean }> };

export type CreateSchemeCriteriaMutationVariables = Exact<{
  data: CreateSchemeCriteriaInput;
}>;


export type CreateSchemeCriteriaMutation = { __typename?: 'Mutation', createSchemeCriteria: { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, roles: Array<{ __typename?: 'Role', id: string, key: string }> } };

export type UpdateSchemeCriteriaMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateSchemeCriteriaInput;
}>;


export type UpdateSchemeCriteriaMutation = { __typename?: 'Mutation', updateSchemeCriteria: { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, roles: Array<{ __typename?: 'Role', id: string, key: string }> } };

export type DeleteSchemeCriteriaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSchemeCriteriaMutation = { __typename?: 'Mutation', deleteSchemeCriteria: { __typename?: 'SchemeCriteria', id: string, weighting?: number | null, texts: any, parentId?: string | null, canRate: boolean, roles: Array<{ __typename?: 'Role', id: string, key: string }> } };

export type TeamResultDataFragment = { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> };

export type TeamResultWithTeamDataFragment = { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, team: { __typename?: 'Team', id: string, name: string }, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> };

export type TeamResultsQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type TeamResultsQuery = { __typename?: 'Query', teamResults: Array<{ __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> }> };

export type TeamResultsWithTeamQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type TeamResultsWithTeamQuery = { __typename?: 'Query', teamResults: Array<{ __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, team: { __typename?: 'Team', id: string, name: string }, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> }> };

export type SubmitResultsMutationVariables = Exact<{
  data: SubmitResultInput;
}>;


export type SubmitResultsMutation = { __typename?: 'Mutation', submitResults: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } };

export type TeamDataMinimalFragment = { __typename?: 'Team', id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null };

export type TeamDataFragment = { __typename?: 'Team', verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null };

export type TeamDataWithRatingStateFragment = { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null };

export type TeamDataWithRatingFragment = { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, ratings: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }>, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null };

export type CheckTeamValidQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CheckTeamValidQuery = { __typename?: 'Query', checkTeamValid: boolean };

export type TeamActiveEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TeamActiveEventQuery = { __typename?: 'Query', teamActiveEvent: { __typename?: 'Team', id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null } };

export type TeamsQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type TeamsQuery = { __typename?: 'Query', teams: Array<{ __typename?: 'Team', verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null }> };

export type TeamWithRatingQueryVariables = Exact<{
  id: Scalars['String'];
  own?: InputMaybe<Scalars['Boolean']>;
}>;


export type TeamWithRatingQuery = { __typename?: 'Query', team: { __typename?: 'Team', verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, ratings: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }>, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null } };

export type TeamsWithRatingQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
  own?: InputMaybe<Scalars['Boolean']>;
}>;


export type TeamsWithRatingQuery = { __typename?: 'Query', teams: Array<{ __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, ratings: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }>, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null }> };

export type TeamsWithEvaluationQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type TeamsWithEvaluationQuery = { __typename?: 'Query', teams: Array<{ __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, ratings: Array<{ __typename?: 'Rating', id: string, teamId: string, criteriaId: string, userId: string, value: number }>, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null }> };

export type ResendVerificationCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResendVerificationCodeMutation = { __typename?: 'Mutation', resendVerificationCode: boolean };

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTeamMutation = { __typename?: 'Mutation', deleteTeam: { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null } };

export type CreateTeamMutationVariables = Exact<{
  data: CreateTeamInput;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null } };

export type RegisterTeamMutationVariables = Exact<{
  data: RegisterTeamInput;
}>;


export type RegisterTeamMutation = { __typename?: 'Mutation', registerTeam: { __typename?: 'Team', id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null } };

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateTeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null } };

export type VerifyTeamMutationVariables = Exact<{
  data: VerifyTeamInput;
}>;


export type VerifyTeamMutation = { __typename?: 'Mutation', verifyTeam: { __typename?: 'TeamToken', accessToken: string, refreshToken: string, team: { __typename?: 'Team', id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null } } };

export type ActivateTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ActivateTeamMutation = { __typename?: 'Mutation', activateTeam: { __typename?: 'Team', ratingState: RatingState, verificationCode: string, contact: string, teamMembersCount: number, challengeId?: string | null, id: string, name: string, createdAt: any, active: boolean, hasResult?: boolean | null, challenge?: { __typename?: 'Challenge', id: string, order: number, texts: any } | null, teamMembers: Array<{ __typename?: 'TeamMember', id: string, firstName: string, lastName: string }>, teamResult?: { __typename?: 'TeamResult', id: string, repository: string, website?: string | null, texts: any, createdAt: any, files: Array<{ __typename?: 'File', id: string, fileName: string, fileSize: number, url: string, mimeType: string }> } | null } };

export type UserDataFragment = { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, active: boolean, createdAt: any, updatedAt: any, role: { __typename?: 'Role', id: string, key: string }, events: Array<{ __typename?: 'Event', id: string, name: string }> };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstname: string, lastname: string, email: string, active: boolean, createdAt: any, updatedAt: any, role: { __typename?: 'Role', id: string, key: string }, events: Array<{ __typename?: 'Event', id: string, name: string }> }> };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, active: boolean, createdAt: any, updatedAt: any, role: { __typename?: 'Role', id: string, key: string }, events: Array<{ __typename?: 'Event', id: string, name: string }> } };

export type InviteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: boolean };

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, active: boolean, createdAt: any, updatedAt: any, role: { __typename?: 'Role', id: string, key: string }, events: Array<{ __typename?: 'Event', id: string, name: string }> } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string, active: boolean, createdAt: any, updatedAt: any, role: { __typename?: 'Role', id: string, key: string }, events: Array<{ __typename?: 'Event', id: string, name: string }> } };

export const AuthUserFragmentDoc = gql`
    fragment AuthUser on User {
  id
  firstname
  lastname
  email
  role {
    key
    permissions {
      ability
      resource
    }
  }
}
    `;
export const ChallengeDataFragmentDoc = gql`
    fragment ChallengeData on Challenge {
  id
  order
  texts
}
    `;
export const EventDataFragmentDoc = gql`
    fragment EventData on Event {
  id
  name
  startDate
  endDate
  active
  registration
  createdAt
  updatedAt
  texts
  submission
  supportEmail
  rateable
  challenges {
    id
    texts
    order
  }
}
    `;
export const EventMinimalDataFragmentDoc = gql`
    fragment EventMinimalData on Event {
  id
  name
}
    `;
export const FeedbackAnswerFragmentDoc = gql`
    fragment FeedbackAnswer on FeedbackAnswer {
  id
  feedbackQuestionId
  value
}
    `;
export const FeedbackQuestionAnswerDataFragmentDoc = gql`
    fragment FeedbackQuestionAnswerData on FeedbackQuestionAnswer {
  id
  texts
  order
}
    `;
export const FeedbackDataFragmentDoc = gql`
    fragment FeedbackData on FeedbackQuestion {
  id
  texts
  type
  config
  order
  feedbackQuestionAnswers {
    ...FeedbackQuestionAnswerData
  }
}
    ${FeedbackQuestionAnswerDataFragmentDoc}`;
export const FileDataFragmentDoc = gql`
    fragment FileData on File {
  id
  key
  fileName
  fileSize
  createdAt
  mimeType
  url
  config
}
    `;
export const RolesMinimalFragmentDoc = gql`
    fragment RolesMinimal on Role {
  id
  key
}
    `;
export const SchemeDataMinimalFragmentDoc = gql`
    fragment SchemeDataMinimal on Scheme {
  id
  ratingScale
  ratingCriteriaCount
}
    `;
export const SchemeCriteriaDataFragmentDoc = gql`
    fragment SchemeCriteriaData on SchemeCriteria {
  id
  weighting
  texts
  parentId
  canRate
}
    `;
export const SchemeCriteriaNestedDataFragmentDoc = gql`
    fragment SchemeCriteriaNestedData on SchemeCriteria {
  ...SchemeCriteriaData
  children {
    ...SchemeCriteriaData
  }
}
    ${SchemeCriteriaDataFragmentDoc}`;
export const SchemeDataFragmentDoc = gql`
    fragment SchemeData on Scheme {
  ...SchemeDataMinimal
  schemeCriteria {
    ...SchemeCriteriaNestedData
  }
}
    ${SchemeDataMinimalFragmentDoc}
${SchemeCriteriaNestedDataFragmentDoc}`;
export const SchemeCriteriaWithRoleDataFragmentDoc = gql`
    fragment SchemeCriteriaWithRoleData on SchemeCriteria {
  ...SchemeCriteriaData
  roles {
    id
    key
  }
}
    ${SchemeCriteriaDataFragmentDoc}`;
export const TeamResultDataFragmentDoc = gql`
    fragment TeamResultData on TeamResult {
  id
  repository
  website
  texts
  createdAt
  files {
    id
    fileName
    fileSize
    url
    mimeType
  }
}
    `;
export const TeamResultWithTeamDataFragmentDoc = gql`
    fragment TeamResultWithTeamData on TeamResult {
  ...TeamResultData
  team {
    id
    name
  }
}
    ${TeamResultDataFragmentDoc}`;
export const TeamDataMinimalFragmentDoc = gql`
    fragment TeamDataMinimal on Team {
  id
  name
  createdAt
  active
  hasResult
}
    `;
export const TeamDataFragmentDoc = gql`
    fragment TeamData on Team {
  ...TeamDataMinimal
  verificationCode
  contact
  teamMembersCount
  challengeId
  challenge {
    id
    order
    texts
  }
  teamMembers {
    id
    firstName
    lastName
  }
  teamResult {
    ...TeamResultData
  }
}
    ${TeamDataMinimalFragmentDoc}
${TeamResultDataFragmentDoc}`;
export const TeamDataWithRatingStateFragmentDoc = gql`
    fragment TeamDataWithRatingState on Team {
  ...TeamData
  ratingState
}
    ${TeamDataFragmentDoc}`;
export const RatingDataFragmentDoc = gql`
    fragment RatingData on Rating {
  id
  teamId
  criteriaId
  userId
  value
}
    `;
export const TeamDataWithRatingFragmentDoc = gql`
    fragment TeamDataWithRating on Team {
  ...TeamDataWithRatingState
  ratings {
    ...RatingData
  }
}
    ${TeamDataWithRatingStateFragmentDoc}
${RatingDataFragmentDoc}`;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  firstname
  lastname
  email
  role {
    id
    key
  }
  active
  createdAt
  updatedAt
  events {
    id
    name
  }
}
    `;
export const LoginDocument = gql`
    mutation login($data: LoginInput!) {
  login(data: $data) {
    accessToken
    refreshToken
    user {
      ...AuthUser
    }
  }
}
    ${AuthUserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($token: JWT!) {
  refreshToken(token: $token) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ConfirmMailDocument = gql`
    mutation confirmMail($token: String!) {
  confirmMail(token: $token)
}
    `;
export type ConfirmMailMutationFn = Apollo.MutationFunction<ConfirmMailMutation, ConfirmMailMutationVariables>;

/**
 * __useConfirmMailMutation__
 *
 * To run a mutation, you first call `useConfirmMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMailMutation, { data, loading, error }] = useConfirmMailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmMailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMailMutation, ConfirmMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMailMutation, ConfirmMailMutationVariables>(ConfirmMailDocument, options);
      }
export type ConfirmMailMutationHookResult = ReturnType<typeof useConfirmMailMutation>;
export type ConfirmMailMutationResult = Apollo.MutationResult<ConfirmMailMutation>;
export type ConfirmMailMutationOptions = Apollo.BaseMutationOptions<ConfirmMailMutation, ConfirmMailMutationVariables>;
export const CheckValidTokenDocument = gql`
    query checkValidToken($token: String!) {
  checkValidToken(token: $token)
}
    `;

/**
 * __useCheckValidTokenQuery__
 *
 * To run a query within a React component, call `useCheckValidTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckValidTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckValidTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckValidTokenQuery(baseOptions: Apollo.QueryHookOptions<CheckValidTokenQuery, CheckValidTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckValidTokenQuery, CheckValidTokenQueryVariables>(CheckValidTokenDocument, options);
      }
export function useCheckValidTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckValidTokenQuery, CheckValidTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckValidTokenQuery, CheckValidTokenQueryVariables>(CheckValidTokenDocument, options);
        }
export type CheckValidTokenQueryHookResult = ReturnType<typeof useCheckValidTokenQuery>;
export type CheckValidTokenLazyQueryHookResult = ReturnType<typeof useCheckValidTokenLazyQuery>;
export type CheckValidTokenQueryResult = Apollo.QueryResult<CheckValidTokenQuery, CheckValidTokenQueryVariables>;
export const ChallengeDocument = gql`
    query challenge($id: String!) {
  challenge(id: $id) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;

/**
 * __useChallengeQuery__
 *
 * To run a query within a React component, call `useChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChallengeQuery(baseOptions: Apollo.QueryHookOptions<ChallengeQuery, ChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengeQuery, ChallengeQueryVariables>(ChallengeDocument, options);
      }
export function useChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengeQuery, ChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengeQuery, ChallengeQueryVariables>(ChallengeDocument, options);
        }
export type ChallengeQueryHookResult = ReturnType<typeof useChallengeQuery>;
export type ChallengeLazyQueryHookResult = ReturnType<typeof useChallengeLazyQuery>;
export type ChallengeQueryResult = Apollo.QueryResult<ChallengeQuery, ChallengeQueryVariables>;
export const ChallengesDocument = gql`
    query challenges($eventId: String) {
  challenges(eventId: $eventId) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;

/**
 * __useChallengesQuery__
 *
 * To run a query within a React component, call `useChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useChallengesQuery(baseOptions?: Apollo.QueryHookOptions<ChallengesQuery, ChallengesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengesQuery, ChallengesQueryVariables>(ChallengesDocument, options);
      }
export function useChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengesQuery, ChallengesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengesQuery, ChallengesQueryVariables>(ChallengesDocument, options);
        }
export type ChallengesQueryHookResult = ReturnType<typeof useChallengesQuery>;
export type ChallengesLazyQueryHookResult = ReturnType<typeof useChallengesLazyQuery>;
export type ChallengesQueryResult = Apollo.QueryResult<ChallengesQuery, ChallengesQueryVariables>;
export const CreateChallengeDocument = gql`
    mutation createChallenge($data: CreateChallengeInput!) {
  createChallenge(data: $data) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;
export type CreateChallengeMutationFn = Apollo.MutationFunction<CreateChallengeMutation, CreateChallengeMutationVariables>;

/**
 * __useCreateChallengeMutation__
 *
 * To run a mutation, you first call `useCreateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChallengeMutation, { data, loading, error }] = useCreateChallengeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChallengeMutation(baseOptions?: Apollo.MutationHookOptions<CreateChallengeMutation, CreateChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChallengeMutation, CreateChallengeMutationVariables>(CreateChallengeDocument, options);
      }
export type CreateChallengeMutationHookResult = ReturnType<typeof useCreateChallengeMutation>;
export type CreateChallengeMutationResult = Apollo.MutationResult<CreateChallengeMutation>;
export type CreateChallengeMutationOptions = Apollo.BaseMutationOptions<CreateChallengeMutation, CreateChallengeMutationVariables>;
export const UpdateChallengeDocument = gql`
    mutation updateChallenge($id: String!, $data: UpdateChallengeInput!) {
  updateChallenge(id: $id, data: $data) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;
export type UpdateChallengeMutationFn = Apollo.MutationFunction<UpdateChallengeMutation, UpdateChallengeMutationVariables>;

/**
 * __useUpdateChallengeMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeMutation, { data, loading, error }] = useUpdateChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChallengeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChallengeMutation, UpdateChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChallengeMutation, UpdateChallengeMutationVariables>(UpdateChallengeDocument, options);
      }
export type UpdateChallengeMutationHookResult = ReturnType<typeof useUpdateChallengeMutation>;
export type UpdateChallengeMutationResult = Apollo.MutationResult<UpdateChallengeMutation>;
export type UpdateChallengeMutationOptions = Apollo.BaseMutationOptions<UpdateChallengeMutation, UpdateChallengeMutationVariables>;
export const ReorderChallengesDocument = gql`
    mutation reorderChallenges($eventId: String!, $order: [String!]!) {
  reorderChallenges(eventId: $eventId, order: $order) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;
export type ReorderChallengesMutationFn = Apollo.MutationFunction<ReorderChallengesMutation, ReorderChallengesMutationVariables>;

/**
 * __useReorderChallengesMutation__
 *
 * To run a mutation, you first call `useReorderChallengesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderChallengesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderChallengesMutation, { data, loading, error }] = useReorderChallengesMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderChallengesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderChallengesMutation, ReorderChallengesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderChallengesMutation, ReorderChallengesMutationVariables>(ReorderChallengesDocument, options);
      }
export type ReorderChallengesMutationHookResult = ReturnType<typeof useReorderChallengesMutation>;
export type ReorderChallengesMutationResult = Apollo.MutationResult<ReorderChallengesMutation>;
export type ReorderChallengesMutationOptions = Apollo.BaseMutationOptions<ReorderChallengesMutation, ReorderChallengesMutationVariables>;
export const DeleteChallengeDocument = gql`
    mutation deleteChallenge($id: String!) {
  deleteChallenge(id: $id) {
    ...ChallengeData
  }
}
    ${ChallengeDataFragmentDoc}`;
export type DeleteChallengeMutationFn = Apollo.MutationFunction<DeleteChallengeMutation, DeleteChallengeMutationVariables>;

/**
 * __useDeleteChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeMutation, { data, loading, error }] = useDeleteChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChallengeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChallengeMutation, DeleteChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChallengeMutation, DeleteChallengeMutationVariables>(DeleteChallengeDocument, options);
      }
export type DeleteChallengeMutationHookResult = ReturnType<typeof useDeleteChallengeMutation>;
export type DeleteChallengeMutationResult = Apollo.MutationResult<DeleteChallengeMutation>;
export type DeleteChallengeMutationOptions = Apollo.BaseMutationOptions<DeleteChallengeMutation, DeleteChallengeMutationVariables>;
export const ActiveEventDocument = gql`
    query activeEvent {
  activeEvent {
    ...EventData
    teams {
      ...TeamDataMinimal
    }
  }
}
    ${EventDataFragmentDoc}
${TeamDataMinimalFragmentDoc}`;

/**
 * __useActiveEventQuery__
 *
 * To run a query within a React component, call `useActiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveEventQuery(baseOptions?: Apollo.QueryHookOptions<ActiveEventQuery, ActiveEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveEventQuery, ActiveEventQueryVariables>(ActiveEventDocument, options);
      }
export function useActiveEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveEventQuery, ActiveEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveEventQuery, ActiveEventQueryVariables>(ActiveEventDocument, options);
        }
export type ActiveEventQueryHookResult = ReturnType<typeof useActiveEventQuery>;
export type ActiveEventLazyQueryHookResult = ReturnType<typeof useActiveEventLazyQuery>;
export type ActiveEventQueryResult = Apollo.QueryResult<ActiveEventQuery, ActiveEventQueryVariables>;
export const ActiveEventWithSchemeDocument = gql`
    query activeEventWithScheme {
  activeEvent {
    ...EventData
    scheme {
      ...SchemeData
    }
  }
}
    ${EventDataFragmentDoc}
${SchemeDataFragmentDoc}`;

/**
 * __useActiveEventWithSchemeQuery__
 *
 * To run a query within a React component, call `useActiveEventWithSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEventWithSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEventWithSchemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveEventWithSchemeQuery(baseOptions?: Apollo.QueryHookOptions<ActiveEventWithSchemeQuery, ActiveEventWithSchemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveEventWithSchemeQuery, ActiveEventWithSchemeQueryVariables>(ActiveEventWithSchemeDocument, options);
      }
export function useActiveEventWithSchemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveEventWithSchemeQuery, ActiveEventWithSchemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveEventWithSchemeQuery, ActiveEventWithSchemeQueryVariables>(ActiveEventWithSchemeDocument, options);
        }
export type ActiveEventWithSchemeQueryHookResult = ReturnType<typeof useActiveEventWithSchemeQuery>;
export type ActiveEventWithSchemeLazyQueryHookResult = ReturnType<typeof useActiveEventWithSchemeLazyQuery>;
export type ActiveEventWithSchemeQueryResult = Apollo.QueryResult<ActiveEventWithSchemeQuery, ActiveEventWithSchemeQueryVariables>;
export const ActiveEventForJuryDocument = gql`
    query activeEventForJury {
  activeEvent {
    ...EventData
    teams {
      id
      name
      ratingState
    }
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useActiveEventForJuryQuery__
 *
 * To run a query within a React component, call `useActiveEventForJuryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEventForJuryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEventForJuryQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveEventForJuryQuery(baseOptions?: Apollo.QueryHookOptions<ActiveEventForJuryQuery, ActiveEventForJuryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveEventForJuryQuery, ActiveEventForJuryQueryVariables>(ActiveEventForJuryDocument, options);
      }
export function useActiveEventForJuryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveEventForJuryQuery, ActiveEventForJuryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveEventForJuryQuery, ActiveEventForJuryQueryVariables>(ActiveEventForJuryDocument, options);
        }
export type ActiveEventForJuryQueryHookResult = ReturnType<typeof useActiveEventForJuryQuery>;
export type ActiveEventForJuryLazyQueryHookResult = ReturnType<typeof useActiveEventForJuryLazyQuery>;
export type ActiveEventForJuryQueryResult = Apollo.QueryResult<ActiveEventForJuryQuery, ActiveEventForJuryQueryVariables>;
export const ActiveEventWithFeedbackDocument = gql`
    query activeEventWithFeedback {
  activeEvent {
    ...EventData
    feedbackQuestions {
      ...FeedbackData
    }
  }
}
    ${EventDataFragmentDoc}
${FeedbackDataFragmentDoc}`;

/**
 * __useActiveEventWithFeedbackQuery__
 *
 * To run a query within a React component, call `useActiveEventWithFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEventWithFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEventWithFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveEventWithFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<ActiveEventWithFeedbackQuery, ActiveEventWithFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveEventWithFeedbackQuery, ActiveEventWithFeedbackQueryVariables>(ActiveEventWithFeedbackDocument, options);
      }
export function useActiveEventWithFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveEventWithFeedbackQuery, ActiveEventWithFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveEventWithFeedbackQuery, ActiveEventWithFeedbackQueryVariables>(ActiveEventWithFeedbackDocument, options);
        }
export type ActiveEventWithFeedbackQueryHookResult = ReturnType<typeof useActiveEventWithFeedbackQuery>;
export type ActiveEventWithFeedbackLazyQueryHookResult = ReturnType<typeof useActiveEventWithFeedbackLazyQuery>;
export type ActiveEventWithFeedbackQueryResult = Apollo.QueryResult<ActiveEventWithFeedbackQuery, ActiveEventWithFeedbackQueryVariables>;
export const EventDocument = gql`
    query event($id: String!) {
  event(id: $id) {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query events {
  events {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const EventMinimalDocument = gql`
    query eventMinimal($id: String!) {
  event(id: $id) {
    ...EventMinimalData
  }
}
    ${EventMinimalDataFragmentDoc}`;

/**
 * __useEventMinimalQuery__
 *
 * To run a query within a React component, call `useEventMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMinimalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventMinimalQuery(baseOptions: Apollo.QueryHookOptions<EventMinimalQuery, EventMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventMinimalQuery, EventMinimalQueryVariables>(EventMinimalDocument, options);
      }
export function useEventMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventMinimalQuery, EventMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventMinimalQuery, EventMinimalQueryVariables>(EventMinimalDocument, options);
        }
export type EventMinimalQueryHookResult = ReturnType<typeof useEventMinimalQuery>;
export type EventMinimalLazyQueryHookResult = ReturnType<typeof useEventMinimalLazyQuery>;
export type EventMinimalQueryResult = Apollo.QueryResult<EventMinimalQuery, EventMinimalQueryVariables>;
export const EventsMinimalDocument = gql`
    query eventsMinimal {
  events {
    ...EventMinimalData
  }
}
    ${EventMinimalDataFragmentDoc}`;

/**
 * __useEventsMinimalQuery__
 *
 * To run a query within a React component, call `useEventsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsMinimalQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<EventsMinimalQuery, EventsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsMinimalQuery, EventsMinimalQueryVariables>(EventsMinimalDocument, options);
      }
export function useEventsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsMinimalQuery, EventsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsMinimalQuery, EventsMinimalQueryVariables>(EventsMinimalDocument, options);
        }
export type EventsMinimalQueryHookResult = ReturnType<typeof useEventsMinimalQuery>;
export type EventsMinimalLazyQueryHookResult = ReturnType<typeof useEventsMinimalLazyQuery>;
export type EventsMinimalQueryResult = Apollo.QueryResult<EventsMinimalQuery, EventsMinimalQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($data: CreateEventInput!) {
  createEvent(data: $data) {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $data: UpdateEventInput!) {
  updateEvent(id: $id, data: $data) {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateActiveEventDocument = gql`
    mutation updateActiveEvent($id: String!, $active: Boolean!) {
  updateActiveEvent(id: $id, active: $active) {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;
export type UpdateActiveEventMutationFn = Apollo.MutationFunction<UpdateActiveEventMutation, UpdateActiveEventMutationVariables>;

/**
 * __useUpdateActiveEventMutation__
 *
 * To run a mutation, you first call `useUpdateActiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveEventMutation, { data, loading, error }] = useUpdateActiveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateActiveEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveEventMutation, UpdateActiveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActiveEventMutation, UpdateActiveEventMutationVariables>(UpdateActiveEventDocument, options);
      }
export type UpdateActiveEventMutationHookResult = ReturnType<typeof useUpdateActiveEventMutation>;
export type UpdateActiveEventMutationResult = Apollo.MutationResult<UpdateActiveEventMutation>;
export type UpdateActiveEventMutationOptions = Apollo.BaseMutationOptions<UpdateActiveEventMutation, UpdateActiveEventMutationVariables>;
export const SoftDeleteEventDocument = gql`
    mutation softDeleteEvent($id: String!) {
  softDeleteEvent(id: $id) {
    ...EventData
  }
}
    ${EventDataFragmentDoc}`;
export type SoftDeleteEventMutationFn = Apollo.MutationFunction<SoftDeleteEventMutation, SoftDeleteEventMutationVariables>;

/**
 * __useSoftDeleteEventMutation__
 *
 * To run a mutation, you first call `useSoftDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteEventMutation, { data, loading, error }] = useSoftDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteEventMutation, SoftDeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteEventMutation, SoftDeleteEventMutationVariables>(SoftDeleteEventDocument, options);
      }
export type SoftDeleteEventMutationHookResult = ReturnType<typeof useSoftDeleteEventMutation>;
export type SoftDeleteEventMutationResult = Apollo.MutationResult<SoftDeleteEventMutation>;
export type SoftDeleteEventMutationOptions = Apollo.BaseMutationOptions<SoftDeleteEventMutation, SoftDeleteEventMutationVariables>;
export const CreateFeedbackAnswerDocument = gql`
    mutation createFeedbackAnswer($data: [CreateFeedbackAnswerInput!]!) {
  createFeedbackAnswer(data: $data) {
    ...FeedbackAnswer
  }
}
    ${FeedbackAnswerFragmentDoc}`;
export type CreateFeedbackAnswerMutationFn = Apollo.MutationFunction<CreateFeedbackAnswerMutation, CreateFeedbackAnswerMutationVariables>;

/**
 * __useCreateFeedbackAnswerMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackAnswerMutation, { data, loading, error }] = useCreateFeedbackAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeedbackAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackAnswerMutation, CreateFeedbackAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackAnswerMutation, CreateFeedbackAnswerMutationVariables>(CreateFeedbackAnswerDocument, options);
      }
export type CreateFeedbackAnswerMutationHookResult = ReturnType<typeof useCreateFeedbackAnswerMutation>;
export type CreateFeedbackAnswerMutationResult = Apollo.MutationResult<CreateFeedbackAnswerMutation>;
export type CreateFeedbackAnswerMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackAnswerMutation, CreateFeedbackAnswerMutationVariables>;
export const DeleteFeedbackAnswersDocument = gql`
    mutation deleteFeedbackAnswers($eventId: String!) {
  deleteFeedbackAnswers(eventId: $eventId)
}
    `;
export type DeleteFeedbackAnswersMutationFn = Apollo.MutationFunction<DeleteFeedbackAnswersMutation, DeleteFeedbackAnswersMutationVariables>;

/**
 * __useDeleteFeedbackAnswersMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackAnswersMutation, { data, loading, error }] = useDeleteFeedbackAnswersMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteFeedbackAnswersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackAnswersMutation, DeleteFeedbackAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackAnswersMutation, DeleteFeedbackAnswersMutationVariables>(DeleteFeedbackAnswersDocument, options);
      }
export type DeleteFeedbackAnswersMutationHookResult = ReturnType<typeof useDeleteFeedbackAnswersMutation>;
export type DeleteFeedbackAnswersMutationResult = Apollo.MutationResult<DeleteFeedbackAnswersMutation>;
export type DeleteFeedbackAnswersMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackAnswersMutation, DeleteFeedbackAnswersMutationVariables>;
export const FeedbackQuestionsDocument = gql`
    query feedbackQuestions($eventId: String!) {
  feedbackQuestions(eventId: $eventId) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;

/**
 * __useFeedbackQuestionsQuery__
 *
 * To run a query within a React component, call `useFeedbackQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackQuestionsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useFeedbackQuestionsQuery(baseOptions: Apollo.QueryHookOptions<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>(FeedbackQuestionsDocument, options);
      }
export function useFeedbackQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>(FeedbackQuestionsDocument, options);
        }
export type FeedbackQuestionsQueryHookResult = ReturnType<typeof useFeedbackQuestionsQuery>;
export type FeedbackQuestionsLazyQueryHookResult = ReturnType<typeof useFeedbackQuestionsLazyQuery>;
export type FeedbackQuestionsQueryResult = Apollo.QueryResult<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>;
export const FeedbackQuestionsForEvaluationDocument = gql`
    query feedbackQuestionsForEvaluation($eventId: String!) {
  feedbackQuestions(eventId: $eventId) {
    ...FeedbackData
    feedbackAnswers {
      ...FeedbackAnswer
    }
  }
}
    ${FeedbackDataFragmentDoc}
${FeedbackAnswerFragmentDoc}`;

/**
 * __useFeedbackQuestionsForEvaluationQuery__
 *
 * To run a query within a React component, call `useFeedbackQuestionsForEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackQuestionsForEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackQuestionsForEvaluationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useFeedbackQuestionsForEvaluationQuery(baseOptions: Apollo.QueryHookOptions<FeedbackQuestionsForEvaluationQuery, FeedbackQuestionsForEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackQuestionsForEvaluationQuery, FeedbackQuestionsForEvaluationQueryVariables>(FeedbackQuestionsForEvaluationDocument, options);
      }
export function useFeedbackQuestionsForEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackQuestionsForEvaluationQuery, FeedbackQuestionsForEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackQuestionsForEvaluationQuery, FeedbackQuestionsForEvaluationQueryVariables>(FeedbackQuestionsForEvaluationDocument, options);
        }
export type FeedbackQuestionsForEvaluationQueryHookResult = ReturnType<typeof useFeedbackQuestionsForEvaluationQuery>;
export type FeedbackQuestionsForEvaluationLazyQueryHookResult = ReturnType<typeof useFeedbackQuestionsForEvaluationLazyQuery>;
export type FeedbackQuestionsForEvaluationQueryResult = Apollo.QueryResult<FeedbackQuestionsForEvaluationQuery, FeedbackQuestionsForEvaluationQueryVariables>;
export const CreateFeedbackQuestionDocument = gql`
    mutation createFeedbackQuestion($data: CreateFeedbackQuestionInput!) {
  createFeedbackQuestion(data: $data) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type CreateFeedbackQuestionMutationFn = Apollo.MutationFunction<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>;

/**
 * __useCreateFeedbackQuestionMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackQuestionMutation, { data, loading, error }] = useCreateFeedbackQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeedbackQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>(CreateFeedbackQuestionDocument, options);
      }
export type CreateFeedbackQuestionMutationHookResult = ReturnType<typeof useCreateFeedbackQuestionMutation>;
export type CreateFeedbackQuestionMutationResult = Apollo.MutationResult<CreateFeedbackQuestionMutation>;
export type CreateFeedbackQuestionMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackQuestionMutation, CreateFeedbackQuestionMutationVariables>;
export const UpdateFeedbackQuestionDocument = gql`
    mutation updateFeedbackQuestion($id: String!, $data: UpdateFeedbackQuestionInput!) {
  updateFeedbackQuestion(id: $id, data: $data) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type UpdateFeedbackQuestionMutationFn = Apollo.MutationFunction<UpdateFeedbackQuestionMutation, UpdateFeedbackQuestionMutationVariables>;

/**
 * __useUpdateFeedbackQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackQuestionMutation, { data, loading, error }] = useUpdateFeedbackQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFeedbackQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackQuestionMutation, UpdateFeedbackQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedbackQuestionMutation, UpdateFeedbackQuestionMutationVariables>(UpdateFeedbackQuestionDocument, options);
      }
export type UpdateFeedbackQuestionMutationHookResult = ReturnType<typeof useUpdateFeedbackQuestionMutation>;
export type UpdateFeedbackQuestionMutationResult = Apollo.MutationResult<UpdateFeedbackQuestionMutation>;
export type UpdateFeedbackQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackQuestionMutation, UpdateFeedbackQuestionMutationVariables>;
export const ReorderFeedbackQuestionsDocument = gql`
    mutation reorderFeedbackQuestions($eventId: String!, $order: [String!]!) {
  reorderFeedbackQuestions(eventId: $eventId, order: $order) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type ReorderFeedbackQuestionsMutationFn = Apollo.MutationFunction<ReorderFeedbackQuestionsMutation, ReorderFeedbackQuestionsMutationVariables>;

/**
 * __useReorderFeedbackQuestionsMutation__
 *
 * To run a mutation, you first call `useReorderFeedbackQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderFeedbackQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderFeedbackQuestionsMutation, { data, loading, error }] = useReorderFeedbackQuestionsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderFeedbackQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderFeedbackQuestionsMutation, ReorderFeedbackQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderFeedbackQuestionsMutation, ReorderFeedbackQuestionsMutationVariables>(ReorderFeedbackQuestionsDocument, options);
      }
export type ReorderFeedbackQuestionsMutationHookResult = ReturnType<typeof useReorderFeedbackQuestionsMutation>;
export type ReorderFeedbackQuestionsMutationResult = Apollo.MutationResult<ReorderFeedbackQuestionsMutation>;
export type ReorderFeedbackQuestionsMutationOptions = Apollo.BaseMutationOptions<ReorderFeedbackQuestionsMutation, ReorderFeedbackQuestionsMutationVariables>;
export const DeleteFeedbackQuestionDocument = gql`
    mutation deleteFeedbackQuestion($id: String!) {
  deleteFeedbackQuestion(id: $id) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type DeleteFeedbackQuestionMutationFn = Apollo.MutationFunction<DeleteFeedbackQuestionMutation, DeleteFeedbackQuestionMutationVariables>;

/**
 * __useDeleteFeedbackQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackQuestionMutation, { data, loading, error }] = useDeleteFeedbackQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedbackQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackQuestionMutation, DeleteFeedbackQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackQuestionMutation, DeleteFeedbackQuestionMutationVariables>(DeleteFeedbackQuestionDocument, options);
      }
export type DeleteFeedbackQuestionMutationHookResult = ReturnType<typeof useDeleteFeedbackQuestionMutation>;
export type DeleteFeedbackQuestionMutationResult = Apollo.MutationResult<DeleteFeedbackQuestionMutation>;
export type DeleteFeedbackQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackQuestionMutation, DeleteFeedbackQuestionMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($data: UploadFileInput!, $file: Upload!) {
  uploadFile(data: $data, file: $file) {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UpdateFileDocument = gql`
    mutation updateFile($data: UpdateFileInput!, $id: String!) {
  updateFileInfo(data: $data, id: $id) {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export type UpdateFileMutationFn = Apollo.MutationFunction<UpdateFileMutation, UpdateFileMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileMutation, UpdateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(UpdateFileDocument, options);
      }
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<UpdateFileMutation, UpdateFileMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: String!) {
  deleteFile(id: $id) {
    id
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const DeleteFilesDocument = gql`
    mutation deleteFiles($ids: [String!]!) {
  deleteFiles(ids: $ids)
}
    `;
export type DeleteFilesMutationFn = Apollo.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>;

/**
 * __useDeleteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilesMutation, { data, loading, error }] = useDeleteFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFilesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilesMutation, DeleteFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilesMutation, DeleteFilesMutationVariables>(DeleteFilesDocument, options);
      }
export type DeleteFilesMutationHookResult = ReturnType<typeof useDeleteFilesMutation>;
export type DeleteFilesMutationResult = Apollo.MutationResult<DeleteFilesMutation>;
export type DeleteFilesMutationOptions = Apollo.BaseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const CreateOrUpdateRatingsDocument = gql`
    mutation createOrUpdateRatings($data: [CreateRatingInput!]!) {
  createOrUpdateRatings(data: $data) {
    ...RatingData
  }
}
    ${RatingDataFragmentDoc}`;
export type CreateOrUpdateRatingsMutationFn = Apollo.MutationFunction<CreateOrUpdateRatingsMutation, CreateOrUpdateRatingsMutationVariables>;

/**
 * __useCreateOrUpdateRatingsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateRatingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateRatingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateRatingsMutation, { data, loading, error }] = useCreateOrUpdateRatingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateRatingsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateRatingsMutation, CreateOrUpdateRatingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateRatingsMutation, CreateOrUpdateRatingsMutationVariables>(CreateOrUpdateRatingsDocument, options);
      }
export type CreateOrUpdateRatingsMutationHookResult = ReturnType<typeof useCreateOrUpdateRatingsMutation>;
export type CreateOrUpdateRatingsMutationResult = Apollo.MutationResult<CreateOrUpdateRatingsMutation>;
export type CreateOrUpdateRatingsMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateRatingsMutation, CreateOrUpdateRatingsMutationVariables>;
export const DeleteRatingsDocument = gql`
    mutation deleteRatings($eventId: String!) {
  deleteRatings(eventId: $eventId)
}
    `;
export type DeleteRatingsMutationFn = Apollo.MutationFunction<DeleteRatingsMutation, DeleteRatingsMutationVariables>;

/**
 * __useDeleteRatingsMutation__
 *
 * To run a mutation, you first call `useDeleteRatingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRatingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRatingsMutation, { data, loading, error }] = useDeleteRatingsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteRatingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRatingsMutation, DeleteRatingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRatingsMutation, DeleteRatingsMutationVariables>(DeleteRatingsDocument, options);
      }
export type DeleteRatingsMutationHookResult = ReturnType<typeof useDeleteRatingsMutation>;
export type DeleteRatingsMutationResult = Apollo.MutationResult<DeleteRatingsMutation>;
export type DeleteRatingsMutationOptions = Apollo.BaseMutationOptions<DeleteRatingsMutation, DeleteRatingsMutationVariables>;
export const RatingsUpdatedDocument = gql`
    subscription ratingsUpdated {
  ratingsUpdated {
    ...RatingData
  }
}
    ${RatingDataFragmentDoc}`;

/**
 * __useRatingsUpdatedSubscription__
 *
 * To run a query within a React component, call `useRatingsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRatingsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRatingsUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RatingsUpdatedSubscription, RatingsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RatingsUpdatedSubscription, RatingsUpdatedSubscriptionVariables>(RatingsUpdatedDocument, options);
      }
export type RatingsUpdatedSubscriptionHookResult = ReturnType<typeof useRatingsUpdatedSubscription>;
export type RatingsUpdatedSubscriptionResult = Apollo.SubscriptionResult<RatingsUpdatedSubscription>;
export const RolesDocument = gql`
    query roles {
  roles {
    ...RolesMinimal
  }
}
    ${RolesMinimalFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SchemeByEventDocument = gql`
    query schemeByEvent($eventId: String!) {
  schemeByEvent(eventId: $eventId) {
    ...SchemeData
  }
}
    ${SchemeDataFragmentDoc}`;

/**
 * __useSchemeByEventQuery__
 *
 * To run a query within a React component, call `useSchemeByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSchemeByEventQuery(baseOptions: Apollo.QueryHookOptions<SchemeByEventQuery, SchemeByEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemeByEventQuery, SchemeByEventQueryVariables>(SchemeByEventDocument, options);
      }
export function useSchemeByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemeByEventQuery, SchemeByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemeByEventQuery, SchemeByEventQueryVariables>(SchemeByEventDocument, options);
        }
export type SchemeByEventQueryHookResult = ReturnType<typeof useSchemeByEventQuery>;
export type SchemeByEventLazyQueryHookResult = ReturnType<typeof useSchemeByEventLazyQuery>;
export type SchemeByEventQueryResult = Apollo.QueryResult<SchemeByEventQuery, SchemeByEventQueryVariables>;
export const SchemeByEventForCrudDocument = gql`
    query schemeByEventForCRUD($eventId: String!) {
  schemeByEvent(eventId: $eventId) {
    ...SchemeDataMinimal
    schemeCriteria {
      ...SchemeCriteriaWithRoleData
      children {
        ...SchemeCriteriaWithRoleData
      }
    }
  }
}
    ${SchemeDataMinimalFragmentDoc}
${SchemeCriteriaWithRoleDataFragmentDoc}`;

/**
 * __useSchemeByEventForCrudQuery__
 *
 * To run a query within a React component, call `useSchemeByEventForCrudQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeByEventForCrudQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeByEventForCrudQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSchemeByEventForCrudQuery(baseOptions: Apollo.QueryHookOptions<SchemeByEventForCrudQuery, SchemeByEventForCrudQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemeByEventForCrudQuery, SchemeByEventForCrudQueryVariables>(SchemeByEventForCrudDocument, options);
      }
export function useSchemeByEventForCrudLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemeByEventForCrudQuery, SchemeByEventForCrudQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemeByEventForCrudQuery, SchemeByEventForCrudQueryVariables>(SchemeByEventForCrudDocument, options);
        }
export type SchemeByEventForCrudQueryHookResult = ReturnType<typeof useSchemeByEventForCrudQuery>;
export type SchemeByEventForCrudLazyQueryHookResult = ReturnType<typeof useSchemeByEventForCrudLazyQuery>;
export type SchemeByEventForCrudQueryResult = Apollo.QueryResult<SchemeByEventForCrudQuery, SchemeByEventForCrudQueryVariables>;
export const SchemeByEventForEvaluationDocument = gql`
    query schemeByEventForEvaluation($eventId: String!) {
  schemeByEvent(eventId: $eventId) {
    ...SchemeDataMinimal
    schemeCriteria {
      ...SchemeCriteriaNestedData
    }
  }
}
    ${SchemeDataMinimalFragmentDoc}
${SchemeCriteriaNestedDataFragmentDoc}`;

/**
 * __useSchemeByEventForEvaluationQuery__
 *
 * To run a query within a React component, call `useSchemeByEventForEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeByEventForEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeByEventForEvaluationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSchemeByEventForEvaluationQuery(baseOptions: Apollo.QueryHookOptions<SchemeByEventForEvaluationQuery, SchemeByEventForEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchemeByEventForEvaluationQuery, SchemeByEventForEvaluationQueryVariables>(SchemeByEventForEvaluationDocument, options);
      }
export function useSchemeByEventForEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchemeByEventForEvaluationQuery, SchemeByEventForEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchemeByEventForEvaluationQuery, SchemeByEventForEvaluationQueryVariables>(SchemeByEventForEvaluationDocument, options);
        }
export type SchemeByEventForEvaluationQueryHookResult = ReturnType<typeof useSchemeByEventForEvaluationQuery>;
export type SchemeByEventForEvaluationLazyQueryHookResult = ReturnType<typeof useSchemeByEventForEvaluationLazyQuery>;
export type SchemeByEventForEvaluationQueryResult = Apollo.QueryResult<SchemeByEventForEvaluationQuery, SchemeByEventForEvaluationQueryVariables>;
export const CreateSchemeDocument = gql`
    mutation createScheme($data: CreateSchemeInput!) {
  createScheme(data: $data) {
    ...SchemeDataMinimal
  }
}
    ${SchemeDataMinimalFragmentDoc}`;
export type CreateSchemeMutationFn = Apollo.MutationFunction<CreateSchemeMutation, CreateSchemeMutationVariables>;

/**
 * __useCreateSchemeMutation__
 *
 * To run a mutation, you first call `useCreateSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchemeMutation, { data, loading, error }] = useCreateSchemeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchemeMutation, CreateSchemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchemeMutation, CreateSchemeMutationVariables>(CreateSchemeDocument, options);
      }
export type CreateSchemeMutationHookResult = ReturnType<typeof useCreateSchemeMutation>;
export type CreateSchemeMutationResult = Apollo.MutationResult<CreateSchemeMutation>;
export type CreateSchemeMutationOptions = Apollo.BaseMutationOptions<CreateSchemeMutation, CreateSchemeMutationVariables>;
export const UpdateSchemeDocument = gql`
    mutation updateScheme($id: String!, $data: UpdateSchemeInput!) {
  updateScheme(id: $id, data: $data) {
    ...SchemeDataMinimal
  }
}
    ${SchemeDataMinimalFragmentDoc}`;
export type UpdateSchemeMutationFn = Apollo.MutationFunction<UpdateSchemeMutation, UpdateSchemeMutationVariables>;

/**
 * __useUpdateSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemeMutation, { data, loading, error }] = useUpdateSchemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemeMutation, UpdateSchemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemeMutation, UpdateSchemeMutationVariables>(UpdateSchemeDocument, options);
      }
export type UpdateSchemeMutationHookResult = ReturnType<typeof useUpdateSchemeMutation>;
export type UpdateSchemeMutationResult = Apollo.MutationResult<UpdateSchemeMutation>;
export type UpdateSchemeMutationOptions = Apollo.BaseMutationOptions<UpdateSchemeMutation, UpdateSchemeMutationVariables>;
export const DeleteSchemeDocument = gql`
    mutation deleteScheme($id: String!) {
  deleteScheme(id: $id) {
    id
  }
}
    `;
export type DeleteSchemeMutationFn = Apollo.MutationFunction<DeleteSchemeMutation, DeleteSchemeMutationVariables>;

/**
 * __useDeleteSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemeMutation, { data, loading, error }] = useDeleteSchemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemeMutation, DeleteSchemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemeMutation, DeleteSchemeMutationVariables>(DeleteSchemeDocument, options);
      }
export type DeleteSchemeMutationHookResult = ReturnType<typeof useDeleteSchemeMutation>;
export type DeleteSchemeMutationResult = Apollo.MutationResult<DeleteSchemeMutation>;
export type DeleteSchemeMutationOptions = Apollo.BaseMutationOptions<DeleteSchemeMutation, DeleteSchemeMutationVariables>;
export const CreateSchemeCriteriaDocument = gql`
    mutation createSchemeCriteria($data: CreateSchemeCriteriaInput!) {
  createSchemeCriteria(data: $data) {
    ...SchemeCriteriaWithRoleData
  }
}
    ${SchemeCriteriaWithRoleDataFragmentDoc}`;
export type CreateSchemeCriteriaMutationFn = Apollo.MutationFunction<CreateSchemeCriteriaMutation, CreateSchemeCriteriaMutationVariables>;

/**
 * __useCreateSchemeCriteriaMutation__
 *
 * To run a mutation, you first call `useCreateSchemeCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchemeCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchemeCriteriaMutation, { data, loading, error }] = useCreateSchemeCriteriaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchemeCriteriaMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchemeCriteriaMutation, CreateSchemeCriteriaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchemeCriteriaMutation, CreateSchemeCriteriaMutationVariables>(CreateSchemeCriteriaDocument, options);
      }
export type CreateSchemeCriteriaMutationHookResult = ReturnType<typeof useCreateSchemeCriteriaMutation>;
export type CreateSchemeCriteriaMutationResult = Apollo.MutationResult<CreateSchemeCriteriaMutation>;
export type CreateSchemeCriteriaMutationOptions = Apollo.BaseMutationOptions<CreateSchemeCriteriaMutation, CreateSchemeCriteriaMutationVariables>;
export const UpdateSchemeCriteriaDocument = gql`
    mutation updateSchemeCriteria($id: String!, $data: UpdateSchemeCriteriaInput!) {
  updateSchemeCriteria(id: $id, data: $data) {
    ...SchemeCriteriaWithRoleData
  }
}
    ${SchemeCriteriaWithRoleDataFragmentDoc}`;
export type UpdateSchemeCriteriaMutationFn = Apollo.MutationFunction<UpdateSchemeCriteriaMutation, UpdateSchemeCriteriaMutationVariables>;

/**
 * __useUpdateSchemeCriteriaMutation__
 *
 * To run a mutation, you first call `useUpdateSchemeCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemeCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemeCriteriaMutation, { data, loading, error }] = useUpdateSchemeCriteriaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchemeCriteriaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemeCriteriaMutation, UpdateSchemeCriteriaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemeCriteriaMutation, UpdateSchemeCriteriaMutationVariables>(UpdateSchemeCriteriaDocument, options);
      }
export type UpdateSchemeCriteriaMutationHookResult = ReturnType<typeof useUpdateSchemeCriteriaMutation>;
export type UpdateSchemeCriteriaMutationResult = Apollo.MutationResult<UpdateSchemeCriteriaMutation>;
export type UpdateSchemeCriteriaMutationOptions = Apollo.BaseMutationOptions<UpdateSchemeCriteriaMutation, UpdateSchemeCriteriaMutationVariables>;
export const DeleteSchemeCriteriaDocument = gql`
    mutation deleteSchemeCriteria($id: String!) {
  deleteSchemeCriteria(id: $id) {
    ...SchemeCriteriaWithRoleData
  }
}
    ${SchemeCriteriaWithRoleDataFragmentDoc}`;
export type DeleteSchemeCriteriaMutationFn = Apollo.MutationFunction<DeleteSchemeCriteriaMutation, DeleteSchemeCriteriaMutationVariables>;

/**
 * __useDeleteSchemeCriteriaMutation__
 *
 * To run a mutation, you first call `useDeleteSchemeCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemeCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemeCriteriaMutation, { data, loading, error }] = useDeleteSchemeCriteriaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemeCriteriaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemeCriteriaMutation, DeleteSchemeCriteriaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemeCriteriaMutation, DeleteSchemeCriteriaMutationVariables>(DeleteSchemeCriteriaDocument, options);
      }
export type DeleteSchemeCriteriaMutationHookResult = ReturnType<typeof useDeleteSchemeCriteriaMutation>;
export type DeleteSchemeCriteriaMutationResult = Apollo.MutationResult<DeleteSchemeCriteriaMutation>;
export type DeleteSchemeCriteriaMutationOptions = Apollo.BaseMutationOptions<DeleteSchemeCriteriaMutation, DeleteSchemeCriteriaMutationVariables>;
export const TeamResultsDocument = gql`
    query teamResults($eventId: String) {
  teamResults(eventId: $eventId) {
    ...TeamResultData
  }
}
    ${TeamResultDataFragmentDoc}`;

/**
 * __useTeamResultsQuery__
 *
 * To run a query within a React component, call `useTeamResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamResultsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useTeamResultsQuery(baseOptions?: Apollo.QueryHookOptions<TeamResultsQuery, TeamResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamResultsQuery, TeamResultsQueryVariables>(TeamResultsDocument, options);
      }
export function useTeamResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamResultsQuery, TeamResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamResultsQuery, TeamResultsQueryVariables>(TeamResultsDocument, options);
        }
export type TeamResultsQueryHookResult = ReturnType<typeof useTeamResultsQuery>;
export type TeamResultsLazyQueryHookResult = ReturnType<typeof useTeamResultsLazyQuery>;
export type TeamResultsQueryResult = Apollo.QueryResult<TeamResultsQuery, TeamResultsQueryVariables>;
export const TeamResultsWithTeamDocument = gql`
    query teamResultsWithTeam($eventId: String) {
  teamResults(eventId: $eventId) {
    ...TeamResultWithTeamData
  }
}
    ${TeamResultWithTeamDataFragmentDoc}`;

/**
 * __useTeamResultsWithTeamQuery__
 *
 * To run a query within a React component, call `useTeamResultsWithTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamResultsWithTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamResultsWithTeamQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useTeamResultsWithTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamResultsWithTeamQuery, TeamResultsWithTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamResultsWithTeamQuery, TeamResultsWithTeamQueryVariables>(TeamResultsWithTeamDocument, options);
      }
export function useTeamResultsWithTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamResultsWithTeamQuery, TeamResultsWithTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamResultsWithTeamQuery, TeamResultsWithTeamQueryVariables>(TeamResultsWithTeamDocument, options);
        }
export type TeamResultsWithTeamQueryHookResult = ReturnType<typeof useTeamResultsWithTeamQuery>;
export type TeamResultsWithTeamLazyQueryHookResult = ReturnType<typeof useTeamResultsWithTeamLazyQuery>;
export type TeamResultsWithTeamQueryResult = Apollo.QueryResult<TeamResultsWithTeamQuery, TeamResultsWithTeamQueryVariables>;
export const SubmitResultsDocument = gql`
    mutation submitResults($data: SubmitResultInput!) {
  submitResults(data: $data) {
    ...TeamResultData
  }
}
    ${TeamResultDataFragmentDoc}`;
export type SubmitResultsMutationFn = Apollo.MutationFunction<SubmitResultsMutation, SubmitResultsMutationVariables>;

/**
 * __useSubmitResultsMutation__
 *
 * To run a mutation, you first call `useSubmitResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitResultsMutation, { data, loading, error }] = useSubmitResultsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitResultsMutation(baseOptions?: Apollo.MutationHookOptions<SubmitResultsMutation, SubmitResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitResultsMutation, SubmitResultsMutationVariables>(SubmitResultsDocument, options);
      }
export type SubmitResultsMutationHookResult = ReturnType<typeof useSubmitResultsMutation>;
export type SubmitResultsMutationResult = Apollo.MutationResult<SubmitResultsMutation>;
export type SubmitResultsMutationOptions = Apollo.BaseMutationOptions<SubmitResultsMutation, SubmitResultsMutationVariables>;
export const CheckTeamValidDocument = gql`
    query checkTeamValid($id: String!) {
  checkTeamValid(id: $id)
}
    `;

/**
 * __useCheckTeamValidQuery__
 *
 * To run a query within a React component, call `useCheckTeamValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTeamValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTeamValidQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckTeamValidQuery(baseOptions: Apollo.QueryHookOptions<CheckTeamValidQuery, CheckTeamValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTeamValidQuery, CheckTeamValidQueryVariables>(CheckTeamValidDocument, options);
      }
export function useCheckTeamValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTeamValidQuery, CheckTeamValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTeamValidQuery, CheckTeamValidQueryVariables>(CheckTeamValidDocument, options);
        }
export type CheckTeamValidQueryHookResult = ReturnType<typeof useCheckTeamValidQuery>;
export type CheckTeamValidLazyQueryHookResult = ReturnType<typeof useCheckTeamValidLazyQuery>;
export type CheckTeamValidQueryResult = Apollo.QueryResult<CheckTeamValidQuery, CheckTeamValidQueryVariables>;
export const TeamActiveEventDocument = gql`
    query teamActiveEvent($id: String!) {
  teamActiveEvent(id: $id) {
    ...TeamDataMinimal
  }
}
    ${TeamDataMinimalFragmentDoc}`;

/**
 * __useTeamActiveEventQuery__
 *
 * To run a query within a React component, call `useTeamActiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamActiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamActiveEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamActiveEventQuery(baseOptions: Apollo.QueryHookOptions<TeamActiveEventQuery, TeamActiveEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamActiveEventQuery, TeamActiveEventQueryVariables>(TeamActiveEventDocument, options);
      }
export function useTeamActiveEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamActiveEventQuery, TeamActiveEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamActiveEventQuery, TeamActiveEventQueryVariables>(TeamActiveEventDocument, options);
        }
export type TeamActiveEventQueryHookResult = ReturnType<typeof useTeamActiveEventQuery>;
export type TeamActiveEventLazyQueryHookResult = ReturnType<typeof useTeamActiveEventLazyQuery>;
export type TeamActiveEventQueryResult = Apollo.QueryResult<TeamActiveEventQuery, TeamActiveEventQueryVariables>;
export const TeamsDocument = gql`
    query teams($eventId: String) {
  teams(eventId: $eventId) {
    ...TeamData
  }
}
    ${TeamDataFragmentDoc}`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const TeamWithRatingDocument = gql`
    query teamWithRating($id: String!, $own: Boolean) {
  team(id: $id) {
    ...TeamData
    ratings(own: $own) {
      ...RatingData
    }
  }
}
    ${TeamDataFragmentDoc}
${RatingDataFragmentDoc}`;

/**
 * __useTeamWithRatingQuery__
 *
 * To run a query within a React component, call `useTeamWithRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamWithRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamWithRatingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      own: // value for 'own'
 *   },
 * });
 */
export function useTeamWithRatingQuery(baseOptions: Apollo.QueryHookOptions<TeamWithRatingQuery, TeamWithRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamWithRatingQuery, TeamWithRatingQueryVariables>(TeamWithRatingDocument, options);
      }
export function useTeamWithRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamWithRatingQuery, TeamWithRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamWithRatingQuery, TeamWithRatingQueryVariables>(TeamWithRatingDocument, options);
        }
export type TeamWithRatingQueryHookResult = ReturnType<typeof useTeamWithRatingQuery>;
export type TeamWithRatingLazyQueryHookResult = ReturnType<typeof useTeamWithRatingLazyQuery>;
export type TeamWithRatingQueryResult = Apollo.QueryResult<TeamWithRatingQuery, TeamWithRatingQueryVariables>;
export const TeamsWithRatingDocument = gql`
    query teamsWithRating($eventId: String, $own: Boolean) {
  teams(eventId: $eventId) {
    ...TeamDataWithRatingState
    ratings(own: $own) {
      ...RatingData
    }
  }
}
    ${TeamDataWithRatingStateFragmentDoc}
${RatingDataFragmentDoc}`;

/**
 * __useTeamsWithRatingQuery__
 *
 * To run a query within a React component, call `useTeamsWithRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsWithRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsWithRatingQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      own: // value for 'own'
 *   },
 * });
 */
export function useTeamsWithRatingQuery(baseOptions?: Apollo.QueryHookOptions<TeamsWithRatingQuery, TeamsWithRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsWithRatingQuery, TeamsWithRatingQueryVariables>(TeamsWithRatingDocument, options);
      }
export function useTeamsWithRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsWithRatingQuery, TeamsWithRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsWithRatingQuery, TeamsWithRatingQueryVariables>(TeamsWithRatingDocument, options);
        }
export type TeamsWithRatingQueryHookResult = ReturnType<typeof useTeamsWithRatingQuery>;
export type TeamsWithRatingLazyQueryHookResult = ReturnType<typeof useTeamsWithRatingLazyQuery>;
export type TeamsWithRatingQueryResult = Apollo.QueryResult<TeamsWithRatingQuery, TeamsWithRatingQueryVariables>;
export const TeamsWithEvaluationDocument = gql`
    query teamsWithEvaluation($eventId: String) {
  teams(eventId: $eventId) {
    ...TeamDataWithRating
  }
}
    ${TeamDataWithRatingFragmentDoc}`;

/**
 * __useTeamsWithEvaluationQuery__
 *
 * To run a query within a React component, call `useTeamsWithEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsWithEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsWithEvaluationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useTeamsWithEvaluationQuery(baseOptions?: Apollo.QueryHookOptions<TeamsWithEvaluationQuery, TeamsWithEvaluationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsWithEvaluationQuery, TeamsWithEvaluationQueryVariables>(TeamsWithEvaluationDocument, options);
      }
export function useTeamsWithEvaluationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsWithEvaluationQuery, TeamsWithEvaluationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsWithEvaluationQuery, TeamsWithEvaluationQueryVariables>(TeamsWithEvaluationDocument, options);
        }
export type TeamsWithEvaluationQueryHookResult = ReturnType<typeof useTeamsWithEvaluationQuery>;
export type TeamsWithEvaluationLazyQueryHookResult = ReturnType<typeof useTeamsWithEvaluationLazyQuery>;
export type TeamsWithEvaluationQueryResult = Apollo.QueryResult<TeamsWithEvaluationQuery, TeamsWithEvaluationQueryVariables>;
export const ResendVerificationCodeDocument = gql`
    mutation resendVerificationCode($id: String!) {
  resendVerificationCode(id: $id)
}
    `;
export type ResendVerificationCodeMutationFn = Apollo.MutationFunction<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>;

/**
 * __useResendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useResendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationCodeMutation, { data, loading, error }] = useResendVerificationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>(ResendVerificationCodeDocument, options);
      }
export type ResendVerificationCodeMutationHookResult = ReturnType<typeof useResendVerificationCodeMutation>;
export type ResendVerificationCodeMutationResult = Apollo.MutationResult<ResendVerificationCodeMutation>;
export type ResendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($id: String!) {
  deleteTeam(id: $id) {
    ...TeamDataWithRatingState
  }
}
    ${TeamDataWithRatingStateFragmentDoc}`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($data: CreateTeamInput!) {
  createTeam(data: $data) {
    ...TeamDataWithRatingState
  }
}
    ${TeamDataWithRatingStateFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const RegisterTeamDocument = gql`
    mutation registerTeam($data: RegisterTeamInput!) {
  registerTeam(data: $data) {
    ...TeamDataMinimal
  }
}
    ${TeamDataMinimalFragmentDoc}`;
export type RegisterTeamMutationFn = Apollo.MutationFunction<RegisterTeamMutation, RegisterTeamMutationVariables>;

/**
 * __useRegisterTeamMutation__
 *
 * To run a mutation, you first call `useRegisterTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTeamMutation, { data, loading, error }] = useRegisterTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterTeamMutation(baseOptions?: Apollo.MutationHookOptions<RegisterTeamMutation, RegisterTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterTeamMutation, RegisterTeamMutationVariables>(RegisterTeamDocument, options);
      }
export type RegisterTeamMutationHookResult = ReturnType<typeof useRegisterTeamMutation>;
export type RegisterTeamMutationResult = Apollo.MutationResult<RegisterTeamMutation>;
export type RegisterTeamMutationOptions = Apollo.BaseMutationOptions<RegisterTeamMutation, RegisterTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($id: String!, $data: UpdateTeamInput!) {
  updateTeam(id: $id, data: $data) {
    ...TeamDataWithRatingState
  }
}
    ${TeamDataWithRatingStateFragmentDoc}`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const VerifyTeamDocument = gql`
    mutation verifyTeam($data: VerifyTeamInput!) {
  verifyTeam(data: $data) {
    team {
      ...TeamDataMinimal
    }
    accessToken
    refreshToken
  }
}
    ${TeamDataMinimalFragmentDoc}`;
export type VerifyTeamMutationFn = Apollo.MutationFunction<VerifyTeamMutation, VerifyTeamMutationVariables>;

/**
 * __useVerifyTeamMutation__
 *
 * To run a mutation, you first call `useVerifyTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTeamMutation, { data, loading, error }] = useVerifyTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyTeamMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTeamMutation, VerifyTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTeamMutation, VerifyTeamMutationVariables>(VerifyTeamDocument, options);
      }
export type VerifyTeamMutationHookResult = ReturnType<typeof useVerifyTeamMutation>;
export type VerifyTeamMutationResult = Apollo.MutationResult<VerifyTeamMutation>;
export type VerifyTeamMutationOptions = Apollo.BaseMutationOptions<VerifyTeamMutation, VerifyTeamMutationVariables>;
export const ActivateTeamDocument = gql`
    mutation activateTeam($id: String!) {
  activateTeam(id: $id) {
    ...TeamDataWithRatingState
  }
}
    ${TeamDataWithRatingStateFragmentDoc}`;
export type ActivateTeamMutationFn = Apollo.MutationFunction<ActivateTeamMutation, ActivateTeamMutationVariables>;

/**
 * __useActivateTeamMutation__
 *
 * To run a mutation, you first call `useActivateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTeamMutation, { data, loading, error }] = useActivateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateTeamMutation(baseOptions?: Apollo.MutationHookOptions<ActivateTeamMutation, ActivateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateTeamMutation, ActivateTeamMutationVariables>(ActivateTeamDocument, options);
      }
export type ActivateTeamMutationHookResult = ReturnType<typeof useActivateTeamMutation>;
export type ActivateTeamMutationResult = Apollo.MutationResult<ActivateTeamMutation>;
export type ActivateTeamMutationOptions = Apollo.BaseMutationOptions<ActivateTeamMutation, ActivateTeamMutationVariables>;
export const UsersDocument = gql`
    query users {
  users {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($id: String!) {
  inviteUser(id: $id)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $data: UpdateUserInput!) {
  updateUser(id: $id, data: $data) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    