import React from 'react'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { UsersView } from '@features/users/views/UsersView'
import { Roles } from '@typings/roles'
import { AdminLayout } from '@shared/layouts/AdminLayout'

import { AppRouteObject } from '../router'

export const usersRoutes: AppRouteObject[] = [
  {
    path: '/users',
    element: <ProtectedRoute component={<AdminLayout />} />,
    access: {
      roles: [Roles.ADMIN]
    },
    meta: {
      name: 'users',
      navItem: true,
      navIcon: 'PeopleAlt',
      navOrder: 3
    },
    children: [{
      index: true,
      element: <UsersView />
    }]
  }
]
