import { Box, BoxProps } from '@mui/material'
import { BaseMarkDownEditor } from '@shared/components/inputs/BaseMarkDownEditor'
import { BaseMultiFileInput } from '@shared/components/inputs/BaseMultiFileInput'
import { TextInput } from '@shared/components/inputs/TextInput'
import { MAX_LINK_LENGTH, MAX_MD_LENGTH } from '@shared/constants/input'
import { urlPattern } from '@utils/pattern'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = React.PropsWithChildren & BoxProps & {
  loading?: boolean,
}

export const SubmissionInputs: React.FC<Props> = ({ loading, ...props }) => {
  const { t } = useTranslation()

  return (
    <Box {...props}>
      <TextInput
        formKey="repository"
        label={t('submission.repository')}
        type="text"
        inputProps={{
          maxLength: MAX_LINK_LENGTH
        }}
        options={{
          required: true,
          pattern: urlPattern
        }}
      />

      <TextInput
        formKey="website"
        label={t('submission.website')}
        type="text"
        sx={{
          mt: 2
        }}
        inputProps={{
          maxLength: MAX_LINK_LENGTH
        }}
        options={{
          pattern: urlPattern
        }}
      />
      {/*
      <TextInput
        formKey="texts.description"
        label={t('submission.description')}
        type="text"
        inputProps={{
          max: MAX_MD_LENGTH
        }}
        multiline
        sx={{
          mt: 2
        }}
      />
 */}
      <BaseMarkDownEditor
        formKey='texts.description'
        title={t('submission.description')}
        options={{ required: true }}
        description={t('submission.createDescription')}
        mdProps={{
          maxLength: MAX_MD_LENGTH,
          height: 300,
          hideExtraCommands: true
        }}
      />

      <BaseMultiFileInput
        formKey="attachments"
        fileKey="file"
        title={t('submission.attachments')}
        description={t('files.attachmentsDescription')}
        acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg', '.png', '.webp', '.gif'], 'application/pdf': ['.pdf'], 'video/*': ['.mp4'] }}
        maxFileSize={20971520}
        loading={loading}
      />
    </Box>
  )
}
