import { Box, useTheme } from '@mui/material'
import { DashboardBreadcrumbs } from '@shared/components/ui/Breadcrumbs'
import { NavSidebar } from '@shared/navSidebar/NavSidebar'
import React from 'react'
import { Outlet } from 'react-router'

export const AdminLayout: React.FC = () => {
  const { breakpoints } = useTheme()

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    }}
    >
      <Box sx={{
        flex: '0 1 300px',
        maxWidth: '300px',
        height: '100%',
        [breakpoints.down('md')]: [
          {
            // TODO mobile menu
          }
        ]
      }}
      >
        <NavSidebar />
      </Box>
      <Box sx={{
        flex: 1,
        overflow: 'hidden'
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        >
          <Box sx={{ flex: 0 }}>
            <DashboardBreadcrumbs />
          </Box>
          <Box sx={{
            flex: 1,
            overflow: 'hidden'
          }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
