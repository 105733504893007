import React from 'react'
import { Navigate } from 'react-router'
import { isRole } from '@utils/permissions'
import { AdminLayout } from '@shared/layouts/AdminLayout'
import { Roles } from '@typings/roles'

export const RoleBasedRedirection: React.FC = () => {
  if (isRole(Roles.JURY)) {
    return <Navigate replace to="/jury" />
  }

  return <AdminLayout />
}
