import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { UpdateEventInput } from '@typings/graphql'
import { useFormContext, useWatch } from 'react-hook-form'
import { DateTimeInput } from '@shared/components/inputs/DateTimeInput'
import { mailPattern } from '@utils/pattern'

export const EventCreateEditInputs: React.FC = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<UpdateEventInput>()

  const watchedStartDate = useWatch({
    control,
    name: 'startDate'
  })

  const watchedEndDate = useWatch({
    control,
    name: 'endDate'
  })

  return (
    <>
      <TextInput
        formKey="name"
        hideMaxLength
        label={t('common.name')}
        options={{
          required: true
        }}
      />

      <TextInput
        formKey="texts.subject"
        hideMaxLength
        label={t('common.subject')}
        options={{
          required: true
        }}
      />

      <DateTimeInput
        formKey="startDate"
        label={t('common.startDate')}
        options={{
          required: true
        }}
        dateTimePickerProps={{
          ...(watchedEndDate && { maxDate: watchedEndDate }),
          disablePast: true
        }}
      />

      <Box sx={{
        mt: 3
      }}
      >
        <DateTimeInput
          formKey="endDate"
          label={t('common.endDate')}
          options={{
            required: true
          }}
          dateTimePickerProps={{
            ...(watchedStartDate && { minDate: watchedStartDate }),
            disablePast: true
          }}
        />
      </Box>

      <Box sx={{
        mt: 3
      }}
      >
        <TextInput
          formKey="supportEmail"
          label={t('common.supportEmail')}
          hideMaxLength
          options={{
            required: true,
            pattern: mailPattern
          }}
        />
      </Box>

    </>
  )
}
