import React from 'react'
import { TeamColumnType, TeamsRowModel } from '@features/teams/typings/types'
import { Typography } from '@mui/material'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

const RenderCell: React.FC<TeamsRowModel> = ({ teamResult }) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant='subtitle1'
      sx={{
        color: teamResult ? 'success.main' : 'error.main'
      }}
    >
      {teamResult ? t('common.yes') : t('common.no')}
    </Typography>
  )
}

export const submittedColumnModel: TeamColumnType = {
  field: 'teamResult',
  width: 125,
  minWidth: 125,
  filterOperators: getGridBooleanOperators(),
  valueGetter: (params) => !!params.row.teamResult,
  renderCell: (params) => <RenderCell {...params.row} />
}
