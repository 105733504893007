import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiFormLabelType = {
  defaultProps?: ComponentsProps['MuiFormLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormLabel'];
  variants?: ComponentsVariants['MuiFormLabel'];
};

export const MuiFormLabel: MuiFormLabelType = {
  styleOverrides: {
    root: {
      color: palette.grey?.[500],
      '&.MuiInputLabel-outlined': {
        '&.MuiInputLabel-shrink': {
          top: '1px',
          backgroundColor: 'transparent',
          color: palette.text?.primary
        }
      }
    }
  }
}
