import { Box, Button, Tooltip } from '@mui/material'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'

import { EventDetailsButtonActions, EventDetailsHandleActionProps } from '../typings/types'
import { useEventDetailsContext } from '../provider/EventDetailsDataProvider'

export const EventDetailActionCard: React.FC<EventDetailsHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  const { eventData } = useEventDetailsContext()

  const onChangeSubmission = () => {
    handleAction(EventDetailsButtonActions.TOGGLE_SUBMISSION)
  }

  const onChangeRegistration = () => {
    handleAction(EventDetailsButtonActions.TOGGLE_REGISTRATION)
  }
  const onChangeRatings = () => {
    handleAction(EventDetailsButtonActions.TOGGLE_RATINGS)
  }

  const isStarted = React.useMemo(() => {
    if (!eventData) {
      return false
    }

    return new Date(eventData.startDate).getTime() < new Date().getTime()
  }, [eventData])

  const tooltipText = React.useMemo(() => {
    if (!eventData?.active) {
      return t('eventDetails.eventNotActiveTooltip')
    } else if (!isStarted) {
      return t('eventDetails.eventNotStartedTooltip')
    }

    return ''
  }, [eventData])

  return (
    <DashboardCard
      header={t('eventDetails.settings')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: 'error.main'
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {can('events:update') && (
          <>
            <Tooltip
              title={tooltipText}
              disableHoverListener={!tooltipText}
            >
              <Box>
                <Button
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={onChangeSubmission}
                  disabled={!eventData?.active || !isStarted}
                >
                  {t(`action.${!eventData?.submission ? 'openSubmission' : 'closeSubmission'}`)}
                </Button>
              </Box>
            </Tooltip>

            <Button
              fullWidth
              sx={{ mb: 2 }}
              onClick={onChangeRegistration}
              disabled={!eventData?.active}
            >
              {t(`action.${!eventData?.registration ? 'openRegistration' : 'closeRegistration'}`)}
            </Button>

            <Button
              fullWidth
              onClick={onChangeRatings}
              disabled={!eventData?.active}
            >
              {t(`action.${!eventData?.rateable ? 'openRatings' : 'closeRatings'}`)}
            </Button>
          </>
          )}
        </Box>
      </Box>
    </DashboardCard>
  )
}
