import { Box, MenuItem, Typography } from '@mui/material'
import { commands } from '@uiw/react-md-editor'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  close: () => void;
  execute: () => void;
  getState?: (() => false | commands.TextState) | undefined;
  textApi?: commands.TextAreaTextApi | undefined;
}

export const HeadlineExtension: React.FC<Props> = ({ close, getState, textApi }) => {
  const { t } = useTranslation()

  const insert = (value: string) => {
    const state = getState?.().valueOf()

    const selected = typeof state === 'object' ? (state as any)?.selectedText : ''
    textApi?.replaceSelection(`${value} ${selected}`)

    close()
  }

  return (
    <ul>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <MenuItem
          onClick={() => insert('#')}
        >
          <Typography variant="body1"
            sx={{
              fontSize: '1.25rem',
              p: 1
            }}
          >{t('mdEditor.titleSize', { size: 1 })}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => insert('##')}
        >
          <Typography variant="body1"
            sx={{
              fontSize: '1.25rem',
              p: 1
            }}
          >{t('mdEditor.titleSize', { size: 2 })}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => insert('###')}
        >
          <Typography variant="body1"
            sx={{
              fontSize: '1.25rem',
              p: 1
            }}
          >{t('mdEditor.titleSize', { size: 3 })}</Typography>
        </MenuItem>
      </Box>
    </ul>
  )
}
