import { Box, IconButton, Tooltip } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { themeIcons } from '@theme/icons'
import { passwordPattern } from '@utils/pattern'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PASSWORD_MIN_LENGTH, PASSWORD_SYMBOLS } from '@shared/constants/password'

import { PasswordResetInputType } from '../views/PasswordResetView'

export const PasswordResetInput: React.FC = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<PasswordResetInputType>()

  const [showPassword, setShowPassword] = React.useState(false)

  const watchedPassword = useWatch({
    control,
    name: 'password',
    defaultValue: ''
  })

  return (
    <>
      <TextInput
        formKey="password"
        label={t('common.password')}
        type={showPassword ? 'text' : 'password'}
        hideMaxLength
        options={{
          required: true,
          validate: (value) => passwordPattern(value.trim())
        }}
        InputProps={{
          endAdornment: (
            <Box sx={{
              position: 'absolute',
              right: '8px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}
            >
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? <themeIcons.Visibility /> : <themeIcons.VisibilityOff />}
              </IconButton>

              <Tooltip title={t('error.passwordRequirements', {
                chars: PASSWORD_SYMBOLS,
                length: PASSWORD_MIN_LENGTH
              })}
                sx={{
                  cursor: 'pointer',
                  mr: 1
                }}
              >
                <IconButton >
                  <themeIcons.Info />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }}
        validationText={!passwordPattern(watchedPassword.trim()) ? t('error.input.passwordStrength') : t('error.input.default')}
      />

      <TextInput
        formKey="passwordConfirm"
        label={t('common.passwordConfirm')}
        type={showPassword ? 'text' : 'password'}
        autoComplete='new-password'
        hideMaxLength
        sx={{
          mt: 2
        }}
        options={{
          required: true,
          validate: (value) => passwordPattern(value.trim()) && value.trim() === watchedPassword.trim()
        }}
        validationText={t('error.input.passwordNotMatch')}
      />
    </>
  )
}
