import React from 'react'
import { BaseDrawer, BaseDrawerProps } from '@shared/components/ui/BaseDrawer'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { FetchResult } from '@apollo/client'

import { TeamsRowModel } from '../typings/types'

import { TeamRatingInput } from './TeamRatingInput'

type Props<T> = BaseDrawerProps & {
  team: TeamsRowModel | null
  handleUpdate: (data: any) => Promise<FetchResult<any>>
  resetModel?: T | null
}

export function TeamRatingDrawer<T> (props: Props<T>) {
  const { team, handleUpdate, onSuccess, onError, onClose, ...restProps } = props

  const { t } = useTranslation()

  const formData = useForm()

  const { handleSubmit, formState: { isDirty }, reset } = formData

  const { trigger } = useUnsavedChanges({
    when: isDirty,
    proceed: onClose
  })

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const ratings: Record<string, number> = {}

    team?.ratings?.forEach((rating) => {
      ratings[rating.criteriaId] = rating.value
    })

    formData.reset({
      ratings
    })

    return () => {
      formData.reset()
    }
  }, [team])

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await handleUpdate(data)
      onSuccess?.()
    } catch (error: any) {
      onError?.(error)
    } finally {
      setLoading(false)
    }
  })

  return (
    <BaseDrawer
      title={team?.name}
      onClose={trigger}
      onTransitionEnd={() => reset()}
      footer={
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={!isDirty}
          onClick={onSubmit}
        >
          {t('action.rate')}
        </LoadingButton>
      }
      contentProps={{
        sx: {
          p: 0
        }
      }}
      {...restProps}
    >
      <FormProvider {...formData}>
        <form style={{ height: '100%', overflow: 'auto' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
          >
            <Box sx={{
              flex: 1,
              p: 3
            }}
            >
              <TeamRatingInput team={team} />
            </Box>
          </Box>
        </form>

      </FormProvider>
    </BaseDrawer>
  )
}
