import { Checkbox, CheckboxProps, FormControlLabel, FormGroup } from '@mui/material'
import React from 'react'
import { RegisterOptions, useFormContext, Controller } from 'react-hook-form'

export type CheckboxInputProps = {
  formKey: string
  label: string
  options?: RegisterOptions
  checkboxProps?: CheckboxProps
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({ formKey, options, label, checkboxProps }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { value, onChange } }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value || false}
                onChange={onChange}
                {...checkboxProps}
              />
            }
            label={label}
          />
        </FormGroup>
      )}
    />
  )
}
