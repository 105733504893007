import { Box, BoxProps, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

export type BaseDrawerProps = React.PropsWithChildren & {
  title?: string
  open: boolean
  footer?: React.ReactNode
  contentProps?: BoxProps
  onTransitionEnd?: () => void
  onSuccess?: () => void
  onError?: (error: any) => void
  onClose?: () => void
}

export const BaseDrawer: React.FC<BaseDrawerProps> = ({
  children,
  title,
  onClose,
  footer,
  contentProps,
  onTransitionEnd,
  ...props
}) => {
  const { sx: contentStyles, ...restContentProps } = contentProps ?? {}

  return (
    <Drawer
      anchor='right'
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          minWidth: 500,
          maxWidth: 500
        }
      }}
      onClose={onClose}
      SlideProps={{
        onExited: onTransitionEnd
      }}
      {...props}
    >
      <Box sx={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      >
        <Box sx={{
          flex: '0 0 auto',
          pt: 3
        }}
        >
          <Box
            sx={{
              pl: 3,
              pr: 8,
              pb: 3,
              position: 'relative'
            }}
          >
            <Typography variant="h3">{title}</Typography>
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: '16px'
              }}
              onClick={onClose}
            >
              <themeIcons.Close />
            </IconButton>
          </Box>
          <Divider light />
        </Box>
        <Box sx={{
          flex: 1,
          overflow: 'hidden',
          p: 3,
          ...contentStyles
        }}
          {...restContentProps}
        >
          {children}
        </Box>
        {footer && (
        <Box sx={{
          flex: '0 0 auto',
          pb: 3
        }}
        >
          <Divider light />
          <Box sx={{
            px: 3,
            pt: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
          >
            {footer}
          </Box>
        </Box>
        )}
      </Box>
    </Drawer>
  )
}
