import { Typography } from '@mui/material'
import { useEventMinimalQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const EventDetailsBreadcrumb:React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { data } = useEventMinimalQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const title = React.useMemo(() => {
    if (!data?.event) {
      return t('common.unknown')
    }

    return data.event?.name
  }, [data])

  return (
    <Typography
      variant="subtitle1"
      fontWeight="inherit"
    >
      {title}
    </Typography>
  )
}
