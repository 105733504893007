import type React from 'react'
import {
  InfoOutlined,
  SvgIconComponent,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  BarChartOutlined,
  CheckOutlined,
  CloseOutlined,
  CodeOutlined,
  CodeOffOutlined,
  DateRangeOutlined,
  DeleteOutline,
  DraftsOutlined,
  EditOutlined,
  EventOutlined,
  GroupsOutlined,
  HomeOutlined,
  MailOutlined,
  PeopleAltOutlined,
  LogoutOutlined,
  SubjectOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  CancelOutlined,
  CloudUploadOutlined,
  ExpandOutlined,
  FileUploadOutlined,
  MinimizeOutlined,
  UploadFileOutlined,
  DataObjectOutlined,
  FormatBoldOutlined,
  FormatItalicOutlined,
  TitleOutlined,
  HelpOutlined,
  StarOutlined,
  FeedbackOutlined,
  RateReviewOutlined,
  AddOutlined,
  DragHandleOutlined,
  ArrowDownwardOutlined,
  ContentPasteOutlined,
  KeyboardArrowDownOutlined,
  AssignmentOutlined
} from '@mui/icons-material'
import type { SvgIconProps } from '@mui/material'

export type ThemeIcons = Record<
  string,
  SvgIconComponent | React.FC<SvgIconProps>
>;

export const customThemeIcons = {
  Add: AddOutlined,
  ArrowBack: ArrowBackOutlined,
  ArrowDownward: ArrowDownwardOutlined,
  ArrowForward: ArrowForwardOutlined,
  Assignment: AssignmentOutlined,
  BarChart: BarChartOutlined,
  Cancel: CancelOutlined,
  Check: CheckOutlined,
  CloudUpload: CloudUploadOutlined,
  Close: CloseOutlined,
  Code: CodeOutlined,
  CodeOff: CodeOffOutlined,
  ContentPaste: ContentPasteOutlined,
  DataObject: DataObjectOutlined,
  DateRange: DateRangeOutlined,
  Delete: DeleteOutline,
  DragHandle: DragHandleOutlined,
  Drafts: DraftsOutlined,
  Edit: EditOutlined,
  Expand: ExpandOutlined,
  Event: EventOutlined,
  Feedback: FeedbackOutlined,
  RateReview: RateReviewOutlined,
  FileUpload: FileUploadOutlined,
  FormatBold: FormatBoldOutlined,
  FormatItalic: FormatItalicOutlined,
  Groups: GroupsOutlined,
  Help: HelpOutlined,
  Home: HomeOutlined,
  Info: InfoOutlined,
  KeyboardArrowDown: KeyboardArrowDownOutlined,
  Mail: MailOutlined,
  Minimize: MinimizeOutlined,
  PeopleAlt: PeopleAltOutlined,
  Logout: LogoutOutlined,
  Star: StarOutlined,
  Subject: SubjectOutlined,
  Title: TitleOutlined,
  UploadFile: UploadFileOutlined,
  Visibility: VisibilityOutlined,
  VisibilityOff: VisibilityOffOutlined
}

export const themeIcons = {
  ...customThemeIcons
}
