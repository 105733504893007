import React from 'react'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { Roles } from '@typings/roles'
import { AdminLayout } from '@shared/layouts/AdminLayout'
import { EventsView } from '@features/events/views/EventsView'
import { EventDetailView } from '@features/eventDetail/views/EventDetailView'
import { TeamsView } from '@features/teams/views/TeamsView'
import { EvaluationView } from '@features/evaluation/views/EvaluationView'
import { EventDetailsBreadcrumb } from '@features/eventDetail/components/EventDetailsBreadcrumb'
import { FeedbackEvaluationView } from '@features/feedbackEvaluation/views/FeedbackEvaluationView'
import { FeedbackQuestionsView } from '@features/feedbackQuestions/views/FeedbackQuestionsView'
import { SchemeView } from '@features/scheme/views/SchemeView'
import { ChallengesView } from '@features/challenges/views/ChallengesView'

import { AppRouteObject } from '../router'

export const eventsRoutes: AppRouteObject[] = [
  {
    path: '/events',
    element: <ProtectedRoute component={<AdminLayout />} />,
    access: {
      roles: [Roles.ADMIN, Roles.MANAGER, Roles.CODE_REVIEWER]
    },
    meta: {
      name: 'events',
      navItem: true,
      navIcon: 'Event',
      navOrder: 2
    },
    children: [{
      index: true,
      element: <EventsView />
    }, {
      path: ':id',
      breadcrumb: <EventDetailsBreadcrumb />,
      meta: {
        hasSubMenu: true
      },
      children: [{
        index: true,
        element: <EventDetailView />,
        meta: {
          name: 'eventDetail',
          navIcon: 'Event',
          navItem: true,
          navOrder: 1
        }
      },
      {
        path: 'scheme',
        element: <SchemeView />,
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER]
        },
        meta: {
          name: 'scheme',
          navIcon: 'ContentPaste',
          navItem: true,
          navOrder: 2
        }
      },
      {
        path: 'challenges',
        element: <ChallengesView />,
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER]
        },
        meta: {
          name: 'challenges',
          navIcon: 'Assignment',
          navItem: true,
          navOrder: 3
        }
      },
      {
        path: 'teams',
        element: <TeamsView />,
        meta: {
          name: 'teams',
          navIcon: 'Groups',
          navItem: true,
          navOrder: 4
        }
      }, {
        path: 'evaluation',
        element: <EvaluationView />,
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER]
        },
        meta: {
          name: 'evaluation',
          navIcon: 'BarChart',
          navItem: true,
          navOrder: 5
        }
      }, {
        path: 'feedback',
        element: <FeedbackQuestionsView />,
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER]
        },
        meta: {
          name: 'feedbackQuestions',
          navIcon: 'RateReview',
          navItem: true,
          navOrder: 6
        }
      }, {
        path: 'feedback-evaluation',
        element: <FeedbackEvaluationView />,
        access: {
          roles: [Roles.ADMIN, Roles.MANAGER]
        },
        meta: {
          name: 'feedbackEvaluation',
          navIcon: 'Feedback',
          navItem: true,
          navOrder: 7
        }
      }]
    }]
  }
]
