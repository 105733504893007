import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'

export const ChallengesCreateEditInputs: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TextInput
        formKey="texts.title"
        hideMaxLength
        label={t('common.title')}
        options={{
          required: true
        }}
      />

      <TextInput
        formKey="texts.description"
        label={t('common.description')}
        options={{
          required: true
        }}
      />

    </>
  )
}
