import { Box } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useFeedbackContext } from '../provider/FeedbackDataProvider'
import { FeedbackInput } from '../typings/types'

export const FeedbackQuestionProgress: React.FC = () => {
  const { eventData } = useFeedbackContext()
  const { control } = useFormContext<FeedbackInput>()

  const totalQuestions = React.useMemo(() => {
    return eventData?.feedbackQuestions.length || 0
  }, [eventData])

  const watchedFeedbacks = useWatch({
    control,
    name: 'feedback',
    defaultValue: {}
  })

  const answeredQuestions = React.useMemo(() => {
    return Object.entries(watchedFeedbacks).filter(([key, feedback]) => {
      const feedbackType = typeof feedback

      if (feedbackType === 'number' || feedbackType === 'string' || key === 'other') {
        return !!feedback
      }

      return Object.entries(feedback || {}).filter(([, value]) => (value?.length !== undefined ? value.length > 0 : !!value)).length > 0
    }).length
  }, [watchedFeedbacks])

  return (
    <Box sx={{
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      backgroundColor: 'grey.100',
      height: '8px',
      zIndex: 10
    }}
    >
      {totalQuestions > 0 && (
        <Box sx={{
          height: '100%',
          transition: 'width 0.5s ease-in-out',
          width: `${(answeredQuestions / totalQuestions) * 100}%`,
          backgroundColor: 'primary.main'
        }}
        />
      )}
    </Box>
  )
}
