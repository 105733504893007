import {
  RegisterTeamMutation,
  TeamDataMinimalFragment,
  useActiveEventQuery,
  useRegisterTeamMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { FetchResult } from 'apollo-link'

import { EventNode, RegisterTeamInput } from '../typings/types'

export type RegisterTeamDataProviderContextType = {
  eventData: EventNode | null
  teamData: TeamDataMinimalFragment[]
  loading: boolean
  handleRegisterTeam: (input: RegisterTeamInput) => Promise<FetchResult<RegisterTeamMutation>>
}

const RegisterTeamDataProviderContext = React.createContext<RegisterTeamDataProviderContextType>(
  {} as any
)

export const RegisterTeamDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, loading: eventDataLoading, refetch } = useActiveEventQuery({
    fetchPolicy: 'cache-and-network'
  })

  const eventData = React.useMemo<EventNode | null>(() => {
    return data?.activeEvent || null
  }, [data])

  const teamData = React.useMemo<TeamDataMinimalFragment[]>(() => {
    return data?.activeEvent?.teams || []
  }, [data])

  const loading = useArtificialLoading(500, [eventDataLoading])

  const [registerTeam] = useRegisterTeamMutation({
    update (cache, { data: updatedData }) {
      const createdTeam = updatedData?.registerTeam

      if (createdTeam) {
        cache.modify({
          id: cache.identify({
            __typename: 'Event',
            id: data?.activeEvent?.id
          }),
          fields: {
            teams (existingTeams = []) {
              return [...existingTeams, createdTeam]
            }
          }
        })
      }

      refetch()
    }
  })

  const handleRegisterTeam = React.useCallback(async (input: RegisterTeamInput) => {
    const { teamMembers, ...rest } = input
    return await registerTeam({
      variables: {
        data: {
          ...rest,
          teamMembers: teamMembers?.map((item) => ({
            firstName: item.firstName,
            lastName: item.lastName
          })) || []
        }
      }
    })
  }, [])

  const value = React.useMemo<RegisterTeamDataProviderContextType>(() => {
    return {
      eventData,
      teamData,
      loading,
      handleRegisterTeam
    }
  }, [eventData, loading])

  return (
    <>
      <RegisterTeamDataProviderContext.Provider value={value}>
        {children}
      </RegisterTeamDataProviderContext.Provider>
    </>
  )
}

export const useRegisterTeamContext = () => React.useContext(RegisterTeamDataProviderContext)
