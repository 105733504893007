import { Box, Typography } from '@mui/material'
import React from 'react'
import { RegisterOptions } from 'react-hook-form'

import { InputTitleField } from '../ui/InputRequiredIndicator'
import { MarkdownEditor, MarkdownEditorProps } from '../wysiwyg/MarkdownEditor'

type Props = {
  formKey: string
  options?: RegisterOptions
  title: string
  description?: string,
  mdProps?: Partial<MarkdownEditorProps>
}

export const BaseMarkDownEditor: React.FC<Props> = ({ formKey, options, title, mdProps, description }) => {
  return (
    <Box py={4}>
      <Box mb={4}>
        <InputTitleField required={!!options?.required} text={title} />
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Box>
      <Box>
        <Box position="relative">
          <MarkdownEditor
            {...mdProps}
            name={formKey}
            options={options}
          ></MarkdownEditor>
        </Box>
      </Box>
    </Box>

  )
}
