import { EventQuery, TeamsQuery } from '@typings/graphql'

export enum EventDetailsButtonActions {
  TOGGLE_SUBMISSION = 'TOGGLE_SUBMISSION',
  TOGGLE_REGISTRATION = 'TOGGLE_REGISTRATION',
  TOGGLE_RATINGS = 'TOGGLE_RATINGS',
}

export type EventModel = Exclude<EventQuery['event'], null | undefined>
export type TeamModel = Exclude<TeamsQuery['teams'], null | undefined>[0]

export type EventDetailsHandleActionProps = {
  handleAction: (action: EventDetailsButtonActions) => void
}
