import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material'
import { palette } from '@theme/core/palette/palette'

export type MuiRatingType = {
  defaultProps?: ComponentsProps['MuiRating'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiRating'];
  variants?: ComponentsVariants['MuiRating'];
};

export const MuiRating: MuiRatingType = {
  styleOverrides: {
    icon: {
      svg: {
        width: '40px',
        height: '40px'
      }
    },
    iconFilled: {
      color: palette.primary.main
    },
    iconEmpty: {
      color: palette.primary.main,
      strokeWidth: '1px'
    }
  }
}
