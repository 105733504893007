import { MuiAccordion } from './components/accordion'
import { MuiAlert } from './components/alert'
import { MuiCssBaseline } from './components/baseline'
import { MuiButton } from './components/button'
import { MuiCheckbox } from './components/checkbox'
import { MuiChip } from './components/chip'
import { MuiDataGrid } from './components/dataGrid'
import { MuiDialog } from './components/dialog'
import { MuiDivider } from './components/divider'
import { MuiFormControlLabel } from './components/formControlLabel'
import { MuiFormLabel } from './components/formLabel'
import { MuiIconButton } from './components/iconButton'
import { MuiInputBase } from './components/input'
import { MuiRating } from './components/rating'
import { MuiSnackbar } from './components/snackbar'
import { MuiSvgIcon } from './components/svgIcon'
import { MuiTooltip } from './components/tooltip'

export const components = {
  MuiAccordion,
  MuiAlert,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiDataGrid,
  MuiDialog,
  MuiDivider,
  MuiFormControlLabel,
  MuiFormLabel,
  MuiIconButton,
  MuiInputBase,
  MuiRating,
  MuiSnackbar,
  MuiSvgIcon,
  MuiTooltip
}
