import { Box, Button } from '@mui/material'
import { can } from '@utils/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EventButtonActions, EventHandleActionProps } from '../typings/types'

export const EventsHeaderExtension: React.FC<EventHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()

  const onCreateEventClicked = () => {
    handleAction(EventButtonActions.CREATE)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {can('events:create') && (
        <Button
          sx={{ width: 'auto' }}
          onClick={onCreateEventClicked}
        >
          {t('events.createEvent')}
        </Button>
      )}
    </Box>
  )
}
