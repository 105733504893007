import { Box, Button, Typography } from '@mui/material'
import { LoginInput, useLoginMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useSnackbar } from '@hooks/useSnackbar'
import { LOGIN_BLOCKED } from '@shared/constants/auth'
import { useTimeBlocker } from '@hooks/useTimeBlocker'
import { setAuthData } from '@services/store/slices/auth'
import { useAppDispatch } from '@services/store/store'
import { useLocation, useNavigate } from 'react-router'
import { fontSizes } from '@theme/core/typography/typography'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'

import { LoginInputs } from '../components/LoginInput'

const LoginViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [login] = useLoginMutation()

  const { handleSubmit, formState: { isDirty, isValid } } = useFormContext<LoginInput>()

  const dispatch = useAppDispatch()
  const { message, handleError, setShowSnackbar, showSnackbar } = useSnackbar()
  const { blocked, remainingTime, startBlocker } = useTimeBlocker(LOGIN_BLOCKED)

  const [loading, setLoading] = React.useState(false)

  const submit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      const { data: authData } = await login({
        variables: {
          data
        }
      })

      if (authData) {
        dispatch(setAuthData(authData.login))

        const origin = (location.state as any)?.from || '/'
        navigate(origin, { replace: true })
      }
    } catch (error: any) {
      handleError(error)
      startBlocker()
    } finally {
      setLoading(false)
    }
  })

  const handlePasswordForgot = () => {
    navigate('/forgot-password')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          mt: 0.5
        }}
      >
        <form>
          <LoginInputs />

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: -2
          }}
          >
            <Button
              variant="text"
              sx={{
                fontSize: fontSizes.sm,
                padding: 0
              }}
              disableRipple
              onClick={handlePasswordForgot}
            >
              {t('auth.forgotPassword')}
            </Button>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            <ElevatedButton
              onClick={submit}
              loading={loading}
              disabled={!isDirty || !isValid || blocked }
              sx={{
                mt: 1
              }}
            >
              {t('action.login')}
            </ElevatedButton>

            {blocked &&
            <Typography
              variant='subtitle1'
              sx={{
                ml: 2,
                color: 'error.main'
              }}
            >
              {t('auth.loginBlockedFor', { count: remainingTime })}
            </Typography>
          }
          </Box>

        </form>
      </Box>

      <DefaultSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        severity='error'
        message={message}
        sx={{
          position: 'fixed'
        }}
      />

    </Box>
  )
}

export const LoginView: React.FC = () => {
  const formData = useForm<LoginInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <LoginViewContent />
    </FormProvider>
  )
}
