import { Box, BoxProps, Divider, Typography } from '@mui/material'
import React from 'react'

type Props = React.PropsWithChildren & {
  header?: string | React.ReactNode
  boxProps?: BoxProps
}

export const DashboardCard: React.FC<Props> = ({ header, children, boxProps }) => {
  const { sx, ...props } = boxProps || {}

  return (
    <Box
      sx={{
        py: 2,
        mr: '4px',
        backgroundColor: 'secondary.100',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        border: 'solid 1px rgba(0,0,0)',
        ...sx
      }}
      {...props}
    >
      <Box
        sx={{
          flex: '0 0 auto',
          px: 2,
          mb: header ? 1 : 0
        }}
      >
        {header && (
          <>
            {typeof header === 'string' ? (<Typography variant="h4">{header}</Typography>) : header}
            <Divider sx={{
              my: 1
            }}
            />
          </>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          px: 2,
          overflow: 'auto'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
