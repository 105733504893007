import { Box } from '@mui/material'
import { useRoutesContext } from '@providers/RoutesProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useMatchedRoutes } from '@hooks/useMatchedRoutes'
import { AppRouteObject } from '@services/router/router'

import { NavSidebarLinkListItem } from './NavSidebarLinkItem'

export const NavSidebarLinkList: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const { routes } = useRoutesContext()
  const { matched } = useMatchedRoutes()

  const [parentRoute, setParentRoute] = React.useState<AppRouteObject>()

  const mappedRoutes = React.useMemo<(AppRouteObject & {fullpath?: string})[]>(() => {
    if (!routes) {
      return []
    }
    let relevantRoutes = routes

    const subMenuRoutes = matched?.filter((match) => match.route.meta?.hasSubMenu)

    if (subMenuRoutes && subMenuRoutes?.length > 0) {
      const menuRoute = subMenuRoutes[subMenuRoutes.length - 1]

      relevantRoutes = menuRoute?.route?.children?.map((route) => {
        return {
          ...route,
          ...(route.index && {
            path: menuRoute.route.path
          }),
          fullpath: route.index ? menuRoute.pathname : `${menuRoute.pathname}/${route.path}`
        }
      }) || []

      const currentIndex = matched?.findIndex((match) => match.route.path === menuRoute?.route?.path) || 0
      setParentRoute(currentIndex > 0 ? matched?.[currentIndex - 1]?.route : undefined)
    } else {
      setParentRoute(undefined)
    }

    return relevantRoutes.filter((route) => route.meta?.navItem).sort((a, b) => (a.meta?.navOrder || 0) - (b.meta?.navOrder || 0))
  }, [routes, location])

  const selectedRoute = React.useMemo(() => {
    const matchedRoute = matched?.[matched.length - 1]?.route

    return matchedRoute?.index ? matched?.[matched.length - 2]?.route?.path || '/' : matchedRoute?.path
  }, [matched, location])

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}
    >
      {parentRoute && (
        <Box sx={{ flex: 0, mb: 2 }}>
          <NavSidebarLinkListItem
            label={t('common.back')}
            icon={'ArrowBack'}
            href={parentRoute.path || ''}
          />
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        {mappedRoutes.map((route) => (
          <Box key={`${route.meta?.name}_${route.meta?.navOrder}`} mb="4px">
            <NavSidebarLinkListItem
              label={t(`routes.${route?.meta?.name}` || '')}
              icon={route?.meta?.navIcon}
              selected={selectedRoute === route.path}
              href={route.fullpath || route.path || ''}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
