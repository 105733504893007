import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSnackbar } from '@hooks/useSnackbar'
import { useTimeBlocker } from '@hooks/useTimeBlocker'
import { LOGIN_BLOCKED } from '@shared/constants/auth'
import { useForgotPasswordMutation } from '@typings/graphql'
import { useTranslation } from 'react-i18next'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useNavigate } from 'react-router'
import { themeIcons } from '@theme/icons'
import { fontSizes } from '@theme/core/typography/typography'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'

import { PasswordForgotInput } from '../components/PasswordForgotInput'

type PasswordForgotInputType = {
  email: string
}

export const PasswordForgotViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { handleSubmit, formState: { isDirty, isValid } } = useFormContext<PasswordForgotInputType>()

  const [forgotPassword] = useForgotPasswordMutation()

  const { message, handleError, handleSuccess, setShowSnackbar, showSnackbar } = useSnackbar()
  const { blocked, remainingTime, startBlocker } = useTimeBlocker(LOGIN_BLOCKED)

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const submit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await forgotPassword({
        variables: {
          ...data
        }
      })

      handleSuccess(t('auth.passwordForgotSuccess'))
      setSuccess(true)
    } catch (error: any) {
      handleError(error)
      setSuccess(false)
      startBlocker()
    } finally {
      setLoading(false)
      setShowSnackbar(true)
    }
  })

  const navigateLogin = () => {
    navigate('/login')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          mt: 0.5
        }}
      >
        <Button
          variant='text'
          sx={{
            mb: 2,
            fontSize: fontSizes.sm
          }}
          onClick={navigateLogin}
          startIcon={<themeIcons.ArrowBack />}
        >
          {t('auth.backToLogin')}
        </Button>

        <form>
          <PasswordForgotInput />

          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            <ElevatedButton
              onClick={submit}
              loading={loading}
              disabled={!isDirty || !isValid || blocked }
              sx={{
                mt: 1
              }}
            >
              {t('action.send')}
            </ElevatedButton>

            {blocked &&
            <Typography
              variant='subtitle1'
              sx={{
                ml: 2,
                color: 'error.main'
              }}
            >
              {t('auth.actionBlockedFor', { count: remainingTime })}
            </Typography>
          }
          </Box>

        </form>
      </Box>

      <DefaultSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        severity={success ? 'success' : 'error'}
        message={message}
        sx={{
          position: 'fixed'
        }}
      />
    </Box>
  )
}

export const PasswordForgotView: React.FC = () => {
  const formData = useForm<PasswordForgotInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <PasswordForgotViewContent />
    </FormProvider>
  )
}
