import {
  CreateFeedbackAnswerMutation,
  useActiveEventWithFeedbackQuery,
  useCreateFeedbackAnswerMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { FetchResult } from 'apollo-link'

import { ActiveEventModel, FeedbackInput } from '../typings/types'

export type FeedbackDataProviderContextType = {
  eventData: ActiveEventModel | null
  loading: boolean
  handleSubmitFeedback: (input: FeedbackInput) => Promise<FetchResult<CreateFeedbackAnswerMutation>>
}

const FeedbackDataProviderContext = React.createContext<FeedbackDataProviderContextType>(
  {} as any
)

export const FeedbackDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, loading: eventDataLoading } = useActiveEventWithFeedbackQuery({
    fetchPolicy: 'cache-and-network'
  })

  const eventData = React.useMemo<ActiveEventModel | null>(() => {
    return data?.activeEvent || null
  }, [data])

  const loading = useArtificialLoading(500, [eventDataLoading])

  const [submitFeedback] = useCreateFeedbackAnswerMutation()

  const handleSubmitFeedback = React.useCallback(async (input: FeedbackInput) => {
    const mappedInput = Object.entries(input.feedback).map(([feedbackQuestionId, value]) => ({
      feedbackQuestionId,
      value
    })).filter((rating) => rating.value !== null && rating.value !== undefined)

    return await submitFeedback({
      variables: {
        data: mappedInput
      }
    })
  }, [])

  const value = React.useMemo<FeedbackDataProviderContextType>(() => {
    return {
      eventData,
      loading,
      handleSubmitFeedback
    }
  }, [eventData, loading])

  return (
    <>
      <FeedbackDataProviderContext.Provider value={value}>
        {children}
      </FeedbackDataProviderContext.Provider>
    </>
  )
}

export const useFeedbackContext = () => React.useContext(FeedbackDataProviderContext)
