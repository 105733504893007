import { Box, BoxProps, IconButton } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { themeIcons } from '@theme/icons'
import { mailPattern } from '@utils/pattern'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LoginInputs: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <Box {...props}>
      <TextInput
        formKey="email"
        label={t('common.email')}
        type="email"
        hideMaxLength
        options={{
          required: true,
          pattern: mailPattern
        }}
        validationText={t('error.input.invalidEmail')}
      />

      <TextInput
        formKey="password"
        label={t('common.password')}
        type={showPassword ? 'text' : 'password'}
        hideMaxLength
        sx={{
          mt: 2
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                position: 'absolute',
                right: '8px'
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <themeIcons.Visibility /> : <themeIcons.VisibilityOff />}
            </IconButton>
          )
        }}
      />
    </Box>
  )
}
