import { Box, IconButton, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { EditableFile } from '@typings/files'
import { convertBytesToHumanReadable } from '@utils/format'
import React from 'react'

type FileData = {
  name: string
  size: string
}

type Props = {
  file: EditableFile<any>
  onDelete?: () => void
  onPreview?: () => void
}

const buttonStyles = {
  color: 'grey.600',
  padding: 0,
  '&:hover': {
    color: 'grey.800',
    backgroundColor: 'transparent'
  }
}

export const FileDefaultPreviewRow: React.FC<Props> = ({ file, onDelete, onPreview }) => {
  const fileData = React.useMemo<FileData | null>(() => {
    if (!file) {
      return null
    }

    return {
      name: file.upload?.file?.name ?? file.remote?.fileName,
      size: convertBytesToHumanReadable(file.upload?.file?.size || file.remote?.fileSize)
    }
  }, [file])

  return fileData
    ? (
      <Box
        sx={{
          border: 'solid 1px',
          borderColor: '#000000',
          height: 'auto',
          maxHeight: '60vh',
          width: '100%',
          overflow: 'hidden',
          p: 2,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box sx={{
          backgroundColor: 'primary.200',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '6px solid',
          borderColor: 'primary.100',
          borderRadius: '50%',
          flex: '0 0 50px'
        }}
        >
          <themeIcons.FileUpload sx={{
            color: 'primary.700'
          }}
          />
        </Box>
        <Box sx={{ flex: 1, ml: 1 }}>
          <Typography>
            {fileData.name}
          </Typography>
          {fileData.size && (
          <Typography>
            {fileData.size}
          </Typography>
          )}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '0 0 auto',
          alignItems: 'center'
        }}
        >
          {onPreview &&
          <Box sx={{ mr: 2 }}>
            <IconButton
              disableRipple
              sx={buttonStyles}
              onClick={onPreview}
            >
              <themeIcons.Visibility />
            </IconButton>
          </Box>
        }
          {onDelete &&
            <Box>
              <IconButton
                disableRipple
                sx={buttonStyles}
                onClick={onDelete}
              >
                <themeIcons.Delete />
              </IconButton>
            </Box>
          }
        </Box>
      </Box>
      )
    : null
}
