import { Box } from '@mui/material'
import {
  GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro'
import React from 'react'

export const DataGridToolbar: React.FC = () => {
  return (
    <GridToolbarContainer sx={{
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderBottomColor: 'grey.200'
    }}
    >
      <Box>
        <GridToolbarFilterButton
          componentsProps={{
            button: {
              variant: 'text'
              // startIcon: <themeIcons.Filter />
            }
          }}
        />
      </Box>
      <Box>
        <GridToolbarDensitySelector variant='text'/>
      </Box>
    </GridToolbarContainer>
  )
}
