import { GridColDef } from '@mui/x-data-grid-pro'
import {
  ChallengesQuery,
  CreateTeamInput,
  SchemeByEventQuery,
  SchemeCriteriaDataFragment,
  TeamsWithRatingQuery,
  UpdateTeamInput
} from '@typings/graphql'

export enum TeamButtonActions {
  'CREATE' = 'CREATE',
  'RESEND_CODE' = 'RESEND_CODE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
  'RATE' = 'RATE',
  'ACTIVATE' = 'ACTIVATE',
}

export type TeamNode = Exclude<TeamsWithRatingQuery['teams'], null | undefined>[0]
export type TeamResultModel = Omit<Exclude<TeamNode['teamResult'], null | undefined>, '__typename'>
export type TeamsRowModel = Omit<TeamNode, '__typename'>

export type ChallengesModel = ChallengesQuery['challenges'][0]

export type SchemeModel = SchemeByEventQuery['schemeByEvent']

export type FileType = Exclude<TeamsRowModel['teamResult'], null | undefined>['files'][0]

export type TeamColumnType = Omit<GridColDef<TeamsRowModel>, 'field'> & {
  field: keyof TeamsRowModel | keyof TeamResultModel | 'action',
  hideHeader?: boolean,
}

export type SchemeCriteriaData = SchemeCriteriaDataFragment & {
  children?: SchemeCriteriaData[]
}

export type SchemeCriteriaRatingData = SchemeCriteriaData & {
  rating: number
}

export type CreateRatingsInput = {
  teamId: string
  ratings: Record<string, number>
}

export type CreateTeamModel = Omit<CreateTeamInput, 'teamMembers'> & {
  teamMembers: {
    id: string
    firstName: string
    lastName: string
  }[]
}

export type UpdateTeamModel = Omit<UpdateTeamInput, 'teamMembers'> & {
  teamMembers: {
    id: string
    firstName: string
    lastName: string
  }[]
}
