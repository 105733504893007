import { Box, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { GlobalDialogType } from '@typings/dialogs'
import { useDialogContext } from '@providers/DialogProvider'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { CreateSchemeCriteriaInput, UpdateSchemeCriteriaInput } from '@typings/graphql'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { Roles } from '@typings/roles'

import { useSchemeContext } from '../provider/SchemeDataProvider'
import { SchemeCriteriaList } from '../components/SchemeCriteriaList'
import { SchemeButtonActions, SchemeCriteriaModel } from '../typings/types'
import { CriteriaCreateEditInputs } from '../components/CriteriaCreateEditInputs'
import { IGNORED_ROLES } from '../constants/constants'

type Props = {
  onSuccess: (message: string) => void
  onError: (message: string) => void
}

export const SchemeCriteriaView: React.FC<Props> = ({ onSuccess, onError }) => {
  const { t } = useTranslation()

  const { dialogManagerRef } = useDialogContext()
  const { handleCreateCriteria, handleUpdateCriteria, handleDeleteCriteria } = useSchemeContext()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<SchemeCriteriaModel | null>(null)

  const { schemeData } = useSchemeContext()

  const resetModel = React.useMemo<UpdateSchemeCriteriaInput>(() => {
    return {
      texts: currentRow?.texts,
      weighting: currentRow?.weighting,
      roleIds: currentRow?.roles.filter(({ key }) => !IGNORED_ROLES.includes(key as Roles)).map((role) => role.id)
    }
  }, [currentRow])

  const handleCriteriaCreated = () => {
    onSuccess(t('scheme.createCriteriaSuccess'))
    setShowCreateDrawer(false)
  }

  const handleCriteriaUpdated = () => {
    onSuccess(t('scheme.editCriteriaSuccess'))
    setShowEditDrawer(false)
  }

  const handleDeleteSchemeConfirm = async (id: string) => {
    try {
      await handleDeleteCriteria(id)

      onSuccess(t('scheme.deleteCriteriaSuccess'))
    } catch (error: any) {
      onError(error)
    }
  }

  const onCreateCriteria = async (data: CreateSchemeCriteriaInput) => {
    return await handleCreateCriteria({
      ...data,
      parentId: currentRow?.id || null
    })
  }

  const onHandleAction = (action: SchemeButtonActions, model?: SchemeCriteriaModel) => {
    switch (action) {
      case SchemeButtonActions.CREATE_CRITERIA:
        setCurrentRow(model || null)
        setShowCreateDrawer(true)
        break
      case SchemeButtonActions.DELETE_CRITERIA:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            return await handleDeleteSchemeConfirm(model?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteCriteria.title'),
            children: <Trans
              i18nKey={'dialogs.deleteCriteria.message'}
              values={{
                name: model?.texts?.title
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case SchemeButtonActions.EDIT_CRITERIA:
        setCurrentRow(model || null)
        setShowEditDrawer(true)
        break
      default:
        break
    }
  }

  return (
    <Box>
      <Typography
        fontWeight="bold"
      >
        {t('scheme.criteria')}
      </Typography>

      <SchemeCriteriaList
        handleAction={onHandleAction}
        criteria={schemeData?.schemeCriteria as SchemeCriteriaModel[]}
      />

      <AddDefaultRow
        label={t('scheme.addCriteria')}
        onAdd={() => onHandleAction(SchemeButtonActions.CREATE_CRITERIA)}
        buttonProps={{
          sx: {
            mt: 2,
            height: '75px'
          }
        }}
      />

      <BaseCreateDrawer
        title={t('scheme.createScheme')}
        handleCreate={onCreateCriteria}
        open={showCreateDrawer}
        onError={onError}
        onSuccess={handleCriteriaCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <CriteriaCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateCriteria(currentRow?.id || '', data)}
        title={t('scheme.editCriteria')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleCriteriaUpdated}
        onError={onSuccess}
        onClose={() => setShowEditDrawer(false)}
      >
        <CriteriaCreateEditInputs />
      </BaseEditDrawer>
    </Box>
  )
}
