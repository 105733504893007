import React from 'react'
import { ConfirmDialog } from '@shared/components/ui/ConfirmDialog'
import { GlobalDialogProps, GlobalDialogType } from '@typings/dialogs'
import { useTranslation } from 'react-i18next'

export type DialogManagerHandler = {
  showDialog: (type: GlobalDialogType, properties?: GlobalDialogProps) => void
}

type DialogManagerRenderFn = React.ForwardRefRenderFunction<DialogManagerHandler, {}>

const DialogManagerContent:DialogManagerRenderFn = (props, ref) => {
  const { t } = useTranslation()

  const [dialogType, setDialogType] = React.useState<GlobalDialogType>()
  const [globalProps, setGlobalProps] = React.useState<GlobalDialogProps>()
  const [showDialog, setShowDialog] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    showDialog: (type: GlobalDialogType, properties?: GlobalDialogProps) => {
      setDialogType(type)
      setGlobalProps(properties)
      setShowDialog(true)
    }
  }), [])

  const handleOnClose = () => {
    globalProps?.cancelCallback?.()
    setShowDialog(false)
  }

  const handleOnConfirm = () => {
    globalProps?.confirmCallback?.()
    setShowDialog(false)
  }

  return dialogType
    ? <ConfirmDialog
        open={showDialog}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        {...{
          ...globalProps?.dialogProps,
          title: globalProps?.dialogProps?.title || t(`dialogs.${dialogType}.title`)
        }}
    >
      {globalProps?.dialogProps?.children || (
        t(`dialogs.${dialogType}.message`)
      )}
    </ConfirmDialog>
    : null
}

const DialogManager = React.forwardRef(DialogManagerContent)

export type DialogProviderContextType = {
  dialogManagerRef: React.RefObject<DialogManagerHandler>
}

const DialogProviderContext = React.createContext<DialogProviderContextType>(
  {} as any
)

export const DialogProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const dialogManagerRef = React.useRef<DialogManagerHandler>(null)

  return (
    <DialogProviderContext.Provider value={{ dialogManagerRef }}>
      <DialogManager ref={dialogManagerRef} />
      {children}
    </DialogProviderContext.Provider>
  )
}

export const useDialogContext = () => React.useContext(DialogProviderContext)
