import { Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type SelectInputItem = {
  id: string | number
  name: string
}

type Props = SelectProps & {
  formKey: string
  options?: RegisterOptions
  items: SelectInputItem[]
}

export const FormSelectInput: React.FC<Props> = ({ formKey, options, items, ...props }) => {
  const { t } = useTranslation()
  const { disabled, multiple, label, ...restProps } = props

  const { control } = useFormContext()

  return (
    <FormControl fullWidth>
      {label && <InputLabel
        sx={{
          opacity: disabled ? 0.3 : 1
        }}
        id={`${formKey}_label`}
      >{label}</InputLabel>}
      <Controller
        name={formKey}
        control={control}
        rules={options}
        render={({ field: { onChange, value, ...restFields } }) => (
          <Select fullWidth
            labelId={`${formKey}_label`}
            label={label}
            {...restProps}
            {...restFields}
            disabled={disabled}
            sx={{
              ...(disabled && {
                pointerEvents: 'none'
              })
            }}
            multiple={multiple}
            value={value || (multiple ? [] : '')}
            {...(multiple && {
              renderValue: (selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {
                    (selected as string[]).map((id) => (
                      <Chip key={id} label={items.find((item) => item.id === id)?.name} />
                    ))
                  }
                </Box>
              )
            })}
            onChange={onChange}
          >
            {!options?.required && !multiple && (
            <MenuItem value="">
              {t('common.none')}
            </MenuItem>
            )}
            {items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {multiple && (
                  <Checkbox
                    checked={value?.indexOf(item.id) > -1}
                    sx={{
                      mr: 1
                    }}
                  />
                )}
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )
          }
      />
    </FormControl>
  )
}
