import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'

export type MuiSvgIconType = {
  defaultProps?: ComponentsProps['MuiSvgIcon'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSvgIcon'];
  variants?: ComponentsVariants['MuiSvgIcon'];
}

export const MuiSvgIcon:MuiSvgIconType = {
  styleOverrides: {
    root: {
      width: '24px',
      height: '24px'
    }
  }
}
