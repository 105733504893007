import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDialogContext } from '@providers/DialogProvider'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { GlobalDialogType } from '@typings/dialogs'
import { useNavigate } from 'react-router'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { UpdateEventInput } from '@typings/graphql'

import { EventButtonActions, EventsRowModel } from '../typings/types'
import { EventsDataProvider, useEventsContext } from '../provider/EventsDataProvider'
import { EventsList } from '../components/EventsList'
import { EventsHeaderExtension } from '../components/EventsHeaderExtension'
import { EventCreateEditInputs } from '../components/EventCreateEditInputs'

const EventsViewContent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { dialogManagerRef } = useDialogContext()
  const { loading, handleDeleteEvent, handelUpdateActiveEvent, handleCreateEvent, handleUpdateEvent } = useEventsContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<EventsRowModel | null>(null)

  const resetModel = React.useMemo<UpdateEventInput>(() => {
    return {
      name: currentRow?.name,
      texts: currentRow?.texts,
      startDate: new Date(currentRow?.startDate),
      endDate: new Date(currentRow?.endDate),
      supportEmail: currentRow?.supportEmail

    }
  }, [currentRow])

  const handleDeleteEventConfirm = async (id: string) => {
    try {
      await handleDeleteEvent(id)

      handleSuccess(t('events.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleEventCreated = () => {
    handleSuccess(t('events.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleEventUpdated = () => {
    handleSuccess(t('events.editSuccess'))
    setShowEditDrawer(false)
  }

  const handleChangeActiveEvent = async (rowModel: EventsRowModel | null) => {
    if (!rowModel) {
      return
    }

    try {
      const result = await handelUpdateActiveEvent(
        rowModel.id,
        !rowModel.active
      )

      handleSuccess(t(`events.${result.data?.updateActiveEvent.active ? 'activate' : 'deactivate'}Success`))
    } catch (error: any) {
      handleError(error)
    }
  }

  const onHandleAction = (action: EventButtonActions, rowData?: EventsRowModel) => {
    switch (action) {
      case EventButtonActions.SHOW:
        navigate(rowData?.id || '')
        break
      case EventButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case EventButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            await handleDeleteEventConfirm(rowData?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteEvent.title'),
            children: <Trans
              i18nKey={'dialogs.deleteEvent.message'}
              values={{
                name: rowData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case EventButtonActions.EDIT:
        setCurrentRow(rowData || null)
        setShowEditDrawer(true)
        break
      case EventButtonActions.ACTIVATE:
        handleChangeActiveEvent(rowData || null)
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.events')}
      headerContent={<EventsHeaderExtension handleAction={onHandleAction} />}
      loading={loading}
    >
      <EventsList handleAction={onHandleAction}/>

      <BaseCreateDrawer
        title={t('events.createEvent')}
        handleCreate={handleCreateEvent}
        open={showCreateDrawer}
        onError={handleError}
        onSuccess={handleEventCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <EventCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateEvent(currentRow?.id || '', data)}
        title={t('events.editEvent')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleEventUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <EventCreateEditInputs />
      </BaseEditDrawer>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}
export const EventsView: React.FC = () => {
  return (
    <EventsDataProvider>
      <EventsViewContent />
    </EventsDataProvider>
  )
}
