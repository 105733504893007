import { CreateFeedbackQuestionModel, UpdateFeedbackQuestionModel } from '@features/feedbackQuestions/typings/types'
import { Box, IconButton, Typography } from '@mui/material'
import { CheckboxInput } from '@shared/components/inputs/CheckboxInput'
import { TextInput } from '@shared/components/inputs/TextInput'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'
import { SortableList } from '@shared/components/sortable/SortableList'
import { AddDefaultRow } from '@shared/components/ui/AddDefaultRow'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const FeedbackQuestionChoiceInput: React.FC = () => {
  const { t } = useTranslation()

  const { control, resetField } = useFormContext<CreateFeedbackQuestionModel | UpdateFeedbackQuestionModel>()

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'answers'
  })

  React.useEffect(() => {
    return () => {
      resetField('config')
      resetField('answers')
    }
  }, [])

  const handleOnAddAnswer = () => {
    append({
      id: Math.random().toString(36).substr(2, 9),
      texts: {
        answer: ''
      }
    })
  }

  return (
    <>
      <Typography sx={{
        fontWeight: 'bold',
        mb: 2
      }}
      >
        {t('common.answers')}
      </Typography>

      <Box>
        <SortableList
          items={fields}
          onDragEnd={(item, index) => move(index.old, index.new)}
          id="sortable_answers"
          grabber
          renderItem={(item, activeId, index, level, clone) => (
            <Box
              key={`choice_answer_${item.id}`}
              sx={{
                opacity: (activeId === item.id && !clone) ? 0 : 1,
                backgroundColor: 'secondary.200',
                mb: 2,
                border: '1px solid #000',
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box sx={{ flex: 0 }}>
                <SortableGrabber
                  id={item.id}
                >
                  <themeIcons.DragHandle />
                </SortableGrabber>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  ml: 1
                }}
              >
                <TextInput
                  formKey={`answers.${index}.texts.answer`}
                  hideValidationText
                  label={`${t('common.answer')} ${index + 1}`}
                  options={{
                    required: true
                  }}
                  sx={{
                    backgroundColor: 'white'
                  }}
                />
              </Box>
              <Box
                sx={{
                  flex: 0
                }}
              >
                <IconButton
                  onClick={() => remove(index)}
                  sx={{
                    py: 0,
                    px: 1
                  }}
                >
                  <themeIcons.Delete />
                </IconButton>
              </Box>
            </Box>
          )}
        />

        <AddDefaultRow
          label={t('feedbackQuestions.addAnswer')}
          onAdd={handleOnAddAnswer}
        />
      </Box>

      <Typography sx={{
        fontWeight: 'bold',
        my: 2
      }}
      >
        {t('common.settings')}
      </Typography>
      <Box
        sx={{
          mb: 1
        }}
      >
        <CheckboxInput
          formKey='config.multiple'
          label={t('feedbackQuestions.multiple')}
        />
      </Box>

      <Box
        sx={{
          mb: 1
        }}
      >
        <CheckboxInput
          formKey='config.other'
          label={t('feedbackQuestions.other')}
        />
      </Box>
    </>
  )
}
