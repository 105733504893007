import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LoginMutation, RefreshTokenMutation } from '@typings/graphql'

import type { RootState } from '../store'

type AuthUser = LoginMutation['login']['user'];

type AuthState = {
  user?: AuthUser;

  accessToken: string;
  refreshToken: string;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: undefined,

    accessToken: '',
    refreshToken: ''
  } as AuthState,
  reducers: {
    setAuthData: (
      state,
      { payload }: PayloadAction<LoginMutation['login']>
    ) => {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken

      state.user = { ...payload.user }
    },
    updateAuthUser: (
      state,
      { payload }: PayloadAction<LoginMutation['login']['user']>
    ) => {
      state.user = payload
    },
    resetAuthData: state => {
      state.accessToken = ''
      state.refreshToken = ''

      state.user = undefined
    },
    setAuthToken: (
      state,
      { payload }: PayloadAction<RefreshTokenMutation['refreshToken']>
    ) => {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken
    },
    setAnonymousToken: (
      state,
      { payload }: PayloadAction<RefreshTokenMutation['refreshToken']>
    ) => {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken

      state.user = undefined
    }
  }
})

export const { setAuthData, resetAuthData, setAuthToken, updateAuthUser, setAnonymousToken } =
  slice.actions

export default slice.reducer

export const isLoggedIn = (state: RootState): boolean => {
  return !!state.auth.user
}
export const getAuthUser = (state: RootState): AuthUser | undefined =>
  state.auth.user
