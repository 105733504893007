import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getGridBooleanOperators } from '@mui/x-data-grid-pro'
import { UserColumnType } from '@features/users/typings/types'

const RenderCell: React.FC<{ value: boolean }> = ({ value }) => {
  const { t } = useTranslation()

  return <Box
    sx={{
      color: value ? 'success.main' : 'warning.main'
    }}
  >
    {t(value ? 'common.active' : 'common.inactive')}
  </Box>
}

export const activeColumnModel: UserColumnType = {
  field: 'active',
  width: 75,
  filterOperators: getGridBooleanOperators(),
  renderCell: (params) => <RenderCell value={!!params.value} />
}
