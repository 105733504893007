import {
  CreateUserInput,
  CreateUserMutation,
  DeleteUserMutation,
  EventsMinimalQuery,
  InviteUserMutation,
  RolesQuery,
  UpdateUserInput,
  UpdateUserMutation,
  UserDataFragmentDoc,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEventsMinimalQuery,
  useInviteUserMutation,
  useRolesQuery,
  useUpdateUserMutation,
  useUsersQuery
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { FetchResult } from 'apollo-link'

import { UsersRowModel } from '../typings/types'

export type UsersDataProviderContextType = {
  userData: UsersRowModel[]
  loading: boolean
  roles: RolesQuery['roles']
  eventData: EventsMinimalQuery['events']
  handleDeleteUser: (id: string) => Promise<FetchResult<DeleteUserMutation>>
  handleInviteUser: (id: string) => Promise<FetchResult<InviteUserMutation>>
  handleCreateUser: (input: CreateUserInput) => Promise<FetchResult<CreateUserMutation>>
  handleUpdateUser: (id: string, input: UpdateUserInput) => Promise<FetchResult<UpdateUserMutation>>
}

const UsersDataProviderContext = React.createContext<UsersDataProviderContextType>(
  {} as any
)

export const UsersDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data, loading: dataLoading } = useUsersQuery({
    fetchPolicy: 'cache-and-network'
  })

  const userData = React.useMemo<UsersRowModel[]>(() => {
    return data?.users || []
  }, [data])

  const { data: roleData, loading: rolesLoading } = useRolesQuery({
    fetchPolicy: 'cache-and-network'
  })

  const { data: eventData, loading: eventsLoading } = useEventsMinimalQuery({
    fetchPolicy: 'cache-and-network'
  })

  const loading = useArtificialLoading(500, [dataLoading, rolesLoading, eventsLoading])

  const [deleteUser] = useDeleteUserMutation({
    update (cache, { data: updatedData }) {
      const deletedUser = updatedData?.deleteUser

      if (deletedUser) {
        cache.evict({
          id: cache.identify({
            __typename: 'User',
            id: deletedUser.id
          })
        })

        cache.gc()
      }
    }
  })
  const [inviteUser] = useInviteUserMutation()
  const [createUser] = useCreateUserMutation({
    update (cache, { data: updatedData }) {
      const createdUser = updatedData?.createUser

      if (createdUser) {
        cache.modify({
          fields: {
            users (existingUsers = []) {
              const newUserRef = cache.writeFragment({
                data: createdUser,
                fragment: UserDataFragmentDoc
              })

              return [...existingUsers, newUserRef]
            }
          }
        })
      }
    }
  })
  const [updateUser] = useUpdateUserMutation({
    update (cache, { data: updatedData }) {
      const updatedUser = updatedData?.updateUser

      if (updatedUser) {
        cache.modify({
          id: cache.identify({
            __typename: 'User',
            id: updatedUser.id
          }),
          fields: {
            email () {
              return updatedUser.email
            },
            firstName () {
              return updatedUser.firstname
            },
            lastName () {
              return updatedUser.lastname
            },
            role () {
              return updatedUser.role
            }
          }
        })
      }
    }
  })

  const handleInviteUser = React.useCallback(async (id: string) => {
    return await inviteUser({
      variables: {
        id
      }
    })
  }, [inviteUser])

  const handleDeleteUser = React.useCallback(async (id: string) => {
    return await deleteUser({
      variables: {
        id
      }
    })
  }, [])

  const handleCreateUser = React.useCallback(async (input: CreateUserInput) => {
    return await createUser({
      variables: {
        data: input
      }
    })
  }, [])

  const handleUpdateUser = React.useCallback(async (id: string, input: UpdateUserInput) => {
    return await updateUser({
      variables: {
        id,
        data: input
      }
    })
  }, [])

  const value = React.useMemo<UsersDataProviderContextType>(() => {
    return {
      userData,
      loading,
      roles: roleData?.roles || [],
      eventData: eventData?.events || [],
      handleInviteUser,
      handleDeleteUser,
      handleCreateUser,
      handleUpdateUser
    }
  }, [roleData, userData, loading])

  return (
    <>
      <UsersDataProviderContext.Provider value={value}>
        {children}
      </UsersDataProviderContext.Provider>
    </>
  )
}

export const useUsersContext = () => React.useContext(UsersDataProviderContext)
