import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SchemeCriteriaData } from '../typings/types'

type Props = {
  selected: string
  criteria: SchemeCriteriaData[]
  onChange: (value: string) => void
}

export const EvaluationCriteriaFilter: React.FC<Props> = ({ selected, criteria, onChange }) => {
  const { t } = useTranslation()

  return (
    <FormControl fullWidth>
      <Select
        fullWidth
        value={selected}
        onChange={(e) => onChange(e.target.value as string)}
        displayEmpty
        sx={{
          minWidth: 300
        }}
      >
        <MenuItem value="">
          {t('evaluation.overall')}
        </MenuItem>
        {criteria.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.texts.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
