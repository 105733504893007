import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { RatingInput } from '@shared/components/inputs/RatingInput'

import { SchemeCriteriaData, SchemeCriteriaRatingData, TeamsRowModel } from '../typings/types'
import { useTeamsContext } from '../provider/TeamsDataProvider'

type Props = {
  team: TeamsRowModel | null
}

export const TeamRatingInput: React.FC<Props> = ({ team }) => {
  const { schemeData } = useTeamsContext()

  const getMappedCriteria = React.useCallback((criteria: SchemeCriteriaData): SchemeCriteriaRatingData => {
    return {
      ...criteria,
      rating: team?.ratings?.find((rating) => rating.criteriaId === criteria.id)?.value || 0,
      children: criteria.children?.map((child) => {
        return getMappedCriteria(child)
      })
    }
  }, [schemeData])

  const mappedSchemeData = React.useMemo(() => {
    return schemeData?.schemeCriteria?.map((criteria) => getMappedCriteria(criteria)) || []
  }, [schemeData, team])

  return (
    <Box>
      {mappedSchemeData.map((criteria, index) => (
        <Box
          key={criteria.id}
        >
          <Typography variant="h6">
            {criteria.texts.title}
          </Typography>
          {criteria.children?.map((child) => (
            <Box
              key={child.id}
              sx={{ pb: 2 }}
            >
              <Typography
                sx={{
                  mb: 0.5
                }}
              >
                {child.texts.title}
              </Typography>
              <RatingInput
                formKey={`ratings.${child.id}`}
                scale={schemeData?.ratingScale}
              />
            </Box>
          ))}
          {index < mappedSchemeData.length - 1 && <Divider
            light
            sx={{
              my: 2
            }}
          />}
        </Box>
      ))}
    </Box>
  )
}
