import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import { palette } from '@theme/core/palette/palette'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'

export type MuiDataGridType = {
  defaultProps?: ComponentsProps['MuiDataGrid'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDataGrid'];
  variants?: ComponentsVariants['MuiDataGrid'];
}

export const MuiDataGrid:MuiDataGridType = {
  defaultProps: {
    rowHeight: 56
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      '.MuiDataGrid-withBorderColor': {
        outline: 'none!important'
      },
      '.MuiTablePagination-root': {
        '.MuiTablePagination-selectLabel': {
          fontSize: fontSizes.sm,
          fontWeight: 600,
          lineHeight: lineHeights.sm
        },
        '.MuiTablePagination-displayedRows': {
          fontSize: fontSizes.sm,
          fontWeight: 600,
          lineHeight: lineHeights.sm
        },
        '.MuiTablePagination-select': {
          fontSize: fontSizes.sm,
          fontWeight: 600,
          lineHeight: lineHeights.sm,
          paddingTop: '5px'
        }
      },
      '.MuiDataGrid-pinnedColumns': {
        boxShadow: 'none',
        '&.MuiDataGrid-pinnedColumns--left': {
          borderLeft: 'none',
          borderRight: `1px solid ${palette.grey?.[200]}`
        },
        '&.MuiDataGrid-pinnedColumns--right': {
          borderRight: 'none',
          borderLeft: `1px solid ${palette.grey?.[200]}`
        }
      },
      '.MuiDataGrid-pinnedColumnHeaders': {
        boxShadow: 'none',
        '.MuiDataGrid-columnSeparator': {
          color: 'transparent',
          '&:hover': {
            color: 'transparent'
          }
        },
        '&.MuiDataGrid-pinnedColumnHeaders--left': {
          borderLeft: 'none',
          borderRight: `1px solid ${palette.grey?.[200]}`
        },
        '&.MuiDataGrid-pinnedColumnHeaders--right': {
          borderRight: 'none',
          borderLeft: `1px solid ${palette.grey?.[200]}`
        }
      },
      '.MuiDataGrid-detailPanel': {
        overflow: 'visible'
      }
    },

    panelContent: {
      padding: '0 16px'
    },
    panelHeader: {
      padding: '16px!important'
    },
    panelWrapper: {
      '.MuiDataGrid-columnsPanel': {
        padding: '0'
      },
      '.MuiDataGrid-columnsPanelRow': {
        padding: '0'
      },
      'MuiSwitch-root': {
        marginLeft: '-5px',
        padding: '5px'
      }
    },
    overlay: {
      fontSize: fontSizes.lg,
      fontWeight: 600,
      backgroundColor: 'white'
    },
    columnHeaderTitle: {
      fontSize: fontSizes.sm,
      fontWeight: 600,
      lineHeight: lineHeights.sm
    },
    columnHeaders: {
      borderBottom: '1px solid #000000',
      minHeight: 'auto!important'
    },
    footerContainer: {
      minHeight: '64px'
    },
    row: {
      '&:hover': {
        backgroundColor: palette.secondary?.[300]
      },
      '&.Mui-selected': {
        backgroundColor: palette.secondary?.[200],
        '&:hover': {
          backgroundColor: palette.secondary?.[300]
        }
      },
      '&.MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: palette.secondary?.[100],
        '&:hover': {
          backgroundColor: palette.secondary?.[300]
        }
      }
    },
    cell: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.sm,
      fontWeight: 500,
      minHeight: '100%!important',
      maxHeight: '100%!important',
      maxWidth: 'inherit',
      '.MuiTypography-root': {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      '&.MuiDataGrid-cellCheckbox': {
        paddingRight: 0
      },
      button: {
        '.MuiSvgIcon-root': {
          width: '20px',
          height: '20px'
        }
      }
    },
    columnHeader: {
      height: '44px!important',
      '&.MuiDataGrid-cell:focus-within, &.MuiDataGrid-cell:focus': {
        outline: 'none !important'
      },
      '&.MuiDataGrid-columnHeaderCheckbox': {
        paddingRight: 0
      }
    },
    columnSeparator: {
      color: 'transparent'
    },
    filterForm: {
      '.MuiSelect-standard': {
        '&.MuiInputBase-input': {
          lineHeight: '23px',
          padding: '4px 16px 5px 0',
          fontWeight: 400,
          fontSize: fontSizes.md
        }
      }
    },
    filterFormValueInput: {
      width: '250px'
    },
    filterFormOperatorInput: {
      width: '200px'
    },
    filterFormColumnInput: {
      width: '200px'
    }
  }
}
