import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material'

export type MuiAlertType = {
  defaultProps?: ComponentsProps['MuiAlert'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlert'];
  variants?: ComponentsVariants['MuiAlert'];
};

export const MuiAlert: MuiAlertType = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      border: 'solid 1px',
      borderColor: '#000000',
      boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
      '.MuiAlert-icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }
}
