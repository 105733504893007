import React from 'react'

export const useArtificialLoading = (
  loadingTime: number,
  dep: boolean[]
): boolean => {
  const [artificialLoading, setArtificialLoading] = React.useState(true)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setArtificialLoading(false)
    }, loadingTime)

    return () => {
      clearTimeout(timeout)
    }
  }, [dep, loadingTime])

  const loading = React.useMemo(() => {
    return artificialLoading || dep.some(d => d)
  }, [artificialLoading, dep])

  return loading
}
