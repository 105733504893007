import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSnackbar } from '@hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useNavigate, useParams } from 'react-router'
import { themeIcons } from '@theme/icons'
import { fontSizes } from '@theme/core/typography/typography'
import { useCheckValidTokenQuery, useResetPasswordMutation } from '@typings/graphql'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'

import { PasswordResetInput } from '../components/PasswordResetInput'

export type PasswordResetInputType = {
  password: string
}

export const PasswordResetViewContent: React.FC = () => {
  const { t } = useTranslation()
  const { token } = useParams<{token: string}>()
  const navigate = useNavigate()

  const { data: validToken, loading: validTokenLoading } = useCheckValidTokenQuery({
    variables: {
      token: token as string
    },
    skip: !token
  })

  const [resetPassword] = useResetPasswordMutation()

  const { handleSubmit, formState: { isDirty, isValid } } = useFormContext<PasswordResetInputType>()

  const { message, handleError, handleSuccess, setShowSnackbar, showSnackbar } = useSnackbar()
  const initialLoading = useArtificialLoading(1000, [validTokenLoading])

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const submit = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await resetPassword({
        variables: {
          token: token as string,
          password: data.password
        }
      })

      handleSuccess(t('auth.passwordChanged'))
      setSuccess(true)
    } catch (error: any) {
      handleError(error)
      setSuccess(false)
    } finally {
      setLoading(false)
      setShowSnackbar(true)
    }
  })

  const navigateLogin = () => {
    navigate('/login')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        pt: 0.5
      }}
    >
      {initialLoading
        ? (
          <CircularProgress
            sx={{
              mt: 3
            }}
          />
          )
        : (
          <Box
            sx={{
              width: '100%'
            }}
          >
            {(!validToken?.checkValidToken || success) && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
                <Typography
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  {success ? t('auth.passwordChangedSuccess') : t('error.invalidToken')}
                </Typography>
                <Button
                  variant='text'
                  sx={{
                    mt: 2,
                    fontSize: fontSizes.sm
                  }}
                  onClick={navigateLogin}
                  startIcon={<themeIcons.ArrowBack />}
                >
                  {t('auth.toLogin')}
                </Button>
              </Box>
            )}
            {validToken?.checkValidToken && !success && (
              <form>
                <PasswordResetInput />

                <Box>
                  <ElevatedButton
                    onClick={submit}
                    loading={loading}
                    disabled={!isDirty || !isValid }
                    sx={{
                      mt: 2
                    }}
                  >
                    {t('action.send')}
                  </ElevatedButton>
                </Box>

              </form>
            )}
          </Box>
          )}

      <DefaultSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        severity={success ? 'success' : 'error'}
        message={message}
        sx={{
          position: 'fixed'
        }}
      />
    </Box>
  )
}

export const PasswordResetView: React.FC = () => {
  const formData = useForm<PasswordResetInputType>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <PasswordResetViewContent />
    </FormProvider>
  )
}
