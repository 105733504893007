import { ComponentsOverrides, Theme } from '@mui/material'

export type MuiCssBaselineType = {
  styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline'];
};

export const MuiCssBaseline: MuiCssBaselineType = {
  styleOverrides: {
    html: {
      height: '100%',
      overflow: 'hidden',
      body: {
        height: '100%',
        overscrollBehaviorX: 'none',
        overflow: 'hidden',

        '#root': {
          height: '100%'
        }
      }
    }
  }
}
