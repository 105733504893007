import { Box, BoxProps, IconButton, Modal, Typography, useTheme } from '@mui/material'
import { fontSizes } from '@theme/core/typography/typography'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
  title?: string
  children?: React.ReactElement | null
  containerProps?: BoxProps
}

export const BaseModal: React.FC<Props> = ({ title, children, onClose, containerProps, open }) => {
  const { breakpoints } = useTheme()
  const { sx: containerSx, ...restContainerProps } = containerProps || {}

  return (

    <Modal
      onClose={onClose}
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{
        maxWidth: '80%',
        maxHeight: '80%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: '#000000',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        [breakpoints.down('md')]: {
          maxWidth: '95%',
          maxHeight: '95%'
        },
        ...containerSx
      }}
        {...restContainerProps}
      >
        <Box sx={{
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1.5,
          borderBottom: 'solid 1px',
          borderColor: '#000000'
        }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            <Typography variant="h4"
              sx={{
                [breakpoints.down('md')]: {
                  fontSize: fontSizes.md,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 0
            }}
          >
            <IconButton
              onClick={() => onClose()}
            >
              <themeIcons.Cancel />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Box>
    </Modal>
  )
}
