import { Box } from '@mui/material'
import React from 'react'
import { RegisterOptions, useFormContext, Controller } from 'react-hook-form'

import { RatingNumberBox } from '../ui/RatingNumberBox'

export type RatingInputProps = {
  formKey: string
  options?: RegisterOptions
  scale?: number
  stars?: boolean
  sameColor?: boolean
  disabled?: boolean
}

export const RatingInput: React.FC<RatingInputProps> = ({ formKey, scale = 10, stars, sameColor, disabled, options }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { value, onChange } }) => (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'auto'
        }}
        >
          {new Array(scale).fill(0).map((_, index) => (
            <RatingNumberBox
              size={`${100 / scale}%`}
              key={`${formKey}_${index}`}
              value={index + 1}
              onChange={onChange}
              stars={stars}
              backgroundColor={index + 1 < value
                ? (sameColor ? 'primary.main' : 'secondary.main')
                : value === index + 1 ? 'primary.main' : '#ffffff '}
              color={index + 1 < value
                ? (sameColor ? '#ffffff' : '#000000')
                : value === index + 1 && stars ? '#ffffff' : '#000000 '}
            />
          ))}
        </Box>
      )
    }
    />
  )
}
