import { DEFAULT_INPUT_LENGTH } from '@features/feedbackQuestions/constants/feedbackQuestions'
import { Typography } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const FeedbackQuestionOpenInput: React.FC = () => {
  const { t } = useTranslation()

  const { resetField, setValue, getValues } = useFormContext()

  React.useEffect(() => {
    const currentConfig = getValues('config')

    setValue('config.maxLength', currentConfig?.maxLength || DEFAULT_INPUT_LENGTH)

    return () => {
      resetField('config.maxLength')
    }
  }, [])

  return (
    <>
      <Typography sx={{
        fontWeight: 'bold',
        mb: 2
      }}
      >
        {t('common.settings')}
      </Typography>

      <TextInput
        formKey='config.maxLength'
        label={t('feedbackQuestions.maxLength')}
        options={{
          required: true
        }}
        type='number'
      />
    </>
  )
}
