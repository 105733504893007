import { Box, BoxProps, SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

type Props = BoxProps & {
  visuals?: boolean,
  contentContainerProps?: BoxProps
}

const BottomLeftVisual: React.FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    width="177"
    height="286"
    viewBox="0 0 177 286"
    fill="none"

    sx={{
      width: '177px',
      height: '286px',
      ...sx
    }}
    {...props}
  >
    <path d="M86.029 1.21861V109.166V109.668L86.5309 109.666L175.784 109.324L86.1763 198.478L86.029 198.624V198.793H85.861L85.7145 198.939L74.2081 210.446L74.0616 210.592V210.799V211.053C56.8423 228.271 43.064 242.049 27.5201 257.594C19.2702 265.844 10.523 274.592 0.5 284.615V86.7361C12.219 75.0176 22.2036 65.0891 31.6692 55.6767C49.0344 38.4091 64.6529 22.8784 86.029 1.21861Z" fill="transparent" stroke="black"/>
  </SvgIcon>
)

const TopRightVisual: React.FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    width="177"
    height="286"
    viewBox="0 0 177 286"
    fill="none"
    sx={{
      width: '177px',
      height: '286px',
      ...sx
    }}
    {...props}
  >
    <path d="M90.971 284.603V176.656V176.154L90.4691 176.156L1.21614 176.498L90.8237 87.3444L90.971 87.1978V87.029H91.139L91.2855 86.8825L102.792 75.3761L102.938 75.2297V75.0226V74.7688C120.158 57.5515 133.936 43.7726 149.48 28.228C157.73 19.9777 166.477 11.2301 176.5 1.20709V199.086C164.781 210.804 154.797 220.733 145.331 230.145C127.966 247.413 112.347 262.944 90.971 284.603Z" fill="transparent" stroke="black"/>
  </SvgIcon>
)

export const ShadowCard: React.FC<Props> = ({
  sx,
  visuals,
  contentContainerProps,
  children,
  ...props
}) => {
  const { sx: contentContainerSx, ...contentContainerRest } = contentContainerProps || {}

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'red',
        ...sx
      }}
      {...props}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid black',
          height: '100%',
          width: '100%',
          zIndex: 2,
          position: 'relative',
          p: 4,
          boxShadow: '6px 6px 0px 0px #000000',
          ...contentContainerSx
        }}
        {...contentContainerRest}
      >
        {children}
      </Box>
      {visuals && (
        <>
          <Box sx={{
            position: 'absolute',
            zIndex: 0,
            top: 0,
            right: 0,
            transform: 'translate(15%, -35%)'
          }}
          >
            <TopRightVisual />
          </Box>
          <Box sx={{
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
            left: 0,
            transform: 'translate(-15%, 35%)'
          }}
          >
            <BottomLeftVisual />
          </Box>
        </>
      )}
    </Box>
  )
}
