import { createdColumnModel } from '@shared/dataGrid/columns/createdColumn'

import { TeamColumnType } from '../typings/types'

import { nameColumnModel } from './columns/nameColumn'
import { membersCountColumn } from './columns/membersCountColumn'
import { codeColumnModel } from './columns/codeColumn'
import { submittedColumnModel } from './columns/submittedColumn'
import { repositoryColumnModel } from './columns/repositoryColumn'
import { ratingStateColumnModel } from './columns/ratingStateColumn'
import { activeColumnModel } from './columns/activeColumn'
import { challengeColumnModel } from './columns/challengeColumn'
import { contactColumnModel } from './columns/contactColumn'

export const teamsDataGridColumns: TeamColumnType[] = [
  nameColumnModel,
  codeColumnModel,
  membersCountColumn,
  challengeColumnModel,
  contactColumnModel,
  submittedColumnModel,
  repositoryColumnModel,
  ratingStateColumnModel,
  activeColumnModel,
  createdColumnModel as TeamColumnType
]
