import i18n from '@services/i18n/i18n'

import { BaseColumnType } from '../typings/types'

export const createdColumnModel: BaseColumnType = {
  field: 'createdAt',
  width: 200,
  filterable: false,
  renderCell: (params) => {
    const date = new Date(params.value as string)

    return date.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    })
  }
}
