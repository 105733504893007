import React from 'react'
import { TeamColumnType, TeamsRowModel } from '@features/teams/typings/types'
import { Link, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { constFormatUrl } from '@utils/format'

const RenderCell: React.FC<TeamsRowModel> = ({ teamResult }) => {
  const { t } = useTranslation()

  const formattedUrl = React.useMemo(() => {
    if (!teamResult?.repository) {
      return ''
    }

    return constFormatUrl(teamResult?.repository)
  }, [teamResult])

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (!formattedUrl) {
      return
    }

    window.open(formattedUrl, '_blank')
  }

  return (
    teamResult?.repository
      ? (
        <Tooltip title={formattedUrl}>
          <Link
            sx={{
              cursor: 'pointer'
            }}
            onClick={handleLinkClick}
          >
            {t('teams.linkRepository')}
          </Link>
        </Tooltip>
        )
      : '-'
  )
}

export const repositoryColumnModel: TeamColumnType = {
  field: 'repository',
  width: 125,
  minWidth: 125,
  filterable: false,
  valueGetter: (params) => params.row.teamResult?.repository || '-',
  renderCell: (params) => <RenderCell {...params.row} />
}
