import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { mailPattern } from '@utils/pattern'
import { useTranslation } from 'react-i18next'
import { FormSelectInput } from '@shared/components/inputs/FormSelectInput'
import { Box } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { Roles } from '@typings/roles'

import { useUsersContext } from '../provider/UsersDataProvider'

export const UserCreateEditInputs: React.FC = () => {
  const { t } = useTranslation()

  const { roles, eventData } = useUsersContext()
  const { control } = useFormContext()

  const mappedRoles = React.useMemo(() => {
    return roles.map((role) => ({
      id: role.id,
      name: t(`roles.${role.key}`)
    }))
  }, [roles])

  const mappedEvents = React.useMemo(() => {
    return eventData.map((event) => ({
      id: event.id,
      name: event.name
    }))
  }, [roles])

  const watchedRole = useWatch({
    name: 'roleId',
    control
  })

  const isAdmin = React.useMemo(() => {
    return roles.find((role) => role.id === watchedRole)?.key === Roles.ADMIN
  }, [watchedRole, roles])

  return (
    <>
      <TextInput
        formKey="email"
        label={t('common.email')}
        type="email"
        hideMaxLength
        options={{
          required: true,
          pattern: mailPattern
        }}
        validationText={t('error.input.invalidEmail')}
      />

      <TextInput
        formKey="firstname"
        label={t('common.firstname')}
        sx={{
          mt: 2
        }}
        options={{
          required: true
        }}
      />

      <TextInput
        formKey="lastname"
        label={t('common.lastname')}
        sx={{
          mt: 2
        }}
        options={{
          required: true
        }}
      />

      <Box sx={{
        mt: 2
      }}
      >
        <FormSelectInput
          items={mappedRoles}
          formKey="roleId"
          label={t('common.role')}
          options={{
            required: true
          }}
        />
      </Box>

      {!isAdmin && (
        <Box sx={{
          mt: 5.5
        }}
        >
          <FormSelectInput
            items={mappedEvents}
            formKey="eventIds"
            multiple
            sx={{
              maxWidth: '100%'
            }}
            label={t('common.events')}
          />
        </Box>
      )}

    </>
  )
}
