import { IconButton, IconButtonProps } from '@mui/material'
import { themeIcons } from '@theme/icons'
import React from 'react'

type Props = IconButtonProps & {
  icon: keyof typeof themeIcons
}

export const DefaultIconButton:React.FC<Props> = ({ icon, sx, ...props }) => {
  const Icon = React.useMemo(() => themeIcons[icon], [icon])

  return (
    <IconButton
      sx={{
        minWidth: 'auto',
        borderRadius: '0',
        border: '1px solid #000',
        p: '11px',
        '.MuiButton-startIcon': {
          m: 0
        },
        '&:hover': {
          backgroundColor: 'primary.main'
        },
        ...sx
      }}
      {...props}
    >
      <Icon sx={{
        width: '20px',
        height: '20px'
      }}
      />
    </IconButton>
  )
}
