import React from 'react'
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '@services/apollo/apollo'
import { store } from '@services/store/store'
import { theme } from '@theme/theme'
import '@services/i18n/i18n'

import { RoutesProvider } from './RoutesProvider'
import { DialogProvider } from './DialogProvider'

const CoreProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DialogProvider>
            <RoutesProvider />
          </DialogProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default CoreProvider
