import { QuestionEvaluationTypes } from '@features/feedbackEvaluation/typings/types'
import { FeedbackQuestionType } from '@typings/graphql'

import { FeedbackEvaluationRating } from './FeedbackEvaluationRating'
import { FeedbackEvaluationOpen } from './FeedbackEvaluationOpen'
import { FeedbackEvaluationChoice } from './FeedbackEvaluationChoice'

export const feedbackEvaluationQuestionTypes: QuestionEvaluationTypes = {
  [FeedbackQuestionType.Choice]: FeedbackEvaluationChoice,
  [FeedbackQuestionType.Open]: FeedbackEvaluationOpen,
  [FeedbackQuestionType.Rating]: FeedbackEvaluationRating
}
