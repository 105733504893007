import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material'

export type MuiFormControlLabelType = {
  defaultProps?: ComponentsProps['MuiFormControlLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel'];
  variants?: ComponentsVariants['MuiFormControlLabel'];
};

export const MuiFormControlLabel: MuiFormControlLabelType = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      '.MuiCheckbox-root': {
        marginRight: '8px'
      }
    }
  }
}
