import React from 'react'
import {
  CreateFeedbackQuestionInput,
  CreateFeedbackQuestionMutation,
  DeleteFeedbackQuestionMutation,
  FeedbackQuestionsDocument,
  ReorderFeedbackQuestionsMutation,
  UpdateFeedbackQuestionInput,
  UpdateFeedbackQuestionMutation,
  useCreateFeedbackQuestionMutation,
  useDeleteFeedbackQuestionMutation,
  useFeedbackQuestionsQuery,
  useReorderFeedbackQuestionsMutation,
  useUpdateFeedbackQuestionMutation
} from '@typings/graphql'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from 'apollo-link'
import { mapTextsForMutation } from '@utils/translations'

import { FeedbackQuestionsModel } from '../typings/types'

export type FeedbackQuestionsDataProviderContextType = {
  feedbackData: FeedbackQuestionsModel[]
  loading: boolean,
  handleDeleteQuestion: (id: string) => Promise<FetchResult<DeleteFeedbackQuestionMutation>>
  handleUpdateQuestion: (id: string, input: UpdateFeedbackQuestionInput) => Promise<FetchResult<UpdateFeedbackQuestionMutation>>
  handleCreateQuestion: (input: CreateFeedbackQuestionInput) => Promise<FetchResult<CreateFeedbackQuestionMutation>>
  handleReorderQuestion: (order: FeedbackQuestionsModel[]) => Promise<FetchResult<ReorderFeedbackQuestionsMutation>>
}

const FeedbackQuestionsDataProviderContext = React.createContext<FeedbackQuestionsDataProviderContextType>(
  {} as any
)

export const FeedbackQuestionsDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const { data, loading: feedbackDataLoading } = useFeedbackQuestionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string
    },
    skip: !id
  })

  const [createQuestion] = useCreateFeedbackQuestionMutation({
    update (cache, { data: updatedData }) {
      const created = updatedData?.createFeedbackQuestion

      if (created) {
        cache.updateQuery({
          query: FeedbackQuestionsDocument,
          variables: {
            eventId: id as string
          }
        }, (questionData) => {
          const feedbackData = questionData?.feedbackQuestions || []

          return {
            feedbackQuestions: [...feedbackData, created].sort((a, b) => a.order - b.order)
          }
        })
      }
    }
  })
  const [updateQuestion] = useUpdateFeedbackQuestionMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.updateFeedbackQuestion

      if (updated) {
        cache.modify({
          id: cache.identify({
            __typename: 'FeedbackQuestion',
            id: updated.id
          }),
          fields: {
            texts () {
              return updated.texts
            },
            config () {
              return updated.config
            }
          }
        })
      }
    }
  })
  const [reorderQuestion] = useReorderFeedbackQuestionsMutation({
    update (cache, { data: updatedData }) {
      const updated = updatedData?.reorderFeedbackQuestions

      if (updated) {
        for (const item of updated) {
          cache.modify({
            id: cache.identify({
              __typename: 'FeedbackQuestion',
              id: item.id
            }),
            fields: {
              order () {
                return item.order
              }
            }
          })
        }
      }
    }
  })
  const [deleteQuestion] = useDeleteFeedbackQuestionMutation({
    update (cache, { data: updatedData }) {
      const deleted = updatedData?.deleteFeedbackQuestion

      if (deleted) {
        cache.evict({
          id: cache.identify({
            __typename: 'FeedbackQuestion',
            id: deleted.id
          })
        })

        cache.gc()
      }
    }
  })

  const feedbackData = React.useMemo<FeedbackQuestionsModel[]>(() => {
    return data?.feedbackQuestions || []
  }, [data])

  const loading = useArtificialLoading(500, [feedbackDataLoading])

  const handleUpdateQuestion = React.useCallback(async (questionId: string, input: UpdateFeedbackQuestionInput) => {
    const { texts, answers, config } = input

    return await updateQuestion({
      variables: {
        id: questionId,
        data: {
          config,
          texts: mapTextsForMutation(texts as Record<string, any>),
          answers: answers?.map((item, index) => ({
            order: index,
            texts: mapTextsForMutation(item.texts as Record<string, any>)
          })) || []
        }
      }
    })
  }, [])

  const handleCreateQuestion = React.useCallback(async (input: CreateFeedbackQuestionInput) => {
    const { texts, answers, ...rest } = input

    return await createQuestion({
      variables: {
        data: {
          ...rest,
          eventId: id as string,
          order: feedbackData.length,
          texts: mapTextsForMutation(texts as Record<string, any>),
          answers: answers?.map((item, index) => ({
            order: index,
            texts: mapTextsForMutation(item.texts as Record<string, any>)
          })) || []
        }
      }
    })
  }, [])

  const handleDeleteQuestion = React.useCallback(async (questionId: string) => {
    return await deleteQuestion({
      variables: {
        id: questionId
      }
    })
  }, [])

  const handleReorderQuestion = React.useCallback(async (order: FeedbackQuestionsModel[]) => {
    return await reorderQuestion({
      variables: {
        eventId: id as string,
        order: order.map((item) => item.id)
      }
    })
  }, [])

  const value = React.useMemo<FeedbackQuestionsDataProviderContextType>(() => {
    return {
      feedbackData,
      loading,
      handleCreateQuestion,
      handleUpdateQuestion,
      handleDeleteQuestion,
      handleReorderQuestion
    }
  }, [feedbackData, loading])

  return (
    <>
      <FeedbackQuestionsDataProviderContext.Provider value={value}>
        {children}
      </FeedbackQuestionsDataProviderContext.Provider>
    </>
  )
}

export const useFeedbackQuestionsContext = () => React.useContext(FeedbackQuestionsDataProviderContext)
