import { Box, BoxProps, Divider, Typography, LinearProgress } from '@mui/material'
import React from 'react'

type Props = React.PropsWithChildren & {
  title: string | React.ReactNode,
  description?: string,
  loading?: boolean,
  headerContent?: React.ReactNode,
  boxProps?: BoxProps
}

export const AdminContentLayout: React.FC<Props> = ({ title, description, children, headerContent, loading, boxProps }) => {
  const { sx, ...props } = boxProps || {}
  return (
    <Box sx={{
      flex: 1,
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      ...sx
    }}
      {...props}
    >
      <Box sx={{
        flex: 0,
        px: 4
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          py: 2
        }}
        >
          <Box flex={1}>
            <Typography variant="h3">{title}</Typography>
            {description && <Typography >{description}</Typography>}
          </Box>
          <Box sx={{
            flex: '0 1 auto'
          }}
          >
            {headerContent}
          </Box>

        </Box>
        <Divider />
      </Box>

      {loading
        ? (
          <Box sx={{ px: 4 }}>
            <LinearProgress />
          </Box>
          )
        : (

          <Box sx={{
            flex: 1,
            overflow: 'hidden'
          }}
          >
            <Box sx={{
              height: '100%'
            }}
            >
              <Box sx={{
                height: '100%',
                overflow: 'auto',
                p: 4,
                pt: 2
              }}
              >
                {children}
              </Box>
            </Box>
          </Box>
          )}
    </Box>
  )
}
