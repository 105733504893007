import {
  DataGridPro,
  DataGridProProps,
  useGridApiRef,
  GridFilterModel,
  GridSortModel,
  GridPaginationModel
} from '@mui/x-data-grid-pro'
import React from 'react'
import { DATA_GIRD_PAGE_SIZE } from '@shared/dataGrid/constants/dataGrid'

import { useDataGridTranslation } from './hooks/useDataGridTranslation'
import { DataGridToolbar } from './components/DataGridToolbar'
import { DataGridFilterPanel } from './components/DataGridFilterPanel'

type Props = DataGridProProps

export const BaseDataGrid: React.FC<Props> = (props) => {
  const dataGridTranslation = useDataGridTranslation()

  const dataGridRef = useGridApiRef()

  const {
    checkboxSelection,
    onRowSelectionModelChange,
    filterModel,
    sortModel,
    paginationModel,
    loading,
    rowCount,
    pinnedColumns,
    ...restProps
  } = props

  const [basePaginationModel, setBasePaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: DATA_GIRD_PAGE_SIZE
  })
  const [baseFilterModel, setBaseFilterModel] = React.useState<GridFilterModel>()
  const [baseSortModel, setBaseSortModel] = React.useState<GridSortModel>()

  const showCheckbox = React.useMemo(() => {
    if (checkboxSelection === undefined) {
      return true
    }

    return checkboxSelection
  }, [checkboxSelection])

  return (
    <DataGridPro
      apiRef={dataGridRef}
      checkboxSelection={showCheckbox}
      disableRowSelectionOnClick
      pagination
      pinnedColumns={{ right: ['action'], ...pinnedColumns }}
      pageSizeOptions={[10, 25, 50]}
      paginationModel={paginationModel || basePaginationModel}
      filterModel={filterModel || baseFilterModel}
      onFilterModelChange={setBaseFilterModel}
      sortModel={sortModel || baseSortModel}
      onSortModelChange={setBaseSortModel}
      onPaginationModelChange={setBasePaginationModel}
      loading={loading}
      localeText={dataGridTranslation}
      rowCount={rowCount}
      slots={{
        toolbar: DataGridToolbar,
        filterPanel: DataGridFilterPanel
      }}
      slotProps={{
        filterPanel: {
          logicOperators: []
        },
        baseSelect: { native: false }
      }}
      onRowSelectionModelChange={showCheckbox ? onRowSelectionModelChange : undefined}
      {...restProps}
    />
  )
}
