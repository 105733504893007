import React from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'

import { useFeedbackContext } from '../provider/FeedbackDataProvider'

import { FeedbackQuestion } from './questions/FeedbackQuestion'

export const FeedbackSubmissionInputs: React.FC = () => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const { eventData } = useFeedbackContext()

  const feedbackQuestions = React.useMemo(() => {
    return eventData?.feedbackQuestions || []
  }, [eventData])

  return (
    <Box>
      {feedbackQuestions.map((question, index) => (
        <Box
          key={question.id}
        >
          <Typography
            variant='h5'
            fontWeight="bold"
            sx={{
              [breakpoints.down('md')]: {
                fontSize: fontSizes.sm,
                lineHeight: lineHeights.sm
              }
            }}
          >
            {t('feedbackSubmission.question', { question: question.texts.question, order: question.order + 1 })}
          </Typography>

          <Box
            sx={{
              mt: 1
            }}
          >
            <FeedbackQuestion question={question} />
          </Box>

          {index < feedbackQuestions.length - 1 && (
            <Divider sx={{
              my: 3
            }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
