import { EditableFile } from '@typings/files'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BaseModal } from '../ui/BaseModal'

import { modalPreviewContent } from './preview/modalPreviewContent'

type Props = {
  open: boolean
  onClose: () => void
  file?: EditableFile<any> | null
}

export const FilePreviewModal:React.FC<Props> = ({ file, onClose, open }) => {
  const { t } = useTranslation()

  const fileInfo = React.useMemo(() => {
    const src = file?.upload?.preview || file?.remote?.url
    const type = file?.upload?.file?.type || file?.remote?.mimeType
    const name = file?.upload?.file?.name || file?.remote?.fileName

    if (!src || !type) {
      return null
    }

    return {
      name,
      src,
      type
    }
  }, [file])

  const PreviewContent = React.useMemo(() => {
    if (!fileInfo) {
      return null
    }

    if (fileInfo.type.startsWith('image')) {
      return modalPreviewContent.image
    } else if (fileInfo.type.startsWith('video')) {
      return modalPreviewContent.video
    } else if (fileInfo.type.startsWith('application/pdf')) {
      return modalPreviewContent.pdf
    }

    return null
  }, [fileInfo])

  return (
    <BaseModal
      title={t('files.previewFile', { fileName: fileInfo?.name })}
      onClose={onClose}
      open={open}
    >
      {PreviewContent && fileInfo && <PreviewContent src={fileInfo?.src} type={fileInfo?.type} />}
    </BaseModal>
  )
}
