import {
  ActivateTeamMutation,
  CreateOrUpdateRatingsMutation,
  CreateTeamInput,
  CreateTeamMutation,
  DeleteTeamMutation,
  RatingState,
  ResendVerificationCodeMutation,
  TeamDataFragmentDoc,
  UpdateTeamInput,
  UpdateTeamMutation,
  useActivateTeamMutation,
  useChallengesQuery,
  useCreateOrUpdateRatingsMutation,
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useResendVerificationCodeMutation,
  useSchemeByEventQuery,
  useTeamsWithRatingQuery,
  useUpdateTeamMutation
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { FetchResult } from 'apollo-link'
import { useParams } from 'react-router'

import { ChallengesModel, CreateRatingsInput, SchemeModel, TeamsRowModel } from '../typings/types'

export type TeamsDataProviderContextType = {
  teamsData: TeamsRowModel[]
  challengesData: ChallengesModel[]
  schemeData: SchemeModel
  loading: boolean
  handleDeleteTeam: (id: string) => Promise<FetchResult<DeleteTeamMutation>>
  handleActivateTeam: (id: string) => Promise<FetchResult<ActivateTeamMutation>>
  handleRateTeam: (id: string, input: CreateRatingsInput) => Promise<FetchResult<CreateOrUpdateRatingsMutation>>
  handleCreateTeam: (input: CreateTeamInput) => Promise<FetchResult<CreateTeamMutation>>
  handleUpdateTeam: (id: string, input: UpdateTeamInput) => Promise<FetchResult<UpdateTeamMutation>>
  handleResendCode: (id: string) => Promise<FetchResult<ResendVerificationCodeMutation>>
}

const TeamsDataProviderContext = React.createContext<TeamsDataProviderContextType>(
  {} as any
)

export const TeamsDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id: eventId } = useParams<{id: string}>()

  const { data: challengesData, loading: challengesDataLoading } = useChallengesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: eventId as string
    },
    skip: !eventId
  })

  const { data: schemeData, loading: schemeLoading } = useSchemeByEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: eventId as string
    },
    skip: !eventId
  })

  const { data, loading: dataLoading } = useTeamsWithRatingQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: eventId as string,
      own: true
    },
    skip: !eventId
  })

  const teamsData = React.useMemo<TeamsRowModel[]>(() => {
    return data?.teams || []
  }, [data])

  const loading = useArtificialLoading(500, [dataLoading, schemeLoading, challengesDataLoading])

  const [deleteTeam] = useDeleteTeamMutation({
    update (cache, { data: updatedData }) {
      const deletedTeam = updatedData?.deleteTeam

      if (deletedTeam) {
        cache.evict({
          id: cache.identify({
            __typename: 'Team',
            id: deletedTeam.id
          })
        })

        cache.gc()
      }
    }
  })

  const [createTeam] = useCreateTeamMutation({
    update (cache, { data: updatedData }) {
      const createdTeam = updatedData?.createTeam

      if (createdTeam) {
        cache.modify({
          fields: {
            teams (existingTeams = []) {
              const newTeamRef = cache.writeFragment({
                data: createdTeam,
                fragment: TeamDataFragmentDoc,
                fragmentName: 'TeamData'
              })

              return [...existingTeams, newTeamRef]
            }
          }
        })
      }
    }
  })

  const [updateTeam] = useUpdateTeamMutation({
    update (cache, { data: updatedData }) {
      const updatedTeam = updatedData?.updateTeam

      if (updatedTeam) {
        cache.modify({
          id: cache.identify({
            __typename: 'Team',
            id: updatedTeam.id
          }),
          fields: {
            name () {
              return updatedTeam.name
            }
          }
        })
      }
    }
  })

  const [resendVerificationCode] = useResendVerificationCodeMutation()

  const [rateTeam] = useCreateOrUpdateRatingsMutation({
    update (cache, { data: updatedData }) {
      const updatedRatings = updatedData?.createOrUpdateRatings || []

      const mappedByTeams = updatedRatings.reduce((acc, rating) => {
        const { teamId } = rating

        if (!acc[teamId]) {
          acc[teamId] = []
        }

        acc[teamId].push(rating)

        return acc
      }, {} as Record<string, CreateOrUpdateRatingsMutation['createOrUpdateRatings'][0][]>)

      Object.entries(mappedByTeams).forEach(([teamId, ratings]) => {
        cache.modify({
          id: cache.identify({
            __typename: 'Team',
            id: teamId
          }),
          fields: {
            ratings () {
              return ratings
            },
            ratingState () {
              if (ratings.length === 0) {
                return RatingState.None
              }

              return ratings.length === schemeData?.schemeByEvent?.ratingCriteriaCount ? RatingState.Full : RatingState.Partial
            }
          }
        })
      }, {})
    }
  })

  const [activateTeam] = useActivateTeamMutation({
    update (cache, { data: updatedData }) {
      const activatedTeam = updatedData?.activateTeam

      if (activatedTeam) {
        cache.modify({
          id: cache.identify({
            __typename: 'Team',
            id: activatedTeam.id
          }),
          fields: {
            active () {
              return true
            }
          }
        })
      }
    }
  })

  const handleDeleteTeam = React.useCallback(async (id: string) => {
    return await deleteTeam({
      variables: {
        id
      }
    })
  }, [])

  const handleCreateTeam = React.useCallback(async (input: CreateTeamInput) => {
    const { teamMembers, ...rest } = input
    return await createTeam({
      variables: {
        data: {
          ...rest,
          teamMembers: teamMembers?.map((item) => ({
            firstName: item.firstName,
            lastName: item.lastName
          })) || [],
          ...(eventId && { eventId })
        }
      }
    })
  }, [])

  const handleUpdateTeam = React.useCallback(async (id: string, input: UpdateTeamInput) => {
    const { teamMembers, ...rest } = input

    return await updateTeam({
      variables: {
        id,
        data: {
          ...rest,
          teamMembers: teamMembers?.map((item) => ({
            firstName: item.firstName,
            lastName: item.lastName
          })) || []
        }
      }
    })
  }, [])

  const handleRateTeam = React.useCallback(async (id: string, input: CreateRatingsInput) => {
    return await rateTeam({
      variables: {
        data: Object.entries(input.ratings).map(([criteriaId, value]) => ({
          criteriaId,
          teamId: id,
          value
        })).filter((rating) => rating.value !== null && rating.value !== undefined)
      }
    })
  }, [])

  const handleActivateTeam = React.useCallback(async (id: string) => {
    return await activateTeam({
      variables: {
        id
      }
    })
  }, [])

  const handleResendCode = async (id: string) => {
    return await resendVerificationCode({
      variables: {
        id
      }
    })
  }

  const value = React.useMemo<TeamsDataProviderContextType>(() => {
    return {
      teamsData,
      challengesData: challengesData?.challenges || [],
      loading,
      schemeData: schemeData?.schemeByEvent,
      handleActivateTeam,
      handleRateTeam,
      handleDeleteTeam,
      handleCreateTeam,
      handleUpdateTeam,
      handleResendCode
    }
  }, [teamsData, challengesData, loading])

  return (
    <>
      <TeamsDataProviderContext.Provider value={value}>
        {children}
      </TeamsDataProviderContext.Provider>
    </>
  )
}

export const useTeamsContext = () => React.useContext(TeamsDataProviderContext)
