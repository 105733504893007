import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiSnackbarType = {
  defaultProps?: ComponentsProps['MuiSnackbar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSnackbar'];
  variants?: ComponentsVariants['MuiSnackbar'];
}

export const MuiSnackbar:MuiSnackbarType = {
  styleOverrides: {
    root: {
      zIndex: 9999
    }
  }
}
