import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useSnackbar } from '@hooks/useSnackbar'
import { useUnsavedChanges } from '@hooks/useUnsavedChanges'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'

import { JuryDataProvider, useJuryContext } from '../provider/JuryDataProvider'
import { JuryTeamLayout } from '../components/JuryTeamLayout'
import { JuryTeamRatingInput } from '../components/JuryTeamRatingInput'
import { CreateRatingsInput } from '../typings/types'

const JuryTeamRatingViewContent: React.FC = () => {
  const { t } = useTranslation()
  const { criteriaId } = useParams<{criteriaId: string}>()
  const navigate = useNavigate()

  const { dialogManagerRef } = useDialogContext()

  const [loading, setLoading] = React.useState(false)

  const { eventData, teamData, schemeData, handleRateTeam } = useJuryContext()
  const {
    showSnackbar,
    message,
    setShowSnackbar,
    handleError
  } = useSnackbar()

  const formData = useForm<CreateRatingsInput>()
  const { handleSubmit, formState: { isDirty }, reset } = formData

  const { trigger } = useUnsavedChanges({
    when: isDirty,
    proceed: () => navigate(`/jury/${teamData?.id}`)
  })

  const watchedRatings = useWatch({
    control: formData.control,
    name: 'ratings'
  })

  const currentCriteria = React.useMemo(() => {
    return schemeData?.schemeCriteria?.find((criteria) => criteria.id === criteriaId)
  }, [schemeData, criteriaId])

  const ratingCount = React.useMemo(() => {
    return Object.values(watchedRatings || {}).filter((value) => value > 0).length
  }, [watchedRatings])

  React.useEffect(() => {
    const ratings: Record<string, number> = {}

    teamData?.ratings?.forEach((rating) => {
      if (currentCriteria?.children?.find((criteria) => criteria.id === rating.criteriaId)) {
        ratings[rating.criteriaId] = rating.value
      }
    })

    setShowSnackbar(false)

    reset({
      ratings
    })
  }, [teamData, currentCriteria])

  const onSubmitRating = handleSubmit(async (data) => {
    setLoading(true)

    try {
      await handleRateTeam(data)

      dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
        confirmCallback: () => navigate('/jury'),
        dialogProps: {
          hideCancel: true,
          stretchButtons: true,
          title: t('dialogs.rateSuccess.title'),
          children: t('dialogs.rateSuccess.message')
        }
      })
    } catch (error) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  })

  return (
    <JuryTeamLayout
      onBack={trigger}
      headerExtension={(
        <>
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              px: 2,
              height: 'auto',
              backgroundColor: 'secondary.main'
            }}
          >
            <Typography variant='subtitle1' fontWeight="bold">
              {`${ratingCount}/${currentCriteria?.children.length}`}
            </Typography>
          </Box>
          <Typography
            variant='h3'
          >
            {teamData?.name}
          </Typography>
        </>
      )}
      footerExtension={(
        <Box sx={{
          p: 2,
          borderTop: '1px solid #000000'
        }}
        >
          <LoadingButton
            variant="contained"
            fullWidth
            disabled={!isDirty || !eventData?.rateable}
            onClick={onSubmitRating}
            loading={loading}
          >
            <Typography variant='subtitle1' fontWeight="bold">
              {t('action.saveRating')}
            </Typography>
          </LoadingButton>
        </Box>
      )}
      sx={{
        backgroundColor: '#ffffff'
      }}
    >
      <Stack

        sx={{
          px: 2
        }}
      >
        <Box>
          {
            !currentCriteria?.canRate && (
              <Typography
                sx={{
                  color: 'error.main',
                  mb: 2
                }}
                fontWeight="bold"
                variant='subtitle1'
              >
                {t('jury.canNotRate')}
              </Typography>
            )
          }
          {
            !eventData?.rateable && (
              <Typography
                sx={{
                  color: 'error.main',
                  mb: 2
                }}
                fontWeight="bold"
                variant='subtitle1'
              >
                {t('jury.ratingClosed')}
              </Typography>
            )
          }
          {/* <Typography variant='subtitle1'>
            <Trans
              i18nKey='jury.criteriaDescription'
              values={{
                name: currentCriteria?.texts.title,
                weight: currentCriteria?.weighting
              }}
              components={{
                b: <b />
              }}
            />
          </Typography> */}
        </Box>
        <FormProvider {...formData}>
          <JuryTeamRatingInput
            disabled={!currentCriteria?.canRate}
          />
        </FormProvider>
      </Stack>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity="error"
      />
    </JuryTeamLayout>
  )
}

export const JuryTeamRatingView: React.FC = () => (
  <JuryDataProvider>
    <JuryTeamRatingViewContent />
  </JuryDataProvider>
)
