import { Box, Typography } from '@mui/material'
import { VerifyTeamInput, useVerifyTeamMutation } from '@typings/graphql'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LOGIN_BLOCKED } from '@shared/constants/auth'
import { useTimeBlocker } from '@hooks/useTimeBlocker'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'
import { useAppDispatch } from '@services/store/store'
import { setAnonymousToken } from '@services/store/slices/auth'
import { useParams } from 'react-router'

import { VerifyInputs } from '../components/VerifyInput'
import { VerifiedTeamType } from '../typings/types'

type Props = {
  onVerify: (data: VerifiedTeamType) => void,
  onError: (error: any) => any;
}

const VerifyViewContent: React.FC<Props> = ({ onVerify, onError }) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const [verifyTeam] = useVerifyTeamMutation()

  const dispatch = useAppDispatch()

  const { handleSubmit, formState: { isDirty, isValid } } = useFormContext<VerifyTeamInput>()
  const { blocked, remainingTime, startBlocker } = useTimeBlocker(LOGIN_BLOCKED)

  const [loading, setLoading] = React.useState(false)

  const submitVerify = handleSubmit(async (data) => {
    setLoading(true)
    try {
      const { data: team } = await verifyTeam({
        variables: {
          data: {
            verificationCode: data.verificationCode,
            id: id || ''
          }
        }
      })

      dispatch(setAnonymousToken({
        accessToken: team?.verifyTeam.accessToken,
        refreshToken: team?.verifyTeam.refreshToken
      }))
      if (team) {
        onVerify(team.verifyTeam.team)
        dispatch(setAnonymousToken({ accessToken: team.verifyTeam.accessToken, refreshToken: team.verifyTeam.refreshToken }))
      }
    } catch (error: any) {
      onError(error)
      startBlocker()
    } finally {
      setLoading(false)
    }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <form>
          <VerifyInputs sx={{
            mt: 1
          }}
          />

          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            <ElevatedButton
              onClick={submitVerify}
              loading={loading}
              disabled={!isDirty || !isValid || blocked }
              sx={{
                mt: 1
              }}
            >
              {t('action.verify')}
            </ElevatedButton>

            {blocked &&
            <Typography
              variant='subtitle1'
              sx={{
                ml: 2,
                color: 'error.main'
              }}
            >
              {t('auth.verifyBlockedFor', { count: remainingTime })}
            </Typography>
        }
          </Box>

        </form>

      </Box>
    </Box>
  )
}

export const VerifyView: React.FC<Props> = (props) => {
  const formData = useForm<VerifyTeamInput>({
    defaultValues: {},
    mode: 'onChange'
  })

  return (
    <FormProvider {...formData}>
      <VerifyViewContent {...props} />
    </FormProvider>
  )
}
