import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  mail: string
  title?: string
}

export const SupportEmailRow: React.FC<Props> = ({ title, mail }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Typography variant="subtitle1">
        {title || t('common.supportEmailContact')}
      </Typography>
      <Link
        variant="subtitle1"
        fontWeight="bold"
        href={`mailto:${mail}`}
      >{mail}</Link>
    </Box>
  )
}
