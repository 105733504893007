import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'

export type MuiDialogType = {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
}

export const MuiDialog:MuiDialogType = {
  styleOverrides: {
    container: {
      width: '100%',
      '.MuiPaper-root': {
        padding: 24,
        borderRadius: 0,
        border: 'solid 1px',
        borderColor: '#000000',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)'
      },
      '.MuiDialogTitle-root': {
        padding: 0
      },
      '.MuiDialogContent-root': {
        padding: 0,
        marginTop: '12px'
      },
      '.MuiDialogActions-root': {
        marginTop: '12px'
      }
    },
    paper: {
      margin: 0,
      maxWidth: '50%'
    }
  }
}
