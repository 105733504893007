import React from 'react'
import { Box, IconButton, Typography, Tooltip } from '@mui/material'
import { ActionRowCard } from '@shared/components/ui/ActionRowCard'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { useTranslation } from 'react-i18next'

import { SchemeButtonActions, SchemeCriteriaModel, SchemeHandleActionProps } from '../typings/types'

type Props = React.PropsWithChildren & SchemeHandleActionProps & {
  data: SchemeCriteriaModel
  extension?: React.ReactNode
  level?: number
}

export const SchemeCriteriaListItem: React.FC<Props> = ({ handleAction, extension, data, children, level = 0 }) => {
  const { t } = useTranslation()

  const [collapsed, setCollapsed] = React.useState<boolean>(true)
  const [height, setHeight] = React.useState<number>(0)

  const childrenBoxRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setHeight(!collapsed ? (childrenBoxRef.current?.clientHeight || 0) : 0)
  }, [children])

  const handleCollapse = () => {
    setCollapsed((prev) => {
      setHeight(prev ? (childrenBoxRef.current?.clientHeight || 0) : 0)

      return !prev
    })
  }

  return (
    <>
      <ActionRowCard
        actions={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 0.5
            }}
          >
            {can(['schemes:update', 'schemes:update.own']) && (
            <Tooltip title={t('scheme.editCriteria')}>
              <IconButton onClick={() => handleAction(SchemeButtonActions.EDIT_CRITERIA, data)}>
                <themeIcons.Edit />
              </IconButton>
            </Tooltip>
            )}
            {can(['schemes:delete', 'schemes:delete.own']) && (
            <Tooltip title={t('scheme.deleteCriteria')}>
              <IconButton onClick={() => handleAction(SchemeButtonActions.DELETE_CRITERIA, data)}>
                <themeIcons.Delete />
              </IconButton>
            </Tooltip>
            )}
            {level < 1 && (
              <Tooltip title={t(`scheme.${collapsed ? 'expand' : 'collapse'}`)}>
                <IconButton onClick={handleCollapse}>
                  <themeIcons.KeyboardArrowDown
                    sx={{
                      transition: 'transform 0.2s linear',
                      transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>}
      >
        <Typography fontWeight="bold">
          {data.texts.title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {data.weighting && (
          <Typography
            variant="subtitle1"
          >
            {t('scheme.criteriaWeighting', { weighting: data.weighting })}
          </Typography>
          )}
        </Box>
      </ActionRowCard>
      {!!children &&
      (
        <Box
          sx={{
            maxHeight: height,
            overflow: 'hidden',
            transition: 'max-height 0.2s linear'
          }}
        >
          <Box
            ref={childrenBoxRef}
            sx={{
              pb: 1.5
            }}
          >
            {children}
          </Box>
        </Box>
      )}
      {!collapsed && extension}
    </>)
}
