import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { SortableList } from '@shared/components/sortable/SortableList'
import { ActionRowCard } from '@shared/components/ui/ActionRowCard'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'
import { can } from '@utils/permissions'
import { SortableGrabber } from '@shared/components/sortable/SortableGrabber'

import { ChallengesButtonActions, ChallengesHandleActionProps, ChallengesModel } from '../typings/types'
import { useChallengesContext } from '../provider/ChallengesDataProvider'

export const ChallengesList: React.FC<ChallengesHandleActionProps> = ({ handleAction }) => {
  const { t } = useTranslation()
  const { challengesData, handleReorderChallenge } = useChallengesContext()

  const [internalItems, setInternalItems] = React.useState(challengesData)

  React.useMemo(() => {
    return setInternalItems([...challengesData]?.sort((a, b) => a.order - b.order) || [])
  }, [challengesData])

  const handleReorder = (items: ChallengesModel[]) => {
    setInternalItems(items)

    handleReorderChallenge(items)
  }

  return (
    <SortableList
      items={internalItems}
      onDragEnd={handleReorder}
      id="sortable_answers"
      grabber
      renderItem={(item, activeId, index, level, clone) => (
        <Box
          key={`sort_feedback_${item.id}`}
          sx={{
            mb: 2,
            opacity: (activeId === item.id && !clone) ? 0 : 1
          }}
        >
          <ActionRowCard
            actions={(
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 0.5
                }}
              >
                {can(['challenges:update', 'challenges:update.own']) && (
                  <Tooltip title={t('challenges.editChallenge')}>
                    <IconButton onClick={() => handleAction(ChallengesButtonActions.EDIT, item)}>
                      <themeIcons.Edit />
                    </IconButton>
                  </Tooltip>
                )}
                {can(['challenges:delete', 'challenges:delete.own']) && (
                  <Tooltip title={t('challenges.deleteChallenge')}>
                    <IconButton onClick={() => handleAction(ChallengesButtonActions.DELETE, item)}>
                      <themeIcons.Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            >
              <Box sx={{ flex: 0, mr: 2 }}>
                <SortableGrabber
                  id={item.id}
                >
                  <themeIcons.DragHandle />
                </SortableGrabber>
              </Box>
              <Box sx={{
                flex: 1
              }}
              >
                <Typography variant="h4">{item.texts.title}</Typography>
                <Typography sx={{
                  mt: 1
                }}
                >
                  {item.texts.description}
                </Typography>
              </Box>
            </Box>
          </ActionRowCard>
        </Box>
      )}
    />
  )
}
