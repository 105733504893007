import { Box, Stack, Typography } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { themeIcons } from '@theme/icons'
import { convertBytesToHumanReadable } from '@utils/format'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FileDimensions } from './FileDragDrop'

type Props = {
  accept: string[]
  maxSize: number,
  maxFileDimensions?: FileDimensions
  replace?: boolean
}

export const FileDefaultRenderedPreview: React.FC<Props> = ({ accept, replace, maxSize, maxFileDimensions }) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        textAlign: 'center'
      }}
    >
      <Box sx={{
        backgroundColor: 'grey.200',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '6px solid',
        borderColor: 'grey.100',
        borderRadius: '50%',
        mb: 2
      }}
      >
        <themeIcons.CloudUpload sx={{
          width: '20px',
          height: '20px',
          color: 'grey.700'
        }}
        />
      </Box>

      <Typography variant="subtitle1"
        sx={{
          color: 'text.primary'
        }}
      >
        <Trans
          i18nKey={replace ? 'action.clickToReplace' : 'action.clickToUpload'}
          components={{
            b: <strong style={{
              color: palette.primary.main
            }}
            />
          }}
        />
      </Typography>

      <Typography variant="subtitle1"
        sx={{
          color: 'text.primary'
        }}
      >
        {t('files.acceptedFileTypes', { types: accept?.join(', ') })}
      </Typography>
      <Typography variant="subtitle1"
        sx={{
          color: 'text.primary'
        }}
      >
        {t('files.acceptedFileSize', {
          size: convertBytesToHumanReadable(maxSize),
          dimensions: maxFileDimensions ? t('files.dimensionsPixel', maxFileDimensions) : ''
        })}
      </Typography>
    </Stack>
  )
}
