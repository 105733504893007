import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

import { Logo } from '../components/ui/Logo'

import { NavSidebarFooter } from './NavSidebarFooter'
import { NavSidebarLinkList } from './NavSidebarLinkList'

export const NavSidebar: React.FC = () => {
  const navigate = useNavigate()

  const handleLogoClicked = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        py: 3
      }}
    >
      <Box
        sx={{
          flex: 0,
          px: 3
        }}
      >
        <Box
          sx={{
            cursor: 'pointer'
          }}
          onClick={handleLogoClicked}
        >
          <Logo />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          mt: 2
        }}
      >
        <NavSidebarLinkList />
      </Box>
      <Box
        sx={{
          flex: 0

        }}
      >
        <NavSidebarFooter />
      </Box>
    </Box>
  )
}
