import {
  Color,
  PaletteOptions,
  SimplePaletteColorOptions
} from '@mui/material'

export type ThemePaletteOptions = PaletteOptions & {
  primary: SimplePaletteColorOptions & Partial<Color>;
  secondary: SimplePaletteColorOptions & Partial<Color>;
  error: SimplePaletteColorOptions & Partial<Color>;
  success: SimplePaletteColorOptions & Partial<Color>;
  warning: SimplePaletteColorOptions & Partial<Color>;
};

export const palette: ThemePaletteOptions = {
  primary: {
    main: '#2ED9FF',
    light: '#2ED9FF',
    dark: '#2ED9FF',
    contrastText: '#000000',
    50: '#E6FAFF',
    100: '#D1F7FF',
    200: '#A8EFFF',
    300: '#80E8FF',
    400: '#57E0FF',
    500: '#2ED9FF',
    600: '#00C8F5',
    700: '#009ABD',
    800: '#006D85',
    900: '#003F4D',
    A100: '#002831'
  },
  secondary: {
    main: '#DFEEF4',
    light: '#DFEEF4',
    dark: '#DFEEF4',
    contrastText: '#000000',
    50: '#FAFCFD',
    100: '#f1f7fb',
    200: '#EEF6F9',
    300: '#E7F2F7',
    400: '#DFEEF4',
    500: '#D7EAF1',
    600: '#D0E6EF',
    700: '#C8E2EC',
    800: '#C1DEEA',
    900: '#B9DAE7',
    A100: '#B5D8E6'
  },
  success: {
    main: '#4CAF50',
    light: '#4CAF50',
    dark: '#4CAF50',
    contrastText: '#000000',
    50: '#E8F5E9',
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
    A100: '#B9F6CA'
  },
  error: {
    main: '#FF0000',
    light: '#FF0000',
    dark: '#FF0000',
    contrastText: '#000000',
    50: '#FFEBEE',
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#D32F2F',
    800: '#C62828',
    900: '#B71C1C',
    A100: '#FF8A80'
  },
  warning: {
    main: '#FFC107',
    light: '#FFC107',
    dark: '#FFC107',
    contrastText: '#000000',
    50: '#FFF8E1',
    100: '#FFECB3',
    200: '#FFE082',
    300: '#FFD54F',
    400: '#FFCA28',
    500: '#FFC107',
    600: '#FFB300',
    700: '#FFA000',
    800: '#FF8F00',
    900: '#FF6F00',
    A100: '#FFE57F'
  },
  grey: {
    50: '#FAFAFA',
    100: '#F0F0F0',
    200: '#D8D8D8',
    300: '#C7C7C7',
    400: '#B2B2B2',
    500: '#9E9E9E',
    600: '#828282',
    700: '#6F7D85',
    800: '#4A4A4A',
    900: '#2E2E2E',
    A100: '#202020'
  },
  text: {
    primary: '#000000',
    secondary: '#ffffff'
  }
}
