import React from 'react'
import { HomeView } from '@features/home/views/HomeView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import { RoleBasedRedirection } from '@shared/components/access/RoleBasedRedirection'

import { AppRouteObject } from '../router'

export const commonRoutes: AppRouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoute component={<RoleBasedRedirection />} />,
    meta: {
      name: 'home',
      navItem: true,
      navIcon: 'Home',
      navOrder: 1
    },
    children: [{
      index: true,
      element: <HomeView />
    }]
  }
]
