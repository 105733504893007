import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import React from 'react'

export const ElevatedButton: React.FC<LoadingButtonProps> = ({ sx, children, ...props }) => (
  <LoadingButton
    variant='contained'
    sx={{
      borderRadius: 0,
      backgroundColor: 'primary.main',
      color: 'text.primary',
      top: 0,
      left: 0,
      mb: 0.5,
      border: 'solid 1px rgba(0,0,0)',
      padding: '8px 20px 8px 20px',
      position: 'relative',
      boxShadow: 'none',
      marginRight: '4px',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        zIndex: -1
      },
      '&:hover': {
        backgroundColor: 'primary.main',
        boxShadow: 'none',
        top: '4px',
        left: '4px',
        '&:before': {
          top: '-4px',
          left: '-4px'
        }
      },
      '&.Mui-disabled': {
        pointerEvents: 'none',
        backgroundColor: 'grey.100',
        color: 'grey.400',
        borderColor: 'grey.400',
        '&.MuiLoadingButton-loading': {
          color: 'transparent'
        }
      },
      ...sx
    }}
    {...props}
  >
    {children}
  </LoadingButton>
)
