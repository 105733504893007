import React from 'react'
import * as Sentry from '@sentry/react'
import { AlertProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

type HandleErrorOptions = {
  ignoreShow?: boolean;
  customDefaultMessage?: string;
  disableSentry?: boolean;
}

type UseSnackbar = {
  message: string;
  showSnackbar: boolean;
  severity: AlertProps['severity'];
  setSeverity: (severity: AlertProps['severity']) => void;
  setShowSnackbar: (show: boolean) => void;
  handleError: (error: any, options?: HandleErrorOptions) => void;
  handleSuccess: (successMessage: string) => void;
};

export const useSnackbar = (): UseSnackbar => {
  const { t, i18n } = useTranslation()
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [severity, setSeverity] = React.useState<AlertProps['severity']>('error')

  const handleError = React.useCallback((error: any, options?: HandleErrorOptions) => {
    console.error(error)
    const keyExists = i18n.exists(error.message)

    if (!keyExists && !options?.disableSentry) {
      Sentry.captureException(error)
    }

    setMessage(keyExists ? t(error.message) : (options?.customDefaultMessage ?? t('error.default')))
    setSeverity('error')

    setShowSnackbar(!options?.ignoreShow)
  }, [])

  const handleSuccess = React.useCallback((successMessage: string) => {
    setMessage(successMessage)
    setSeverity('success')

    setShowSnackbar(true)
  }, [])

  return {
    message,
    showSnackbar,
    severity,
    setSeverity,
    setShowSnackbar,
    handleError,
    handleSuccess
  }
}
