import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { BaseCreateDrawer } from '@shared/components/drawer/BaseCreateDrawer'
import { BaseEditDrawer } from '@shared/components/drawer/BaseEditDrawer'
import { UpdateUserInput } from '@typings/graphql'

import { UsersDataProvider, useUsersContext } from '../provider/UsersDataProvider'
import { UsersDataGrid } from '../dataGrid/UsersDataGrid'
import { UserButtonActions, UsersRowModel } from '../typings/types'
import { UserHeaderExtension } from '../components/UserHeaderExtension'
import { UserCreateEditInputs } from '../components/UserCreateEditInputs'

const UsersViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { dialogManagerRef } = useDialogContext()
  const { handleDeleteUser, handleInviteUser, handleCreateUser, handleUpdateUser } = useUsersContext()

  const {
    showSnackbar,
    message,
    setShowSnackbar,
    severity,
    handleSuccess,
    handleError
  } = useSnackbar()
  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [currentRow, setCurrentRow] = React.useState<UsersRowModel | null>(null)

  const resetModel = React.useMemo<UpdateUserInput>(() => {
    return {
      firstname: currentRow?.firstname || '',
      lastname: currentRow?.lastname || '',
      email: currentRow?.email || '',
      roleId: currentRow?.role.id,
      eventIds: currentRow?.events.map((event) => event.id)
    }
  }, [currentRow])

  const handleDeleteUserConfirm = async (id: string) => {
    try {
      await handleDeleteUser(id)

      handleSuccess(t('users.deleteSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleInviteUserClicked = async (id: string) => {
    try {
      await handleInviteUser(id)

      handleSuccess(t('users.inviteSuccess'))
    } catch (error) {
      handleError(error)
    }
  }

  const handleUserCreated = () => {
    handleSuccess(t('users.createSuccess'))
    setShowCreateDrawer(false)
  }

  const handleUserUpdated = () => {
    handleSuccess(t('users.editSuccess'))
    setShowEditDrawer(false)
  }

  const onHandleAction = (action: UserButtonActions, rowData?: UsersRowModel) => {
    switch (action) {
      case UserButtonActions.CREATE:
        setShowCreateDrawer(true)
        break
      case UserButtonActions.DELETE:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DELETE_ENTRY, {
          confirmCallback: async () => {
            await handleDeleteUserConfirm(rowData?.id || '')
          },
          dialogProps: {
            title: t('dialogs.deleteUser.title'),
            children: <Trans
              i18nKey={'dialogs.deleteUser.message'}
              values={{
                email: rowData?.email
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case UserButtonActions.EDIT:
        setCurrentRow(rowData || null)
        setShowEditDrawer(true)
        break
      case UserButtonActions.INVITE:
        handleInviteUserClicked(rowData?.id || '')
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={t('routes.users')}
      headerContent={<UserHeaderExtension handleAction={onHandleAction} />}
    >
      <UsersDataGrid handleAction={onHandleAction} />

      <BaseCreateDrawer
        title={t('users.createUser')}
        handleCreate={handleCreateUser}
        open={showCreateDrawer}
        onError={handleError}
        onSuccess={handleUserCreated}
        onClose={() => setShowCreateDrawer(false)}
      >
        <UserCreateEditInputs />
      </BaseCreateDrawer>

      <BaseEditDrawer
        handleUpdate={(data) => handleUpdateUser(currentRow?.id || '', data)}
        title={t('users.editUser')}
        resetModel={resetModel}
        open={showEditDrawer}
        onSuccess={handleUserUpdated}
        onError={handleError}
        onClose={() => setShowEditDrawer(false)}
      >
        <UserCreateEditInputs />
      </BaseEditDrawer>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}
export const UsersView: React.FC = () => {
  return (
    <UsersDataProvider>
      <UsersViewContent />
    </UsersDataProvider>
  )
}
