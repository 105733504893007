import React from 'react'
import { TextInput } from '@shared/components/inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { FormSelectInput } from '@shared/components/inputs/FormSelectInput'
import { useFormContext, useWatch } from 'react-hook-form'
import { Roles } from '@typings/roles'

import { useSchemeContext } from '../provider/SchemeDataProvider'
import { IGNORED_ROLES } from '../constants/constants'

export const CriteriaCreateEditInputs: React.FC = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()
  const { roleData } = useSchemeContext()

  const watchedRole = useWatch({
    name: 'roleId',
    control
  })

  const mappedRoles = React.useMemo(() => {
    return roleData.filter((role) => !IGNORED_ROLES.includes(role.key as Roles)).map((filter) => ({
      id: filter.id,
      name: t(`roles.${filter.key}`)
    }))
  }, [roleData])

  const isAdmin = React.useMemo(() => {
    return roleData.find((role) => role.id === watchedRole)?.key === Roles.ADMIN
  }, [watchedRole, roleData])

  return (
    <>
      <TextInput
        formKey="texts.title"
        label={t('common.title')}
        options={{
          required: true
        }}
      />

      <TextInput
        formKey="weighting"
        label={t('common.weighting')}
        type="number"
      />

      {!isAdmin && (
        <FormSelectInput
          items={mappedRoles}
          formKey="roleIds"
          multiple
          sx={{
            maxWidth: '100%'
          }}
          label={t('common.roles')}
        />
      )}
    </>
  )
}
