import React from 'react'
import { useDialogContext } from '@providers/DialogProvider'
import { GlobalDialogType } from '@typings/dialogs'

type UseUnsavedChanges = {
  trigger: () => void;
}

type UseUnsavedChangesProps = {
  when: boolean;
  proceed?: () => void;
};

export const useUnsavedChanges = ({ when, proceed }: UseUnsavedChangesProps): UseUnsavedChanges => {
  const { dialogManagerRef } = useDialogContext()

  const triggerDialog = React.useCallback(() => {
    dialogManagerRef?.current?.showDialog(GlobalDialogType.UNSAVED_CHANGES, {
      confirmCallback: proceed
    })
  }, [dialogManagerRef])

  React.useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return ''
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [when])

  const handleTrigger = React.useCallback(() => {
    if (when) {
      triggerDialog()
    } else {
      proceed?.()
    }
  }, [when, proceed])

  return {
    trigger: handleTrigger
  }
}
