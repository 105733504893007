import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material/styles'
import { palette } from '@theme/core/palette/palette'

export type MuiIconButtonType = {
  defaultProps?: ComponentsProps['MuiIconButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
  variants?: ComponentsVariants['MuiIconButton'];
}

export const MuiIconButton:MuiIconButtonType = {
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      cursor: 'pointer',
      color: palette.text?.primary
    }
  }
}
