import { Box, Typography } from '@mui/material'
import { getOverallDays } from '@utils/date'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  date: Date
}

export const DateCountdown: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation()

  const [remainingTime, setRemainingTime] = React.useState<number | null>(null)

  const timeIntervalRef = React.useRef<ReturnType<typeof setInterval> | null>(null)

  const mappedCountDown = React.useMemo(() => {
    if (!remainingTime) {
      return null
    }

    const days = getOverallDays(remainingTime)
    const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((remainingTime / (1000 * 60)) % 60)
    const seconds = Math.floor((remainingTime / 1000) % 60)

    return {
      days: days < 0 ? 0 : days,
      hours: hours < 0 ? 0 : hours,
      minutes: minutes < 0 ? 0 : minutes,
      seconds: seconds < 0 ? 0 : seconds
    }
  }, [remainingTime])

  React.useEffect(() => {
    if (!date) {
      return
    }

    if (timeIntervalRef.current) {
      clearInterval(timeIntervalRef.current)
    }

    setRemainingTime(date.getTime() - new Date().getTime())
    timeIntervalRef.current = setInterval(() => {
      setRemainingTime((prev) => prev! - 1000)
    }, 1000)

    return () => {
      if (timeIntervalRef.current) {
        clearInterval(timeIntervalRef.current)
      }
    }
  }, [date])

  return mappedCountDown
    ? (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
      >
        {Object.entries(mappedCountDown).map(([key, value], index) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            >
              <Box sx={{
                fontSize: '2rem',
                fontWeight: 'bold'
              }}
              >
                {value.toString().padStart(2, '0')}
              </Box>
              <Box sx={{
                fontSize: '0.75rem'
              }}
              >
                {t(`common.${key}`, { count: value })}
              </Box>
            </Box>
            {index < Object.keys(mappedCountDown).length - 1 && (
            <Typography sx={{ mx: 1 }}>:</Typography>
            )}
          </Box>
        ))}
      </Box>
      )
    : null
}
