import React, { ReactElement } from 'react'
import { useAuthenticated } from '@hooks/useAuthenticated'

type Props = {
  component: ReactElement,
}

const ProtectedRoute: React.FC<Props> = ({ component }) => {
  useAuthenticated()

  return component
}

export default ProtectedRoute
