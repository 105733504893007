import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUsersContext } from '@features/users/provider/UsersDataProvider'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { FilterSelectInput, FilterSelectItem } from '@shared/dataGrid/filter/FilterSelectInput'
import { getFilterOperatorSelect } from '@shared/dataGrid/filter/getFilterOperatorSelect'
import { UserColumnType } from '@features/users/typings/types'

const RenderedFilter: React.FC<GridFilterInputValueProps> = (props) => {
  const { t } = useTranslation()
  const { roles } = useUsersContext()

  const mappedRoles = React.useMemo<FilterSelectItem[]>(() => {
    return roles?.map((role) => ({
      id: role.id,
      name: t(`roles.${role.key}`),
      key: role.key
    })) ?? []
  }, [roles])

  return (
    <FilterSelectInput
      {...props}
      items={mappedRoles ?? []}
    />
  )
}

const RoleRenderCell: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation()

  return t(`roles.${value}`)
}

export const roleColumnModel: UserColumnType = {
  field: 'role',
  width: 150,
  sortable: false,
  filterOperators: getFilterOperatorSelect(RenderedFilter),
  valueGetter: (params) => params.row.role.id,
  renderCell: (params) => (
    <RoleRenderCell value={params.row?.role.key as string} />
  )
}
