import React from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'
import { useAuthenticated } from '@hooks/useAuthenticated'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'
import { useNavigate } from 'react-router'

type Props = {
  text: string
}

export const ErrorLayout: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { breakpoints } = useTheme()

  useAuthenticated()

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection={['column', 'row']}>
      <Box width="50%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          [breakpoints.down('md')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }
        }}
      >
        <SecurityIcon sx={{ width: ['200px', '300px'], height: ['200px', '300px'] }} />

        <Box display="inline-block">
          <Typography variant="h2">{text}</Typography>

          <Button
            variant="text"
            startIcon={<themeIcons.ArrowBack />}
            sx={{
              mt: 2
            }}
            onClick={() => navigate('/')}
          >
            {t('action.toHome')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
