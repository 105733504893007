import React from 'react'
import { PublicLayout } from '@shared/layouts/PublicLayout'
import { LoginView } from '@features/auth/views/LoginView'
import { PasswordForgotView } from '@features/auth/views/PasswordForgotView'
import { PasswordResetView } from '@features/auth/views/PasswordResetView'

import { AppRouteObject } from '../router'

export const authRoutes: AppRouteObject[] = [
  {
    path: '/login',
    element: <PublicLayout logoClickable />,
    children: [
      {
        index: true,
        element: <LoginView />
      }
    ]
  },
  {
    path: '/forgot-password',
    element: <PublicLayout logoClickable/>,
    children: [
      {
        index: true,
        element: <PasswordForgotView />
      }
    ]
  },
  {
    path: '/reset-password/:token',
    element: <PublicLayout logoClickable/>,
    children: [
      {
        index: true,
        element: <PasswordResetView />
      }
    ]
  },
  {
    path: '/invitation/:token',
    element: <PublicLayout logoClickable/>,
    children: [
      {
        index: true,
        element: <PasswordResetView />
      }
    ]
  }
]
