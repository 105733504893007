import { Box, Divider, Typography } from '@mui/material'
import { DashboardCard } from '@shared/components/ui/DashboardCard'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useEventDetailsContext } from '../provider/EventDetailsDataProvider'

export const SubmissionTeamList: React.FC = () => {
  const { t } = useTranslation()

  const { teamsData } = useEventDetailsContext()

  const sortedTeams = React.useMemo(() => {
    if (teamsData?.filter((team) => team)?.length === 0) {
      return []
    }

    return teamsData.filter((team) => team)?.sort((a, b) => {
      if (a.teamResult && !b.teamResult) {
        return -1
      }

      if (!a.teamResult && b.teamResult) {
        return 1
      }

      if (a.name < b.name) {
        return -1
      }

      if (a.name > b.name) {
        return 1
      }

      return 0
    })
  }, [teamsData])

  return (
    <DashboardCard
      header={t('eventDetails.submissionsByTeam')}
      boxProps={{
        sx: {
          maxHeight: '500px'
        }
      }}
    >
      {sortedTeams?.length > 0
        ? (
            sortedTeams.map((team, index) => (
              <Box
                key={team.id}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography>
                    {team.name}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  >
                    <Typography
                      sx={{
                        color: team.active ? 'success.500' : 'error.500',
                        mr: 4
                      }}
                    >
                      {t(`common.${team.active ? 'active' : 'inactive'}`)}
                    </Typography>
                    <Typography
                      sx={{
                        color: team.teamResult ? 'success.500' : 'error.500'
                      }}
                    >
                      {t(`common.${team.teamResult ? 'submitted' : 'notSubmitted'}`)}
                    </Typography>
                  </Box>
                </Box>
                {index < sortedTeams.length - 1 && (
                  <Divider
                    sx={{
                      my: 1
                    }}
                    light
                  />
                )}
              </Box>
            ))
          )
        : (
          <Typography sx={{
            textAlign: 'center'
          }}
          >
            {t('eventDetails.noTeams')}
          </Typography>
          )}
    </DashboardCard>
  )
}
