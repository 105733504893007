import { FeedbackQuestionProps } from '@features/feedbackSubmission/typings/types'
import { Box, Typography, useTheme } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { fontSizes, lineHeights } from '@theme/core/typography/typography'
import { t } from 'i18next'
import React from 'react'

export const FeedbackQuestionOpen: React.FC<FeedbackQuestionProps> = ({ question }) => {
  const { breakpoints } = useTheme()
  return (
    <Box>
      <Box sx={{
        mb: 2
      }}
      >
        <Typography variant='subtitle1'
          sx={{
            [breakpoints.down('md')]: {
              fontSize: fontSizes.xs,
              lineHeight: lineHeights.xs
            }
          }}
        >
          {t('feedbackSubmission.openQuestion')}
        </Typography>
      </Box>

      <TextInput
        formKey={`feedback.${question.id}`}
        label={t('common.comment')}
        multiline
        inputProps={{
          ...(question.config.max && { max: question.config.max })
        }}
        options={{
          required: question.config.required || false
        }}
      />

    </Box>
  )
}
