import { ConfirmDialogProps } from '@shared/components/ui/ConfirmDialog'
import { PropsWithChildren } from 'react'

export type GlobalDialogProps = PropsWithChildren & {
  confirmCallback?: () => void;
  cancelCallback?: () => void;
  dialogProps?: ConfirmDialogProps
};

export enum GlobalDialogType {
  UNSAVED_CHANGES = 'unsavedChanges',
  DELETE_ENTRY = 'deleteEntry',
  DEFAULT = 'default'
}
