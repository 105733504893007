import React from 'react'
import { RouteObject } from 'react-router'
import { themeIcons } from '@theme/icons'
import { Roles } from '@typings/roles'

import { authRoutes } from './routes/auth'
import { commonRoutes } from './routes/common'
import { publicRoutes } from './routes/public'
import { errorRoutes } from './routes/error'
import { usersRoutes } from './routes/users'
import { eventsRoutes } from './routes/events'
import { juryRoutes } from './routes/jury'

type RouteMetaData = {
  navItem?: boolean
  navIcon?: keyof typeof themeIcons
  navOrder?: number
  hasSubMenu?: boolean
  [key: string]: any
}

type RouteAccessData = {
  roles: Roles[]
  partner?: boolean
}

export type AppRouteObject = RouteObject & {
  children?: AppRouteObject[]
  meta?: RouteMetaData
  access?: RouteAccessData
  breadcrumb?: React.ReactElement
}

export const baseRoutes: AppRouteObject[] = [
  ...authRoutes,
  ...commonRoutes,
  ...publicRoutes,
  ...errorRoutes
]

export const restrictedRoutes: AppRouteObject[] = [
  ...usersRoutes,
  ...eventsRoutes,
  ...juryRoutes
]
