import { Box, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { generateId } from '@utils/random'
import { EditableFile } from '@typings/files'

import FileDragDrop, { FileDimensions, FileDragDropHandler } from '../files/FileDragDrop'
import { FilePreviewModal } from '../files/FilePreviewModal'
import { FileDefaultRenderedPreview } from '../files/FileDefaultRenderedPreview'
import { FileDefaultPreviewRow } from '../files/FileDefaultPreviewRow'

type Props = {
  formKey: string
  title: string
  fileKey: string
  description?: string,
  loading?: boolean
  maxFileSize?: number
  maxFileDimensions?: FileDimensions
  acceptedFileTypes?: Record<string, string[]>
}

export const BaseMultiFileInput: React.FC<Props> = ({
  formKey, title, fileKey, description, maxFileSize,
  maxFileDimensions, loading, acceptedFileTypes
}) => {
  const fileDragDropRef = React.useRef<FileDragDropHandler | null >(null)

  const [showFullScreen, setShowFullScreen] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState<EditableFile<any> | null>(null)

  const { control, setValue, getValues } = useFormContext()

  const watchedFiles = useWatch({
    name: formKey,
    control
  }) as Record<string, EditableFile<any>>

  const deleteFile = (key: string) => {
    const existingFiles = getValues(formKey)
    const newFiles: Record<string, EditableFile<any>> = {}

    Object.keys(existingFiles).filter((existingKey) => existingKey !== key).forEach((filtered: string, index: number) => {
      newFiles[`${fileKey}_${index}`] = {
        ...existingFiles[filtered],
        ...(existingFiles[filtered]?.upload && {
          upload: {
            ...existingFiles[filtered].upload,
            data: {
              key: `${fileKey}_${index}`
            }
          }
        })
      }
    })

    setValue(`${formKey}`, newFiles, {
      shouldDirty: true,
      shouldValidate: true
    })
  }

  const onFilesChanged = (files: File[]) => {
    if (files.length === 0) {
      return
    }

    const existingFiles = getValues(formKey)

    files.forEach((file: File, index: number) => {
      const currentIndex = existingFiles && Object.keys(existingFiles).length > 0 ? Object.keys(existingFiles).length : index

      setValue(`${formKey}.${fileKey}_${currentIndex}`, {
        id: generateId(10),
        upload: {
          file: files[0],
          data: {
            key: `${fileKey}_${currentIndex}`
          },
          preview: URL.createObjectURL(files[0])
        }
      } as EditableFile<any>, {
        shouldDirty: true,
        shouldValidate: true
      })
    })
  }

  const handlePreviewClick = (file: EditableFile<any>) => {
    setSelectedFile(file)
    setShowFullScreen(true)
  }

  return (
    <>
      <Box py={4}>
        <Box pb={4}>
          <Typography variant="subtitle1" fontWeight={600}>{title}</Typography>
          {description && <Typography variant="subtitle1">{description}</Typography>}
        </Box>
        <Box>
          <Box position="relative">
            <Controller
              name={formKey}
              control={control}
              render={() => (
                <Box>
                  {watchedFiles && Object.keys(watchedFiles)?.map?.((key: string) => (
                    <Box key={key} sx={{ mb: 2 }}>
                      <FileDefaultPreviewRow
                        file={watchedFiles[key]}
                        onDelete={() => deleteFile(key)}
                        onPreview={() => handlePreviewClick(watchedFiles[key])}
                      />
                    </Box>
                  ))}
                  <FileDragDrop
                    ref={fileDragDropRef}
                    accept={acceptedFileTypes || { 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
                    loading={loading}
                    maxFileSize={maxFileSize}
                    maxFileDimensions={maxFileDimensions}
                    onFilesChanged={onFilesChanged}
                    renderedPreview={(accept, maxSize) => (
                      <FileDefaultRenderedPreview
                        accept={accept}
                        maxSize={maxSize}
                      />
                    )}
                  >
                  </FileDragDrop>
                </Box>
              )
              }
            />
          </Box>
        </Box>
      </Box>
      {

      (
        <FilePreviewModal
          open={showFullScreen}
          onClose={() => setShowFullScreen(false)}
          file={selectedFile}
        />
      )

      }
    </>
  )
}
