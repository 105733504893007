import { Box, BoxProps } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { mailPattern } from '@utils/pattern'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const PasswordForgotInput: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Box {...props}>
      <TextInput
        formKey="email"
        label={t('common.email')}
        hideMaxLength
        type="email"
        options={{
          required: true,
          pattern: mailPattern
        }}
        validationText={t('error.input.invalidEmail')}
      />
    </Box>
  )
}
