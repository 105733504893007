import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { upperFirstLetter } from '@utils/format'
import { UserColumnType } from '@features/users/typings/types'

export const firstnameColumnModel: UserColumnType = {
  field: 'firstname',
  width: 150,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => upperFirstLetter(params.value)
}
