import { AdminContentLayout } from '@shared/layouts/AdminContentLayout'
import React from 'react'
import { Grid, Stack } from '@mui/material'
import { useDialogContext } from '@providers/DialogProvider'
import { useSnackbar } from '@hooks/useSnackbar'
import { Trans, useTranslation } from 'react-i18next'
import { GlobalDialogType } from '@typings/dialogs'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'

import { EventDetailsDataProvider, useEventDetailsContext } from '../provider/EventDetailsDataProvider'
import { SubmissionsCounterCard } from '../components/SubmissionCounterCard'
import { SubmissionTeamList } from '../components/SubmissionTeamList'
import { EventNotActiveCard } from '../components/EventNotActiveCard'
import { EventDetailsButtonActions } from '../typings/types'
import { EventDetailActionCard } from '../components/EventDetailActionCard'

const EventDetailViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { eventData, loading, handleToggleSubmission, handleToggleRegistration, handleToggleRatings } = useEventDetailsContext()
  const { dialogManagerRef } = useDialogContext()

  const {
    showSnackbar,
    message,
    severity,
    setShowSnackbar,
    handleSuccess,
    handleError
  } = useSnackbar()

  const handleToggleSubmissionConfirm = async () => {
    try {
      await handleToggleSubmission()

      handleSuccess(t('eventDetails.submissionToggleSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const handleToggleRegistrationConfirm = async () => {
    try {
      await handleToggleRegistration()

      handleSuccess(t('eventDetails.registrationToggleSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }
  const handleToggleRatingsConfirm = async () => {
    try {
      await handleToggleRatings()

      handleSuccess(t('eventDetails.ratingsToggleSuccess'))
    } catch (error: any) {
      handleError(error)
    }
  }

  const onHandleAction = (action: EventDetailsButtonActions) => {
    switch (action) {
      case EventDetailsButtonActions.TOGGLE_SUBMISSION:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
          confirmCallback: async () => {
            await handleToggleSubmissionConfirm()
          },
          dialogProps: {
            title: t(`dialogs.${eventData?.submission ? 'closeSubmission' : 'openSubmission'}.title`),
            children: <Trans
              i18nKey={`dialogs.${eventData?.submission ? 'closeSubmission' : 'openSubmission'}.message`}
              values={{
                name: eventData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case EventDetailsButtonActions.TOGGLE_REGISTRATION:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
          confirmCallback: async () => {
            await handleToggleRegistrationConfirm()
          },
          dialogProps: {
            title: t(`dialogs.${eventData?.registration ? 'closeRegistration' : 'openRegistration'}.title`),
            children: <Trans
              i18nKey={`dialogs.${eventData?.registration ? 'closeRegistration' : 'openRegistration'}.message`}
              values={{
                name: eventData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      case EventDetailsButtonActions.TOGGLE_RATINGS:
        dialogManagerRef.current?.showDialog(GlobalDialogType.DEFAULT, {
          confirmCallback: async () => {
            await handleToggleRatingsConfirm()
          },
          dialogProps: {
            title: t(`dialogs.${eventData?.registration ? 'closeRatings' : 'openRatings'}.title`),
            children: <Trans
              i18nKey={`dialogs.${eventData?.registration ? 'closeRatings' : 'openRatings'}.message`}
              values={{
                name: eventData?.name
              }}
              components={{ b: <b /> }}
            />
          }
        })
        break
      default:
        break
    }
  }

  return (
    <AdminContentLayout
      title={eventData?.name || ''}
      description={t('common.subjectWithName', { name: eventData?.texts.subject || '' })}
      loading={loading}
    >
      <Grid container spacing={2}>
        {!!eventData && !eventData?.active && (
          <Grid item xs={12}>
            <EventNotActiveCard />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Stack
            spacing={2}
          >
            <SubmissionsCounterCard />
            <EventDetailActionCard handleAction={onHandleAction} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <SubmissionTeamList />
        </Grid>
      </Grid>

      <DefaultSnackbar
        open={showSnackbar}
        message={message}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
      />
    </AdminContentLayout>
  )
}

export const EventDetailView: React.FC = () => (
  <EventDetailsDataProvider>
    <EventDetailViewContent />
  </EventDetailsDataProvider>
)
