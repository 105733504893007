import React, { useCallback } from 'react'
import { BaseDataGridDetailPanel } from '@shared/dataGrid/components/BaseDataGridDetailPanel'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { themeIcons } from '@theme/icons'
import { TeamAttachmentModal } from '@shared/components/ui/TeamAttachmentModal'

import { FileType, TeamsRowModel } from '../typings/types'

import { TeamDetailPanelLink } from './TeamDetailPanelLink'

type Props = {
  data: TeamsRowModel
}

export const TeamDetailPanel: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const [selectedFile, setSelectedFile] = React.useState<FileType>()
  const [showFilePreview, setShowFilePreview] = React.useState(false)

  const onFileClicked = useCallback((file: FileType) => {
    setSelectedFile(file)
    setShowFilePreview(true)
  }, [])

  return (
    <BaseDataGridDetailPanel>
      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
        {data.name }
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Typography sx={{ fontWeight: 'bold' }}>
        {t('teams.teamMembers')}
      </Typography>

      {data.teamMembers.map((member) => (
        <Box
          key={member.id}
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography>
            {`${member.firstName} ${member.lastName}`}
          </Typography>
        </Box>
      ))}

      <Typography sx={{ mt: 3, fontWeight: 'bold' }}>
        {t('teams.teamResult')}
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Box sx={{
        mt: 1
      }}
      >
        {data.teamResult
          ? (
            <Box>
              <TeamDetailPanelLink
                sx={{ mb: 1 }}
                title={`${t('common.repository')}:`}
                link={data.teamResult.repository}
              />

              {data.teamResult.website && <TeamDetailPanelLink
                sx={{ mb: 1 }}
                title={`${t('common.website')}:`}
                link={data.teamResult.website}
              />}

              <Typography fontWeight={600}>
                {`${t('common.description')}:`}
              </Typography>
              <Box sx={{
                mt: 1,
                px: 2,
                py: 1,
                backgroundColor: '#ffffff'
              }}
              >
                <ReactMarkdown>
                  {data.teamResult.texts?.description || t('common.noDescription')}
                </ReactMarkdown>
              </Box>

              <Box sx={{ mt: 1 }}>

                <Box>
                  <Typography fontWeight={600}>
                    {`${t('common.attachments')}:`}
                  </Typography>
                  {data.teamResult.files.map((file) => (
                    <Box
                      key={file.id}
                    >
                      <Button
                        variant="text"
                        onClick={() => onFileClicked(file)}
                        disableRipple
                        sx={{
                          cursor: 'pointer',
                          color: '#000000',
                          textDecoration: 'none',
                          '&:hover': {
                            color: '#000000',
                            textDecoration: 'underline'
                          }
                        }}
                        startIcon={<themeIcons.Visibility />}
                      >
                        <Typography>
                          {file.fileName}
                        </Typography>
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            )
          : (
            <Typography>
              {t('teams.noSubmission')}
            </Typography>
            )}
      </Box>

      <TeamAttachmentModal file={selectedFile} open={showFilePreview} onClose={() => setShowFilePreview(false)} />
    </BaseDataGridDetailPanel>
  )
}
