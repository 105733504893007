import { FeedbackEvaluationTypeProps } from '@features/feedbackEvaluation/typings/types'
import { Box, Typography } from '@mui/material'
import React from 'react'

export const FeedbackEvaluationOpen: React.FC<FeedbackEvaluationTypeProps> = ({ data }) => {
  return (
    <Box>
      {data.feedbackAnswers.map((answer, index) => (
        <Box
          key={answer.id}
          sx={{
            mb: 1,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box sx={{
            flex: 0,
            minWidth: '25px',
            mr: 1
          }}
          >
            {`${index + 1}.`}
          </Box>
          <Typography>
            {answer.value}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
