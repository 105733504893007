import { FeedbackQuestionType } from '@typings/graphql'
import React from 'react'

import { FeedbackQuestionChoiceInput } from './FeedbackQuestionChoiceInput'
import { FeedbackQuestionOpenInput } from './FeedbackQuestionOpenInput'

export type QuestionExtensions = Record<FeedbackQuestionType, React.FC>

export const feedbackQuestionTypeExtensions: QuestionExtensions = {
  [FeedbackQuestionType.Choice]: FeedbackQuestionChoiceInput,
  [FeedbackQuestionType.Open]: FeedbackQuestionOpenInput,
  [FeedbackQuestionType.Rating]: () => null
}
