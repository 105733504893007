import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RATING_STATE_COLORS } from '@shared/constants/color'
import { themeIcons } from '@theme/icons'

import { TeamNode } from '../typings/types'

type Props = {
  team: TeamNode
}

export const JuryTeamListItem: React.FC<Props> = ({ team }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        border: '1px solid #000000',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
      }}
    >
      <Box sx={{
        flex: 1,
        backgroundColor: 'secondary.200',
        p: 1.5
      }}
      >
        <Typography fontWeight="bold">
          {team.name}
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 0.5,
          alignItems: 'center'
        }}
        >
          <Box sx={{
            border: '1px solid #000000',
            borderRadius: '50%',
            height: '12px',
            width: '12px',
            mr: 1,
            backgroundColor: RATING_STATE_COLORS[team.ratingState]
          }}
          />
          <Typography variant="subtitle2">
            {t(`jury.ratingState.${team.ratingState.toLowerCase()}`)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderLeft: '1px solid #000000',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          p: 2.5
        }}
      >
        <themeIcons.ArrowForward />
      </Box>
    </Box>
  )
}
