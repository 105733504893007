import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material/styles'

export type MuiButtonType = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

export const MuiButton: MuiButtonType = {
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        '.MuiSvgIcon-root': {
          fontSize: '24px'
        }
      },
      '.MuiSvgIcon-root': {
        fontSize: '24px'
      }
    }
  }
}
