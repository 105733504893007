import { CreateFeedbackQuestionInput, FeedbackQuestionsQuery, UpdateFeedbackQuestionInput } from '@typings/graphql'

export enum FeedbackQuestionsButtonActions {
  'CREATE' = 'CREATE',
  'EDIT' = 'EDIT',
  'DELETE' = 'DELETE',
}

export type FeedbackQuestionsModel = FeedbackQuestionsQuery['feedbackQuestions'][0]
export type FeedbackQuestionAnswerModel = Omit<FeedbackQuestionsModel['feedbackQuestionAnswers'][0], '__typename'>

export type CreateFeedbackQuestionModel = Omit<CreateFeedbackQuestionInput, 'texts'> & {
  texts: Record<string, string>
  answers: {
    id: string
    texts: Record<string, string>
  }[]
}

export type UpdateFeedbackQuestionModel = Omit<UpdateFeedbackQuestionInput, 'texts'> & {
  texts: Record<string, string>
  answers: {
    id: string
    texts: Record<string, string>
  }[]
}

export type FeedbackQuestionsHandleActionProps = {
  handleAction: (action: FeedbackQuestionsButtonActions, model?: FeedbackQuestionsModel) => void
}
